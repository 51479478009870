import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useAddTimeOffRequest, User, useUserTimeOffBalances} from "../api";
import {MobileDatePicker} from "@mui/x-date-pickers-pro";
import moment, {Moment} from "moment";
import {useToast} from "../common/Toast";

enum Duration {
  FULL_DAY = "FULL_DAY",
  HALF_DAY = "HALF_DAY"
}

export const TimeOffAdd = (props: { user: User, onAdded?: () => void }) => {

  const toast = useToast()
  const [openRequestTimeOffDialog, setOpenTimeOffRequestDialog] = useState(false)
  const [timeOffRequestBalanceId, setTimeOffRequestBalanceId] = useState<string | null>(null)
  const [timeOffRequestStartDate, setTimeOffRequestStartDate] = useState<Moment | null>(null)
  const [timeOffRequestEndDate, setTimeOffRequestEndDate] = useState<Moment | null>(null)
  const [timeOffRequestDescription, setTimeOffRequestDescription] = useState<string | null>(null)
  const [durationFrom, setDurationFrom] = useState<Duration>(Duration.FULL_DAY)
  const [durationTo, setDurationTo] = useState<Duration>(Duration.FULL_DAY)

  const [addTimeOffRequest] = useAddTimeOffRequest()

  useEffect(() => {
    if (!timeOffRequestStartDate && timeOffRequestEndDate) {

    }
  }, [timeOffRequestStartDate, timeOffRequestEndDate])

  const minDate = moment().subtract(100, 'years')
  const maxDate = moment().add(100, 'years')

  const balances = useUserTimeOffBalances(props.user.id)

  const onCancelRequestTimeOffDialog = () => {
    setTimeOffRequestBalanceId(null)
    setTimeOffRequestStartDate(null)
    setTimeOffRequestEndDate(null)
    setTimeOffRequestDescription(null)
    setOpenTimeOffRequestDialog(false)
  }

  const onAddTimeOffRequest = () => {
    addTimeOffRequest({
      variables: {
        balanceId: timeOffRequestBalanceId,
        start: timeOffRequestStartDate?.utc(true).format(),
        end: timeOffRequestEndDate?.utc(true).format(),
        description: timeOffRequestDescription,
        firstDayDuration: durationFrom,
        lastDayDuration: timeOffRequestEndDate?.isAfter(timeOffRequestStartDate) ? durationTo : null
      }
    }).then(response => {
      if (response.error) {
        /*const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNameError(e.message)
        }*/
      } else {
        setOpenTimeOffRequestDialog(false)
        setTimeOffRequestBalanceId(null)
        setTimeOffRequestStartDate(null)
        setTimeOffRequestEndDate(null)
        setTimeOffRequestDescription(null)
        setDurationFrom(Duration.FULL_DAY)
        setDurationTo(Duration.FULL_DAY)
        balances.refetch()
        toast.info("Time off requested")
        props.onAdded?.()
      }
    })
  }

  const today = moment();

  return (
    <Box>
      <Button variant="outlined" sx={{backgroundColor: "white"}} onClick={_ => setOpenTimeOffRequestDialog(true)}>Request Time Off</Button>
      <Dialog open={openRequestTimeOffDialog}>
        <DialogTitle>Request Time Off</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          <Box sx={{display: "flex", flexDirection: "column", gap: "15px"}}>
            <FormControl variant="outlined" fullWidth margin="normal" size="small">
              <InputLabel>Type</InputLabel>
              <Select
                value={timeOffRequestBalanceId}
                label={"Type"}
                onChange={e => setTimeOffRequestBalanceId(e.target.value)}
              >
               {balances.data?.user?.timeOffBalances?.map(x =>
                  <MenuItem key={x.id} value={x.id} disabled={moment(x.activationDate).isAfter(today)}>{x.timeOffPolicy.name}</MenuItem>
               )}
              </Select>
            </FormControl>

            <Box sx={{display: "flex", alignItems: "center", gap: "10px"}}>
              <MobileDatePicker
                showToolbar={false}
                label="From"
                inputFormat="YYYY-MM-DD"
                minDate={minDate}
                maxDate={maxDate}
                value={timeOffRequestStartDate}
                onChange={setTimeOffRequestStartDate}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
              <MobileDatePicker
                showToolbar={false}
                label="To"
                inputFormat="YYYY-MM-DD"
                minDate={timeOffRequestStartDate || minDate}
                maxDate={maxDate}
                value={timeOffRequestEndDate}
                onChange={setTimeOffRequestEndDate}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </Box>

            <Box sx={{display: "flex", alignItems: "center", gap: "10px"}}>
              <FormControl variant="outlined" fullWidth margin="normal" size="small">
                <InputLabel>First day duration</InputLabel>
                <Select
                  value={durationFrom}
                  label={"First day duration"}
                  disabled={!timeOffRequestStartDate}
                  onChange={e => setDurationFrom(Duration[e.target.value! as keyof typeof Duration])}
                >
                  <MenuItem value={Duration.FULL_DAY}>Full day</MenuItem>
                  <MenuItem value={Duration.HALF_DAY}>Half a day</MenuItem>
                </Select>
              </FormControl>
              {timeOffRequestEndDate && timeOffRequestEndDate?.isAfter(timeOffRequestStartDate) &&
              <FormControl variant="outlined" fullWidth margin="normal" size="small">
                <InputLabel>Last day duration</InputLabel>
                <Select
                  value={durationTo}
                  label={"Last day duration"}
                  onChange={e => setDurationTo(Duration[e.target.value! as keyof typeof Duration])}
                >
                  <MenuItem value={Duration.FULL_DAY}>Full day</MenuItem>
                  <MenuItem value={Duration.HALF_DAY}>Half a day</MenuItem>
                </Select>
              </FormControl>
              }
            </Box>

            <FormControl variant="outlined" fullWidth margin="dense" size="small">
              <TextField label="Comment" multiline rows={4} value={timeOffRequestDescription} onChange={e => setTimeOffRequestDescription(e.target.value)}/>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={onCancelRequestTimeOffDialog}>Cancel</Button>
          <Button variant="contained" disabled={timeOffRequestBalanceId == null || timeOffRequestStartDate == null || timeOffRequestEndDate == null} onClick={onAddTimeOffRequest}>Add</Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}