import React, {useEffect, useState} from 'react';
import {useLogin, useMe, User} from "../api";


class Auth {
  loaded: boolean = false
  loggedIn: boolean = false
  user?: User
  login: (email: string, password: string) => void = () => {};
  logout: () => void = () => {};
}

const AuthContext = React.createContext<Auth>(new Auth());

export const useAuth = () => {
  return React.useContext(AuthContext);
}

export const AuthProvider = (props: { children: React.ReactNode }) => {

  const init = new Auth()
  const [auth, setAuth] = useState<Auth>(init)

  const { loading, error, data } = useMe()
  const [login] = useLogin()

  useEffect(() => {
    if (data) {
      setAuth(x => ({
        ...x,
        loaded: true,
        loggedIn: data.me.loggedIn,
        user: data.me.user
      }))
    } else if (error) {
      console.error(error)
    }
  }, [loading])

  init.login = (email, password) => {
    login({variables: {email: email, password: password}}).then(response => {
      setAuth({
        ...auth,
        loaded: true,
        loggedIn: response.data?.login.loggedIn ?? false,
        user: response.data?.login.user
      })
    })
  }

  return (
    <AuthContext.Provider value={auth}>
      {props.children}
    </AuthContext.Provider>
  );
};