import {round} from "./string-format-util";


export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const weekDaysShort = [
  'Mo',
  'Tu',
  'We',
  'Th',
  'Fr',
  'Sa',
  'Su'
]

export const formatTime = (x: number) => {
  return x < 10 ? '0' + x : x.toString()
}

export const toTimeDuration = (fromTime: string, toTime: string) => {
  const fromHour = fromTime.split(":")[0]
  const fromMinute = fromTime.split(":")[1]

  const toHour = toTime.split(":")[0]
  const toMinute = toTime.split(":")[1]

  return {
    ...toTimeDuration2(fromHour, fromMinute, toHour, toMinute),
    fromHour,
    fromMinute,
    toHour,
    toMinute
  }
}

export const toTimeDuration2 = (fromHour: string, fromMinute: string, toHour: string, toMinute: string) => {

  const clockwiseFromMinutes = (parseInt(fromHour) * 60 + parseInt(fromMinute))
  const clockwiseToMinutes = (parseInt(toHour) * 60 + parseInt(toMinute))

  const minutesDiff = clockwiseToMinutes > clockwiseFromMinutes ? clockwiseToMinutes - clockwiseFromMinutes : 1440 - clockwiseFromMinutes + clockwiseToMinutes
  const diffHours = Math.floor(minutesDiff / 60)
  const diffMinutes = minutesDiff % 60

  const hours = diffHours > 0 ? diffHours + 'h' : ''
  const minutes = diffMinutes > 0 ? diffMinutes + 'm' : ''
  const text = hours + ' ' + minutes

  return {minutes: minutesDiff, text};
}

export const hoursToDays = (hours: number): number => {
  return round(hours / 8, 2)
}

export const minutesToDays = (minutes: number): number => {
  return hoursToDays(minutes / 60)
}

export const daysToHours = (days: number): number => {
  return Math.floor(days * 8)
}

export const daysToMinutes = (days: number): number => {
  return Math.floor(days * 8 * 60)
}