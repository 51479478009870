import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import {TimeOffPolicy, useDeleteTimeOffPolicy, useTimeOffPolicies} from "../api";
import * as React from "react";
import {useRef, useState} from "react";
import {ApiError} from "../errors";
import {NavLink} from "../common/NavLink";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {headerStyle} from "../style-util";

export const TimeOffPolicyList = () => {

  const navigate = useNavigate()
  const list = useTimeOffPolicies()
  const [selectedItem, setSelectedItem] = useState<TimeOffPolicy | null>(null)
  const policyPopoverBtnAnchorRef = useRef(new Map())

  const [deleteTimeOffPolicy] = useDeleteTimeOffPolicy()
  const [deleteDialog, setDeleteDialog] = useState(false)

  const onConfirmDelete = () => {
    if (!selectedItem) {
      return
    }

    deleteTimeOffPolicy({
      variables: {
        id: selectedItem.id
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          //TODO
        }
      } else {
        list.refetch()
        setDeleteDialog(false)
      }
    })
  }

  const onOpenDeleteDialog = (item: TimeOffPolicy) => {
    setSelectedItem(item)
    setDeleteDialog(true)
  }



  return (
    <Box sx={{marginTop: 2, display: "flex", gap: "10px", flexWrap: "wrap"}}>
      <Box sx={{width: "100%"}}>
        <Paper>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px"}}>
            <Box sx={headerStyle}>
              Time off policies
            </Box>
            <Button variant="outlined" component={NavLink} to={"/settings/time-off-policy/add"} color="primary">
              + Add
            </Button>
          </Box>

          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Name</TableCell>
                    <TableCell variant="head">Paid</TableCell>
                    <TableCell variant="head">Unlimited</TableCell>
                    <TableCell variant="head">Total</TableCell>
                    <TableCell variant="head">Accrual</TableCell>
                    <TableCell variant="head">Available after</TableCell>
                    <TableCell variant="head">Carryover max days</TableCell>
                    <TableCell variant="head">Carryover expiration months</TableCell>
                    <TableCell variant="head">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.data?.timeOffPolicies.map(x => {
                    return (
                      <TableRow key={x.id}>
                        <TableCell>
                          <Link component={RouterLink} to={"/settings/time-off-policy/" + x.id + "/users"}>
                            {x.name}
                          </Link>
                        </TableCell>
                        <TableCell>{x.paid ? 'yes' : 'no'}</TableCell>
                        <TableCell>{x.unlimited ? 'yes' : 'no'}</TableCell>
                        <TableCell>{x.totalDaysOff ?? '-'}</TableCell>
                        <TableCell>{x.accrual ?? '-'}</TableCell>
                        <TableCell>{x.probationPeriodDays ?? '-'}</TableCell>
                        <TableCell>{x.carryoverMaxDays > 0 ? x.carryoverMaxDays : x.carryoverMaxDays === -1 ? 'unlimited' : '-'}</TableCell>
                        <TableCell>{x.carryoverExpirationMonths ?? '-'}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => setSelectedItem(x)} ref={ref => policyPopoverBtnAnchorRef.current.set(x.id, ref)}>
                            <MoreVertIcon/>
                          </IconButton>
                          <Menu open={x === selectedItem}
                                anchorEl={policyPopoverBtnAnchorRef.current.get(x.id)}
                                onClose={() => setSelectedItem(null)}>
                            <MenuItem component={NavLink} to={"/settings/time-off-policy/" + x.id + "/users"}>
                              Users
                            </MenuItem>
                            <MenuItem onClick={_ => navigate("/settings/time-off-policy/" + x.id + "/edit")}>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={_ => onOpenDeleteDialog(x)}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Box>


      <Dialog open={deleteDialog}>
        <DialogTitle>Delete Time Off Policy</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          Are you sure you want to delete '{selectedItem?.name ?? ''}'?
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={e => setDeleteDialog(false)}>No</Button>
          <Button variant="contained" color="error" onClick={onConfirmDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}