import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import {useAddOvertimeRequest, User} from "../api";
import {MobileDatePicker} from "@mui/x-date-pickers-pro";
import moment, {Moment} from "moment";
import {formatTime} from "../data-time-util";

export const OvertimeAdd = (props: { user: User, onAdded?: () => void }) => {

  const [date, setDate] = useState<Moment | null>(moment())
  const [fromHour, setFromHour] = useState<number>(0)
  const [fromMinute, setFromMinute] = useState<number>(0)
  const [toHour, setToHour] = useState<number>(0)
  const [toMinute, setToMinute] = useState<number>(0)
  const [description, setDescription] = useState<string>("")
  const [openDialog, setOpenDialog] = useState(false)

  const [addOvertimeRequest] = useAddOvertimeRequest()

  const minDate = moment().subtract(100, 'years')
  const maxDate = moment().add(100, 'years')

  const onCancelRequestTimeOffDialog = () => {
    setFromHour(0)
    setFromMinute(0)
    setToHour(0)
    setToMinute(0)
    setDescription("")
    setOpenDialog(false)
  }

  const onAdd = () => {
    addOvertimeRequest({
      variables: {
        date: date?.utc(true).format('YYYY-MM-DD'),
        fromTime: `${formatTime(fromHour)}:${formatTime(fromMinute)}`,
        toTime: `${formatTime(toHour)}:${formatTime(toMinute)}`,
        description: description
      }
    }).then(response => {
      if (response.error) {
        /*const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNameError(e.message)
        }*/
      } else {
        onCancelRequestTimeOffDialog()
        props.onAdded?.()
      }
    })
  }

  return (
    <Box>
      <Button variant="outlined" sx={{backgroundColor: "white"}} onClick={_ => setOpenDialog(true)}>Request Overtime</Button>
      <Dialog open={openDialog}>
        <DialogTitle>Request Overtime</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          <Box sx={{display: "flex", flexDirection: "row", gap: "5px", marginTop: "30px", alignItems: "center"}}>
            <Box sx={{width: "50px"}}>Date</Box>
            <MobileDatePicker
              showToolbar={false}
              label="Date"
              inputFormat="YYYY-MM-DD"
              minDate={minDate}
              maxDate={maxDate}
              value={date}
              onChange={setDate}
              renderInput={(params) => <TextField size="small" {...params} sx={{width: "154px"}}/>}
            />
          </Box>
          <Box sx={{display: "flex", flexDirection: "row", gap: "5px", marginTop: "30px", alignItems: "center"}}>
            <Box sx={{width: "50px"}}>From</Box>
            <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
              <InputLabel>hour</InputLabel>
              <Select
                size="small"
                label="hour"
                value={fromHour}
                onChange={e => setFromHour(e.target.value as number)}
              >
                {Array.from(new Array(24), (x, i) => i).map(i =>
                  <MenuItem value={i} key={i}>
                    {formatTime(i)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            :
            <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
              <InputLabel>min</InputLabel>
              <Select
                size="small"
                label="min"
                value={fromMinute}
                onChange={e => setFromMinute(e.target.value as number)}
              >
                {Array.from(new Array(60), (x, i) => i).map(i =>
                  <MenuItem value={i} key={i}>
                    {i < 10 ? '0' + i : i}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{display: "flex", flexDirection: "row", gap: "5px", marginTop: "30px", alignItems: "center"}}>
            <Box sx={{width: "50px"}}>To</Box>
            <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
              <InputLabel>hour</InputLabel>
              <Select
                size="small"
                label="hour"
                value={toHour}
                onChange={e => setToHour(e.target.value as number)}
              >
                {Array.from(new Array(24), (x, i) => i).map(i =>
                  <MenuItem value={i} key={i}>
                    {i < 10 ? '0' + i : i}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            :
            <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
              <InputLabel>min</InputLabel>
              <Select
                size="small"
                label="min"
                value={toMinute}
                onChange={e => setToMinute(e.target.value as number)}
              >
                {Array.from(new Array(60), (x, i) => i).map(i =>
                  <MenuItem value={i} key={i}>
                    {i < 10 ? '0' + i : i}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <FormControl variant="outlined" fullWidth margin="dense" size="small" sx={{marginTop: "30px"}}>
            <TextField label="Comment" multiline rows={4} value={description} onChange={e => setDescription(e.target.value)}/>
          </FormControl>
        </DialogContent>

        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={onCancelRequestTimeOffDialog}>Cancel</Button>
          <Button variant="contained" onClick={onAdd}>Add</Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}