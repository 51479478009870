import * as React from 'react';
import {useRef, useState} from 'react';
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import {useDepartments, useLocations, usePayrollReport, useTeams} from "../api";
import {Link as RouterLink} from "react-router-dom";
import {useAuth} from "../auth/AuthProvider";
import {nameAbbreviation, round} from "../string-format-util";
import moment from "moment";
import {MobileDatePicker} from "@mui/x-date-pickers-pro";
import {Moment} from "moment/moment";
import {groupByAndMap} from "../collection-util";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {formatTime} from "../data-time-util";
import {Spinner} from "../common/Spinner";

export const PayrollReport = () => {
  const auth = useAuth()

  const now = moment()
  const [period, setPeriod] = useState<Moment>(now)

  const [locationId, setLocationId] = useState("")
  const [departmentId, setDepartmentId] = useState("")
  const [teamId, setTeamId] = useState("")

  const payrollReport = usePayrollReport(period.year(), period.month() + 1, locationId, departmentId, teamId)
  const locations = useLocations()
  const departments = useDepartments()
  const teams = useTeams()

  const minDate = moment(auth.user?.company?.createdAt)
  const maxDate = moment()

  const userTimeOff = new Map(
    payrollReport.data?.payrollReport.users.map(x =>
      [
        x.user.id,
        groupByAndMap(
          x.timeOff,
          a => a.policyId,
          b => b.reduce((acc, next) => acc + next.hours, 0)
        )
      ]
    )
  )

  const userOvertime = new Map(
    payrollReport.data?.payrollReport.users.filter(x => x.overtimeHours).map(x =>
      [
        x.user.id,
        x.overtimeHours
      ]
    )
  )

  const cellStyle = {
    textAlign: "center",
    verticalAlign: "top"
  }

  const headerDataStyle = {
    overflow: "hidden",
    overflowY: "hidden",
    width: "70px"
  }

  const onDownload = () => {
    //window.open('/api/v1/report/payroll', '_blank')
  }

  const [showDownloadMenu, setShowDownloadMenu] = useState(false)
  const downloadPopoverBtnAnchorRef = useRef(null)

  if (payrollReport.loading) {
    return <Spinner marginTop={"30vh"} />
  }

  return (
    <Paper>
      <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 1}}>

        <Box sx={{width: "100%", display: "flex", justifyContent: 'space-between', marginBottom: 1}}>
          <Box sx={{display: "flex", gap: "10px"}}>

            <MobileDatePicker
              showToolbar={false}
              views={['year', 'month']}
              label="Period"
              inputFormat="YYYY-MM"
              minDate={minDate}
              maxDate={maxDate}
              value={period}
              onChange={x => setPeriod(x!!)}
              renderInput={(params) => <TextField size="small" {...params} />}
            />

            <FormControl variant="outlined" size="small" sx={{width: "200px"}}>
              <InputLabel>Location</InputLabel>
              <Select
                size="small"
                label="Location"
                value={locationId}
                onChange={(e) => setLocationId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {locations.data?.locations.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{width: "200px"}}>
              <InputLabel>Department</InputLabel>
              <Select
                size="small"
                label="Department"
                value={departmentId}
                onChange={(e) => setDepartmentId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {departments.data?.departments.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{width: "200px"}}>
              <InputLabel>Team</InputLabel>
              <Select
                size="small"
                label="Team"
                value={teamId}
                onChange={(e) => setTeamId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {teams.data?.teams.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <IconButton onClick={() => setShowDownloadMenu(true)} ref={downloadPopoverBtnAnchorRef}>
            <FileDownloadOutlinedIcon/>
          </IconButton>

          <Menu open={showDownloadMenu}
                anchorEl={downloadPopoverBtnAnchorRef.current}
                onClose={() => setShowDownloadMenu(false)}>
            <MenuItem onClick={() => setShowDownloadMenu(false)} dense={true}>
              <Link href={`/api/v1/report/payroll?year=${period.year()}&month=${period.month() + 1}&locationId=${locationId}&departmentId=${departmentId}&teamId=${teamId}&type=CSV`}
                 download={period.year() + "-" + (formatTime(period.month() + 1)) + "-payroll-report.csv"}>
                CSV
              </Link>
            </MenuItem>
            <MenuItem onClick={() => {setShowDownloadMenu(false)}} dense={true}>
              <Link href={`/api/v1/report/payroll?year=${period.year()}&month=${period.month() + 1}&locationId=${locationId}&departmentId=${departmentId}&teamId=${teamId}&type=XLSX`}
                 download={period.year() + "-" + (formatTime(period.month() + 1)) + "-payroll-report.xlsx"}>
                XLSX
              </Link>
            </MenuItem>
          </Menu>


        </Box>

      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head" sx={{width: "200px", verticalAlign: "top"}}>Full Name</TableCell>
              <TableCell sx={{width: "100px", ...cellStyle}} variant="head">Expected</TableCell>
              <TableCell sx={{width: "100px", ...cellStyle}} variant="head">Actual</TableCell>
              {payrollReport.data?.payrollReport.timeOffPolicies.map(x =>
                <TableCell sx={cellStyle} variant="head" key={x.id}>
                  <Box sx={headerDataStyle}>
                    <Box title={x.name} sx={{maxHeight: "50px", overflow: "hidden"}}>{x.name}</Box>
                    <Box sx={{fontSize: "12px", color: "#a3a9ae", lineHeight: "12px"}}>{x.paid ? 'paid' : 'unpaid'}</Box>
                  </Box>
                </TableCell>
              )}
              {userOvertime.size > 0 &&
                <TableCell sx={{...cellStyle}} variant="head">
                  <Box sx={headerDataStyle}>
                    <Box>Overtime</Box>
                    <Box sx={{fontSize: "12px", color: "#a3a9ae", lineHeight: "12px"}}>paid</Box>
                  </Box>
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {payrollReport.data?.payrollReport.users.map(x => {
              return (
                <TableRow key={x.user.id}>
                  <TableCell>
                    <Link underline="none" component={RouterLink} to={"/people/person/" + x.user.id + "/profile"}>
                      <Box sx={{display: "flex", gap: "10px", alignItems: "center"}}>
                        <Avatar src={x.user.photoUrl} sx={{fontSize: "14px"}}>
                          {nameAbbreviation(x.user.fullName)}
                        </Avatar>
                        {x.user.fullName}
                      </Box>
                    </Link>
                  </TableCell>
                  <TableCell sx={cellStyle}>{x.total}h</TableCell>
                  <TableCell sx={cellStyle}>{x.paid}h</TableCell>
                  {payrollReport.data?.payrollReport.timeOffPolicies.map(a => {
                    const b = userTimeOff.get(x.user.id)?.get(a.id)
                    return (<TableCell sx={cellStyle} key={a.id}>{b ? b + "h" : ""}</TableCell>)
                  })}
                  {userOvertime.size > 0 && x.overtimeHours &&
                    <TableCell sx={cellStyle}>{x.overtimeHours}h</TableCell>
                  }
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}