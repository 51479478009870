import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {
  TimeOffBalance,
  TimeOffHistory,
  TimeOffRequest,
  TimeOffRequestStatus,
  User,
  UserRole,
  useUpdateTimeOffBalance,
  useUpdateTimeOffBalanceActivationDate,
  useUpdateTimeOffBalanceApprover,
  useUpdateTimeOffRequestStatus,
  useUsers,
  useUserTimeOffBalances,
  useUserTimeOffHistory,
  useUserTimeOffRequests
} from "../api";
import moment, {Moment} from "moment";
import {TimeOffAdd} from "./TimeOffAdd";
import {useAuth} from "../auth/AuthProvider";
import {headerStyle, paperItemStyle, StatusColor, statusLabel} from "../style-util";
import EditIcon from "@mui/icons-material/Edit";
import {ApiError} from "../errors";
import {round} from "../string-format-util";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {MobileDatePicker} from "@mui/x-date-pickers-pro";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {MoreLessText} from "../common/MoreLessText";
import {DataLoaderSpinner} from "../common/DataLoaderSpinner";
import {daysToMinutes, hoursToDays, minutesToDays} from "../data-time-util";

export const PersonTimeOff = (props: {user: User}) => {

  const auth = useAuth()
  const isAdmin = auth.user?.role === UserRole.COMPANY_ADMIN

  const today = moment();

  const minDate = moment().subtract(100, 'years')
  const maxDate = moment().add(100, 'years')

  const startYear = moment(auth.user?.company?.createdAt).year()
  const endYear = today.year()
  const yearsRange = Array.from(Array(endYear - startYear + 1).keys()).map(x => x + startYear)

  const [year, setYear] = useState<number>(today.year())

  const balances = useUserTimeOffBalances(props.user.id)
  const requests = useUserTimeOffRequests(props.user.id, year)
  const history = useUserTimeOffHistory(props.user.id, year)
  const users = useUsers(0, 10000)

  const [editTimeOffBalanceId, setEditTimeOffBalanceId] = useState<string>()
  const [editTimeOffBalanceUnlimited, setEditTimeOffBalanceUnlimited] = useState<boolean>()

  const [editTimeOffBalanceDays, setEditTimeOffBalanceDays] = useState<number>()

  const [editTimeOffBalanceManagerApproval, setEditTimeOffBalanceManagerApproval] = useState(false)
  const [editTimeOffBalanceAdminApproval, setEditTimeOffBalanceAdminApproval] = useState(false)
  const [editTimeOffBalanceApprovers, setEditTimeOffBalanceApprovers] = useState<Array<User>>([])
  const [requiresApproval, setRequiresApproval] = useState(true)
  const [requiresUsersApproval, setRequiresUsersApproval] = useState(false)

  const [editTimeOffBalanceActivationDate, setEditTimeOffBalanceActivationDate] = useState<Moment | null>(null)

  const [editTimeOffBalanceDialog, setEditTimeOffBalanceDialog] = useState(false)
  const [updateTimeOffBalance] = useUpdateTimeOffBalance()
  const [updateTimeOffBalanceApprover] = useUpdateTimeOffBalanceApprover()
  const [updateTimeOffBalanceActivationDate] = useUpdateTimeOffBalanceActivationDate()

  const [tabIdx, setTabIdx] = useState(0)

  const [updateTimeOffRequestStatus] = useUpdateTimeOffRequestStatus()

  const [selectedTimeOffRequest, setSelectedTimeOffRequest] = useState<TimeOffRequest | null>(null)
  const [cancelTimeOffRequestDialog, setCancelTimeOffRequestDialog] = useState(false)

  const [timeOffRequestApproverComment, setTimeOffRequestApproverComment] = useState<string | null>(null)
  const [updateTimeOffBalanceComment, setUpdateTimeOffBalanceComment] = useState<string | null>(null)
  const [updateTimeOffBalanceActivationDateComment, setUpdateTimeOffBalanceActivationDateComment] = useState<string | null>(null)

  const timeOffRequestPopoverBtnAnchorRef = useRef(new Map())

  const labelStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }

  const sectionStyle = {
    marginBottom: "30px"
  }

  const hStyle = {
    ...headerStyle,
    marginBottom: "10px"
  }

  useEffect(() => {
    const unlimited = balances.data?.user?.timeOffBalances?.find(x => x.id === editTimeOffBalanceId)?.timeOffPolicy?.unlimited ?? false
    setEditTimeOffBalanceUnlimited(unlimited)
    if (unlimited) {
      setTabIdx(1)
    }
  }, [balances, editTimeOffBalanceId])

  const onOpenEditTimeOffBalanceDialog = (x: TimeOffBalance) => {
    setEditTimeOffBalanceId(x.id)

    setEditTimeOffBalanceDays(minutesToDays(x.availableMinutes))
    setUpdateTimeOffBalanceComment(null)

    setRequiresApproval(x.canManagerApprove || x.canAdminApprove || x.approvers.length > 0)
    setRequiresUsersApproval(x.approvers.length > 0)
    setEditTimeOffBalanceManagerApproval(x.canManagerApprove)
    setEditTimeOffBalanceAdminApproval(x.canAdminApprove)
    setEditTimeOffBalanceApprovers(users.data?.users?.list?.filter(a => x.approvers.indexOf(a.id) > -1) ?? [])

    setEditTimeOffBalanceActivationDate(moment(x.activationDate))
    setUpdateTimeOffBalanceActivationDateComment(null)

    setEditTimeOffBalanceDialog(true)
  }

  const onCancelEditTimeOffBalanceDialog = () => {
    setEditTimeOffBalanceId(undefined)
    setEditTimeOffBalanceDialog(false)
    setEditTimeOffBalanceDays(0)
    setEditTimeOffBalanceManagerApproval(false)
    setEditTimeOffBalanceAdminApproval(false)
    setEditTimeOffBalanceApprovers([])
    setEditTimeOffBalanceActivationDate(null)
    setUpdateTimeOffBalanceComment(null)
    setUpdateTimeOffBalanceActivationDateComment(null)
  }

  const onUpdateTimeOffBalance = () => {
    if (!editTimeOffBalanceId) {
      return
    }

    updateTimeOffBalance({
      variables: {
        id: editTimeOffBalanceId,
        minutes: daysToMinutes(editTimeOffBalanceDays ?? 0),
        comment: updateTimeOffBalanceComment
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          //setNewNameError(e.message)
        }
      } else {
        balances.refetch()
        history.refetch()
        onCancelEditTimeOffBalanceDialog()
      }
    })
  }

  const onUpdateTimeOffBalanceApprover = () => {
    if (!editTimeOffBalanceId) {
      return
    }

    updateTimeOffBalanceApprover({
      variables: {
        id: editTimeOffBalanceId,
        canManagerApprove: requiresApproval && editTimeOffBalanceManagerApproval,
        canAdminApprove: requiresApproval && editTimeOffBalanceAdminApproval,
        approvers: requiresApproval ? editTimeOffBalanceApprovers.map(x => x.id) : []
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          //setNewNameError(e.message)
        }
      } else {
        balances.refetch()
        onCancelEditTimeOffBalanceDialog()
      }
    })
  }

  const onUpdateTimeOffBalanceActivationDate = () => {
    if (!editTimeOffBalanceId) {
      return
    }

    updateTimeOffBalanceActivationDate({
      variables: {
        id: editTimeOffBalanceId,
        date: editTimeOffBalanceActivationDate?.utc(true).format('YYYY-MM-DD'),
        comment: updateTimeOffBalanceActivationDateComment
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          //setNewNameError(e.message)
        }
      } else {
        balances.refetch()
        history.refetch()
        onCancelEditTimeOffBalanceDialog()
      }
    })
  }

  const onChangeTab = (event: React.SyntheticEvent, idx: number) => {
    setTabIdx(idx);
  }

  const onOpenCancelTimeOffRequestDialog = (item: TimeOffRequest) => {
    setSelectedTimeOffRequest(item)
    setCancelTimeOffRequestDialog(true)
    setTimeOffRequestApproverComment(null)
  }

  const onCloseCancelTimeOffRequestDialog = () => {
    setSelectedTimeOffRequest(null)
    setCancelTimeOffRequestDialog(false)
    setTimeOffRequestApproverComment(null)
  }

  const onChangeTimeOffRequestStatus = (status: TimeOffRequestStatus) => {
    if (!selectedTimeOffRequest) {
      return
    }

    updateTimeOffRequestStatus({
      variables: {
        timeOffRequestId: selectedTimeOffRequest.id,
        status: status,
        comment: timeOffRequestApproverComment
      }
    }).then(response => {
      if (response.error) {
        console.error(response.error)
      } else {
        balances.refetch()
        requests.refetch()
        history.refetch()
      }
      onCloseCancelTimeOffRequestDialog()
    })
  }

  const cellStyle = {
    verticalAlign: "top"
  }

  const historyHours = (x: TimeOffHistory) => {
    if (x.amountMinutes === null) {
      return ""
    }

    let sign = "";

    if (x.type === 'ADJUST') {
      sign = x.amountMinutes > 0 ? '+' : ''
    }

    if (x.type === 'ACCRUAL' || x.type === 'CANCEL') {
      sign = '+'
    }

    if (x.type === 'TIME_OFF' || x.type === 'EXPIRED') {
      sign = '-'
    }

    return sign + minutesToDays(x.amountMinutes);
  }

  const balanceCardStyle = {...paperItemStyle, marginBottom: "10px"}

  return (
    <Box>
      <Box sx={sectionStyle}>
        <Box sx={hStyle}>
            Balances
        </Box>
        <Box className="hide-scroll" sx={{display: "flex", marginBottom: "10px", overflowX: "auto", maxWidth: "950px", gap: "20px"}}>
          {balances.loading &&
            <>
                <Skeleton variant="rectangular" sx={balanceCardStyle} width={200} height={121} />
                <Skeleton variant="rectangular" sx={balanceCardStyle} width={200} height={121} />
                <Skeleton variant="rectangular" sx={balanceCardStyle} width={200} height={121} />
            </>
          }

          {balances.data?.user?.timeOffBalances?.map(x =>
            <Box sx={balanceCardStyle} key={x.id}>
              <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Box sx={{...labelStyle, color: (moment(x.activationDate).isAfter(today) ? colors.grey[500]: 'unset')}}>{x.timeOffPolicy.name}</Box>

                {isAdmin &&
                <IconButton onClick={_ => onOpenEditTimeOffBalanceDialog(x)}>
                  <EditIcon fontSize={"small"}/>
                </IconButton>
                }
              </Box>

              {!moment(x.activationDate).isAfter(today) &&
                <>
                {!x.timeOffPolicy.unlimited &&
                  <Box sx={{color: "green"}}>
                    <Box sx={{display: "inline-block", color: "green", fontSize: "22px"}}>{minutesToDays(x.availableMinutes)}</Box><Typography variant="caption"> days available</Typography>
                  </Box>
                }
                <Box sx={{display: "inline-block", color: colors.grey[500]}}>{round(x.usedHours / 8, 2)}<Typography variant="caption"> days used</Typography></Box>
                </>
              }

              {moment(x.activationDate).isAfter(today) &&
                <>
                <Box sx={{color: colors.grey[500]}}>
                  <Box sx={{display: "inline-block", color: colors.grey[500], fontSize: "22px"}}>{minutesToDays(x.availableMinutes)}</Box><Typography variant="caption"> days accumulated</Typography>
                </Box>
                <Box sx={{display: "inline-block", color: colors.grey[500]}}>
                  <Typography variant="caption">Activation date: {x.activationDate}</Typography>
                </Box>
                </>
              }
            </Box>
          )}
        </Box>
        {auth.user?.id === props.user.id &&
          <TimeOffAdd user={props.user} onAdded={() => {
            balances.refetch()
            requests.refetch()
            history.refetch()
          }}/>
        }
      </Box>
      <Dialog onClose={onCancelEditTimeOffBalanceDialog} open={editTimeOffBalanceDialog}>
        <DialogContent sx={{width: "400px"}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "20px" }}>
            <Tabs value={tabIdx} onChange={onChangeTab}>
              {!editTimeOffBalanceUnlimited &&
                <Tab label="Balance" />
              }
              <Tab label="Approval" />
              <Tab label="Activation" />
            </Tabs>
          </Box>
          {tabIdx === 0 && !editTimeOffBalanceUnlimited &&
            <Box>
              <TextField
                autoFocus
                label="Days"
                type="number"
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                // error={newNameError !== ''}
                // helperText={newNameError}
                value={editTimeOffBalanceDays}
                onChange={e => {
                  if (e.target.value === '-') {
                    setEditTimeOffBalanceDays(-0)
                  } else if (e.target.value === '') {
                    setEditTimeOffBalanceDays(undefined)
                  } else {
                    setEditTimeOffBalanceDays(round(parseFloat(e.target.value), 2))
                  }
                }}
              />
              <TextField
                multiline
                rows={2}
                type="text"
                label="Comment"
                fullWidth
                variant="outlined"
                margin="normal"
                value={updateTimeOffBalanceComment}
                onChange={e => setUpdateTimeOffBalanceComment(e.target.value)}
              />
              <DialogActions sx={{padding: "10px 0px"}}>
                <Button onClick={_ => onCancelEditTimeOffBalanceDialog()}>Cancel</Button>
                <Button variant="contained" onClick={() => onUpdateTimeOffBalance()}>Update</Button>
              </DialogActions>
            </Box>
          }
          {tabIdx === 1 &&
            <Box>
              <FormGroup>
                <FormControlLabel control={<Checkbox size="small" checked={requiresApproval} onChange={x => setRequiresApproval(x.target.checked)} />} label="Requires approval" />
                {requiresApproval &&
                    <Box sx={{marginTop: "20px"}}>
                      <Box>Who can approve</Box>

                        <Box sx={{borderTop: 1, borderColor: 'divider'}}>
                          <FormControlLabel control={<Checkbox size="small" checked={editTimeOffBalanceManagerApproval} onChange={x => setEditTimeOffBalanceManagerApproval(x.target.checked)} />} label="Manager" />

                          <FormControlLabel control={<Checkbox size="small" checked={editTimeOffBalanceAdminApproval} onChange={x => setEditTimeOffBalanceAdminApproval(x.target.checked)} />} label="Admin" />

                          <FormControlLabel control={<Checkbox size="small" checked={requiresUsersApproval} onChange={x => setRequiresUsersApproval(x.target.checked)} />} label="Specific users" />
                          {requiresUsersApproval &&
                            <FormControl variant="outlined" fullWidth margin="normal" size="small">
                              <Autocomplete
                                multiple={true}
                                options={users.data?.users?.list?.filter(x => !editTimeOffBalanceApprovers.find(a => a.id === x.id)) || []}
                                value={editTimeOffBalanceApprovers}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={option => option.fullName}
                                onChange={(e, value) => setEditTimeOffBalanceApprovers(value)}
                                renderInput={(params) => <TextField {...params} label="Users" />}
                              />
                            </FormControl>
                          }
                        </Box>
                    </Box>
                }
              </FormGroup>

              <DialogActions sx={{padding: "10px 0px"}}>
                <Button onClick={_ => onCancelEditTimeOffBalanceDialog()}>Cancel</Button>
                <Button variant="contained" onClick={() => onUpdateTimeOffBalanceApprover()}>Update</Button>
              </DialogActions>
            </Box>
          }
          {tabIdx === 2 &&
            <Box>
              <MobileDatePicker
                showToolbar={false}
                label="Activation date"
                inputFormat="YYYY-MM-DD"
                minDate={minDate}
                maxDate={maxDate}
                value={editTimeOffBalanceActivationDate}
                onChange={x => setEditTimeOffBalanceActivationDate(x)}
                renderInput={(params) => <TextField sx={{width: "100%"}} size="small" {...params} />}
              />
              <TextField
                multiline
                rows={2}
                type="text"
                label="Comment"
                fullWidth
                variant="outlined"
                margin="normal"
                value={updateTimeOffBalanceActivationDateComment}
                onChange={e => setUpdateTimeOffBalanceActivationDateComment(e.target.value)}
              />
              <DialogActions sx={{padding: "10px 0px"}}>
                <Button onClick={onCancelEditTimeOffBalanceDialog}>Cancel</Button>
                <Button variant="contained" onClick={onUpdateTimeOffBalanceActivationDate}>Update</Button>
              </DialogActions>
            </Box>
          }
        </DialogContent>
      </Dialog>

      <Box sx={sectionStyle}>
        <Box sx={{...hStyle, display: "flex", justifyContent: "space-between", alignItems: "end"}}>
          <Box>
            Requests history
          </Box>
          <FormControl variant="outlined" margin="normal" size="small" sx={{margin: "0px"}}>
            <InputLabel>Year</InputLabel>
            <Select
              value={year}
              label={"Year"}
              SelectDisplayProps={{style: {padding: "5px 35px 5px 15px"}}}
              onChange={e => setYear(e.target.value as number)}
            >
              {yearsRange.map(x =>
                <MenuItem key={x} value={x}>{x}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Type</TableCell>
                  <TableCell variant="head">Requested on</TableCell>
                  <TableCell variant="head">Start</TableCell>
                  <TableCell variant="head">End</TableCell>
                  <TableCell variant="head">Days</TableCell>
                  <TableCell variant="head">Comment</TableCell>
                  <TableCell variant="head">Status</TableCell>
                  {isAdmin &&
                    <TableCell variant="head" sx={{textAlign: "center"}}>Action</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                { requests.data?.user?.timeOffRequests?.map(x => {
                  return (
                    <TableRow key={x.id}>
                      <TableCell sx={cellStyle}>{x.timeOffPolicy.name}</TableCell>
                      <TableCell sx={{...cellStyle, whiteSpace: "nowrap"}}>{moment(x.createdAt).format("YYYY-MM-DD")}</TableCell>
                      <TableCell sx={{...cellStyle, whiteSpace: "nowrap"}}>{moment(x.start).format("YYYY-MM-DD")}</TableCell>
                      <TableCell sx={{...cellStyle, whiteSpace: "nowrap"}}>{moment(x.end).format("YYYY-MM-DD")}</TableCell>
                      <TableCell sx={cellStyle}>{hoursToDays(x.amountHours)}</TableCell>
                      <TableCell sx={{...cellStyle, maxWidth: "250px", minWidth: "250px", wordBreak: "break-word"}}>
                        <MoreLessText text={x.description} len={25}/>
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        {
                          statusLabel(
                            x.status,
                            x.status === 'APPROVED' ? StatusColor.GREEN :
                              x.status === 'DECLINED' ? StatusColor.RED :
                              x.status === 'CANCELLED' ? StatusColor.ORANGE
                              : StatusColor.BLUE
                          )
                        }
                        {x.approverComment &&
                          <Tooltip title={x.approverComment}>
                            <InfoOutlinedIcon sx={{color: colors.grey[500], position: "absolute", marginTop: "3px", marginLeft: "5px", fontSize: "16px"}}/>
                          </Tooltip>
                        }
                      </TableCell>
                      {isAdmin &&
                        <TableCell variant="head" style={{padding: "7px 0px", textAlign: "center", verticalAlign: "top"}}>
                          {x.status !== TimeOffRequestStatus.DECLINED && x.status !== TimeOffRequestStatus.CANCELLED &&
                            <>
                              <IconButton onClick={() => setSelectedTimeOffRequest(x)}
                                          ref={ref => timeOffRequestPopoverBtnAnchorRef.current.set(x.id, ref)}>
                                <MoreVertIcon/>
                              </IconButton>

                              <Menu
                                open={x === selectedTimeOffRequest}
                                anchorEl={timeOffRequestPopoverBtnAnchorRef.current.get(x.id)}
                                onClose={() => setSelectedTimeOffRequest(null)}
                              >
                                {x.status === TimeOffRequestStatus.REQUESTED &&
                                  <>
                                  <MenuItem onClick={_ => onChangeTimeOffRequestStatus(TimeOffRequestStatus.APPROVED)}>Approve</MenuItem>
                                  <MenuItem onClick={_ => onChangeTimeOffRequestStatus(TimeOffRequestStatus.DECLINED)}>Decline</MenuItem>
                                  </>
                                }
                                {x.status === TimeOffRequestStatus.APPROVED &&
                                  <MenuItem onClick={() => onOpenCancelTimeOffRequestDialog(x)}>Cancel</MenuItem>
                                }
                              </Menu>
                            </>
                          }
                        </TableCell>
                      }
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <DataLoaderSpinner isLoaded={!requests.loading} hasData={(requests.data?.user?.timeOffRequests.length ?? 0) > 0} />

          <Dialog open={cancelTimeOffRequestDialog}>
            <DialogTitle>Cancel time off request</DialogTitle>
            <DialogContent sx={{width: "400px"}}>
              <Box>
                Are you sure you want to cancel time off request?
              </Box>
              <TextField
                multiline
                rows={2}
                type="text"
                label="Comment"
                fullWidth
                variant="outlined"
                margin="normal"
                value={timeOffRequestApproverComment}
                onChange={e => setTimeOffRequestApproverComment(e.target.value)}
              />
            </DialogContent>
            <DialogActions sx={{padding: "10px 25px"}}>
              <Button onClick={_ => onCloseCancelTimeOffRequestDialog()}>No</Button>
              <Button variant="contained" color="error" onClick={e => onChangeTimeOffRequestStatus(TimeOffRequestStatus.CANCELLED)}>Yes</Button>
            </DialogActions>
          </Dialog>

        </Box>
      </Box>

      <Box sx={sectionStyle}>
        <Box sx={hStyle}>
          Balance history
        </Box>
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Type</TableCell>
                  <TableCell variant="head">Date</TableCell>
                  <TableCell variant="head">Days</TableCell>
                  <TableCell variant="head">Description</TableCell>
                  {/* TODO: Time Off Policy Name to display and think about deleted */}
                </TableRow>
              </TableHead>
              <TableBody>
                {history.data?.user?.timeOffHistory?.map(x => {
                  return (
                    <TableRow key={x.id}>
                      <TableCell sx={cellStyle}>{x.type === 'TIME_OFF' ? 'TIME OFF' : x.type}</TableCell>
                      <TableCell sx={{...cellStyle, whiteSpace: "nowrap"}}>{moment(x.createdAt).format("YYYY-MM-DD")}</TableCell>
                      <TableCell sx={cellStyle}>{historyHours(x)}</TableCell>{/* TODO: example +2 OR -2 for balance*/}
                      <TableCell sx={{...cellStyle,wordBreak: "break-word", minWidth: "650px", maxWidth: "650px"}}>
                        <MoreLessText text={x.description} len={80}/>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <DataLoaderSpinner isLoaded={!history.loading} hasData={(history.data?.user?.timeOffHistory.length ?? 0) > 0} />
        </Box>
      </Box>

    </Box>
  )
}