import {useMutation, useQuery} from "graphql-hooks";
import axios from 'axios';

export enum UserRole {
  USER = "USER",
  COMPANY_ADMIN = "COMPANY_ADMIN",
}

export enum UserStatus {
  INVITED = "INVITED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export const roleToString = (role: UserRole) => {
  switch (role) {
    case UserRole.USER:
      return "User"
    case UserRole.COMPANY_ADMIN:
      return "Admin"
    default:
      return ""
  }
}

export const statusToString = (status: UserStatus) => {
  if (status) {
    return status.toString().charAt(0).toUpperCase() + status.toString().slice(1).toLowerCase()
  }

  switch (status) {
    case UserStatus.ACTIVE:
      return "Active"
    case UserStatus.INACTIVE:
      return "Inactive"
    default:
      return ""
  }
}

export interface Company {
  id: string
  name: string
  logoUrl: string
  createdAt: string
  active: boolean
}

export interface Me {
  loggedIn: boolean
  user: User
}

export interface AuthResponse {
  me: Me
}

export interface IdName {
  id: string
  name: string
}

export interface User {
  id: string
  fullName: string
  photoUrl: string
  email: string
  phoneNumber: string
  title: string
  role: UserRole
  company?: Company
  startDate: string
  probationEndDate?: string
  probationEndDateNotificationUsers?: Array<IdName>
  endDate?: string
  birthDate?: string
  location?: IdName,
  employmentType?: IdName,
  team?: IdName,
  department?: IdName,
  manager?: IdName,
  workSchedule: UserWorkSchedule,
  status: UserStatus,
  timeOffBalances: [TimeOffBalance],
  holidaysCalendars: [UserHolidaysCalendar]
}

interface UserHolidaysCalendar {
  id: string
  name: string
}

interface UserWorkSchedule {
  id: string
  name: string
  type: WorkScheduleType
}

export enum TimeOffPolicyAccrual {
  MONTHLY = "MONTHLY",
  IMMEDIATELY = "IMMEDIATELY"
}

export interface TimeOffPolicy {
  id: string
  name: string
  paid: boolean,
  unlimited: boolean
  totalDaysOff: number
  accrual: TimeOffPolicyAccrual
  probationPeriodDays: number
  carryoverMaxDays: number
  carryoverExpirationMonths: number
}

export interface TimeOffBalance {
  id: string
  timeOffPolicy: TimeOffPolicy
  userId: string
  availableMinutes: number
  usedHours: number
  //scheduledHours: number
  canManagerApprove: boolean
  canAdminApprove: boolean
  approvers: Array<string>
  activationDate: string
}

export interface TimeOffRequestUser {
  id: string
  fullName: string
  title: string
  photoUrl: string
}

export enum TimeOffRequestStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  CANCELLED = "CANCELLED"
}

export interface TimeOffRequest {
  id: string
  start: string
  end: string
  description: string
  status: TimeOffRequestStatus
  timeOffPolicy: TimeOffPolicy
  user: TimeOffRequestUser
  amountHours: number
  approverComment: string
  createdAt: string
}

export interface TimeOffHistory {
  id: string
  type: string
  createdAt: string
  amountMinutes: number
  description: string
}

export interface Company {
  id: string
  name: string
}

export const useCompany = () => {
  return useQuery<{company: Company}>(`
    query {
      company {
        id
        name
        logoUrl
        createdAt
        active
      }
    }
`)
}

export const useCompanies = () => {
  return useQuery<{ companies: [Company] }>(`
    query {
      companies {
        id
        name
        createdAt
        active
      }
    }
`)
}

export const useLogout = () => {
  return useMutation<{ logout: boolean }>(`
    mutation logout {
      logout
    }
  `)
}


export const useSwitchCompany = () => {
  return useMutation<{ switchCompany: boolean }>(`
    mutation switchCompany($companyId: UUID!) {
      switchCompany(companyId: $companyId)
    }
  `)
}

export const useUsers = (page: number,
                         size: number,
                         locationId?: string,
                         departmentId?: string,
                         teamId?: string,
                         search?: string,
                         statuses?: Array<UserStatus>) => {
  if (locationId === '') {
    locationId = undefined
  }

  if (departmentId === '') {
    departmentId = undefined
  }

  if (teamId === '') {
    teamId = undefined
  }

  if (search === '') {
    search = undefined
  }

  if (statuses?.length === 0) {
    statuses = undefined
  }

  return useQuery<{users: {
    list: Array<User>,
    total: number
  }}>(`
    query users($page: Int, $size: Int, $locationId: UUID, $departmentId: UUID, $teamId: UUID, $search: String, $statuses: [UserStatus!]) {
      users(page: $page, size: $size, locationId: $locationId, departmentId: $departmentId, teamId: $teamId, search: $search, statuses: $statuses) {
        list {
          id
          fullName
          photoUrl
          email
          title
          status
          startDate
          endDate
          location {
            id
            name
          }
          department {
            id
            name
          }
          team {
            id
            name
          }
          manager {
            id
            name
          }
        }
        total
      }
    }
`, {variables: {
      page: page,
      size: size,
      locationId: locationId,
      departmentId: departmentId,
      teamId: teamId,
      search: search,
      statuses: statuses
  }})
}

export const useUser = (userId: string) => {
  return useQuery<{user: User}>(`
    query user($id: UUID!) {
      user(id: $id) {
        id
        fullName
        photoUrl
        email
        phoneNumber
        startDate
        probationEndDate
        endDate
        birthDate
        role
        title
        status
        location {
          id
          name
        }
        employmentType {
          id
          name
        }
        department {
          id
          name
        }
        team {
          id
          name
        }
        manager {
          id
          name
        }
        workSchedule {
          id
          name
          type
        }
        timeOffBalances {
          timeOffPolicy {
            id
            name
          }
        }
        holidaysCalendars {
          id
          name
        }
      }
    }
`, {variables: {id: userId}})
}

export enum AccessType {
  READ = "READ",
  WRITE = "WRITE"
}

export interface Access {
  resourceId: string
  accessType: AccessType
}

export interface UserAccess {
  timeOffBalances: Array<Access>
  overtime?: Access
}

export const useUserAccess = (userId: string) => {
  return useQuery<{userAccess: UserAccess}>(`
    query userAccess($userId: UUID!) {
      userAccess(userId: $userId) {
        timeOffBalances {
          resourceId
          accessType
        }
        overtime {
          resourceId
          accessType
        }
      }
    }
`, {variables: {userId: userId}})
}

export interface Department {
  id: string,
  name: string
}

export interface Departments {
  departments: [Department]
}

export const useDepartments = () => {
  return useQuery<Departments>(`
    query departments {
      departments {
        id,
        name
      }
    }
  `)
}

export const useAddDepartment = () => {
  return useMutation<{}>(`
  mutation addDepartment(
    $name: String!
  ) {
    addDepartment(name: $name)
  }
`)
}

export const useUpdateDepartment = () => {
  return useMutation<{}>(`
  mutation updateDepartment($id: UUID!, $name: String!) {
    updateDepartment(id: $id, name: $name)
  }
`)
}

export const useDeleteDepartment = () => {
  return useMutation<{}>(`
    mutation deleteDepartment($id: UUID!) {
      deleteDepartment(id: $id)
    }
  `)
}

export interface Team {
  id: string,
  name: string
}

export interface Teams {
  teams: [Team]
}

export const useTeams = () => {
  return useQuery<Teams>(`
    query teams {
      teams {
        id,
        name
      }
    }
  `)
}

export const useAddTeam = () => {
  return useMutation<{}>(`
  mutation addTeam(
    $name: String!
  ) {
    addTeam(name: $name)
  }
`)
}

export const useUpdateTeam = () => {
  return useMutation<{}>(`
  mutation updateTeam($id: UUID!, $name: String!) {
    updateTeam(id: $id, name: $name)
  }
`)
}

export const useDeleteTeam = () => {
  return useMutation<{}>(`
  mutation deleteTeam($id: UUID!) {
    deleteTeam(id: $id)
  }
`)
}

export interface Location {
  id: string,
  name: string
}

export interface Locations {
  locations: [Location]
}

export const useLocations = () => {
  return useQuery<Locations>(`
    query locations {
      locations {
        id,
        name
      }
    }
  `)
}

export const useAddLocation = () => {
  return useMutation<{}>(`
  mutation addLocation(
    $name: String!
  ) {
    addLocation(name: $name)
  }
`)
}

export const useUpdateLocation = () => {
  return useMutation<{}>(`
  mutation updateLocation($id: UUID!, $name: String!) {
    updateLocation(id: $id, name: $name)
  }
`)
}

export const useDeleteLocation = () => {
  return useMutation<{}>(`
  mutation deleteLocation($id: UUID!) {
    deleteLocation(id: $id)
  }
`)
}

export interface EmploymentType {
  id: string,
  name: string
}

export interface EmploymentTypes {
  employmentTypes: [EmploymentType]
}

export const useEmploymentTypes = () => {
  return useQuery<EmploymentTypes>(`
    query employmentTypes {
      employmentTypes {
        id,
        name
      }
    }
  `)
}

export const useAddEmploymentType = () => {
  return useMutation<{}>(`
  mutation addEmploymentType(
    $name: String!
  ) {
    addEmploymentType(name: $name)
  }
`)
}

export const useUpdateEmploymentType = () => {
  return useMutation<{}>(`
  mutation updateEmploymentType($id: UUID!, $name: String!) {
    updateEmploymentType(id: $id, name: $name)
  }
`)
}

export const useDeleteEmploymentType = () => {
  return useMutation<{}>(`
  mutation deleteEmploymentType($id: UUID!) {
    deleteEmploymentType(id: $id)
  }
`)
}

export const useAddUser = () => {
  return useMutation<{addUser: User}>(`
    mutation addUser(
      $fullName: String!,
      $email: String!,
      $phoneNumber: String,
      $locationId: UUID,
      $role: UserRole!,
      $title: String,
      $managerId: UUID,
      $departmentId: UUID,
      $employmentTypeId: UUID,
      $teamId: UUID,
      $timeOffPolicyIds: [UUID!],
      $startDate: LocalDate!,
      $birthDate: LocalDate,
      $holidaysCalendarsIds: [UUID!],
      $workScheduleId: UUID!,
      $probationEndDate: LocalDate,
      $probationEndDateNotificationUserIds: [UUID!],
    ) {
      addUser(input: {
        fullName: $fullName,
        title: $title,
        email: $email,
        phoneNumber: $phoneNumber,
        locationId: $locationId,
        role: $role,
        managerId: $managerId,
        departmentId: $departmentId,
        employmentTypeId: $employmentTypeId,
        teamId: $teamId,
        timeOffPolicyIds: $timeOffPolicyIds,
        startDate: $startDate,
        birthDate: $birthDate,
        holidaysCalendarsIds: $holidaysCalendarsIds,
        workScheduleId: $workScheduleId,
        probationEndDate: $probationEndDate
        probationEndDateNotificationUserIds: $probationEndDateNotificationUserIds
      }) {
        id
        fullName
        email
        phoneNumber
        role
        title
        status
        startDate
        probationEndDate
        probationEndDateNotificationUsers {
          id
          name
        }
        birthDate
        location {
          id
          name
        }
        department {
          id
          name
        }
        team {
          id
          name
        }
        manager {
          id
          name
        }
        employmentType {
          id
          name
        }
      }
    }
`)
}

export const useUpdateUser = () => {
  return useMutation<{updateUser: User}>(`
    mutation updateUser(
      $id: UUID!, 
      $fullName: String!,
      $phoneNumber: String,
      $locationId: UUID,
      $role: UserRole!,
      $title: String,
      $managerId: UUID,
      $departmentId: UUID,
      $employmentTypeId: UUID,
      $teamId: UUID,
      $startDate: LocalDate!,
      $birthDate: LocalDate,
      $workScheduleId: UUID!,
      $holidaysCalendarsIds: [UUID!]!,
      $timeOffPolicyIds: [UUID!]!,
      $probationEndDate: LocalDate,
      $probationEndDateNotificationUserIds: [UUID!],
    ) {
      updateUser(input: {
        id: $id,
        fullName: $fullName,
        phoneNumber: $phoneNumber,
        title: $title,
        locationId: $locationId,
        role: $role,
        managerId: $managerId,
        departmentId: $departmentId,
        employmentTypeId: $employmentTypeId,
        teamId: $teamId,
        startDate: $startDate,
        birthDate: $birthDate,
        workScheduleId: $workScheduleId,
        holidaysCalendarsIds: $holidaysCalendarsIds,
        timeOffPolicyIds: $timeOffPolicyIds,
        probationEndDate: $probationEndDate,
        probationEndDateNotificationUserIds: $probationEndDateNotificationUserIds
      }){
        id
        fullName
        phoneNumber
        email
        startDate
        probationEndDate
        probationEndDateNotificationUsers {
          id
          name
        }
        birthDate
        role
        title
        status
        location {
          id
          name
        }
        department {
          id
          name
        }
        team {
          id
          name
        }
        manager {
          id
          name
        }
      }
    }
`)
}

export const useDeactivateUser = () => {
  return useMutation<{}>(`
    mutation deactivateUser($userId: UUID!, $date: LocalDate!) {
      deactivateUser(userId: $userId, date: $date)
    }
  `)
}

export const useDeleteUser = () => {
  return useMutation<{}>(`
    mutation deleteUser($userId: UUID!) {
      deleteUser(userId: $userId)
    }
  `)
}

export const useMe = () => {
  return useQuery<AuthResponse>(
    `
      query {
        me {
          loggedIn
          user {
            id
            email
            fullName
            photoUrl
            role
            company {
              id
              createdAt
            }
            manager {
              id
            }
          }
        }
      }
    `
  )
}

export const useLogin = () => {
  return useMutation<{ login: Me }>(`
    mutation login($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        loggedIn
        user {
          role
          company {
            id
          }
        }
      }
    }
  `)
}

export const useRegisterUser = () => {
  return useMutation(`
    mutation registerUser($email: String!, $password: String!) {
      registerUser(email: $email, password: $password)
    }
  `)
}

export const useRequestPasswordReset = () => {
  return useMutation<{requestPasswordReset: Boolean}>(`
    mutation requestPasswordReset($email: String!) {
      requestPasswordReset(email: $email)
    }
  `)
}

export const useResetPassword = () => {
  return useMutation<{resetPassword: Boolean}>(`
    mutation resetPassword($token: UUID!, $password: String!) {
      resetPassword(token: $token, password: $password)
    }
  `)
}

export const useVerifyUserRegistration = () => {
  return useMutation<{verifyUserRegistration: Boolean}>(`
    mutation verifyUserRegistration($token: String!) {
      verifyUserRegistration(token: $token)
    }
  `)
}

export interface OrgChartItem {
  id: string
  managerId?: string
  fullName: string
  title?: string
  photoUrl: string
}

export const useOrgChart = () => {
  return useQuery<{ orgChart: [OrgChartItem] }>(`
    query orgChart {
      orgChart {
        id
        managerId
        fullName
        title
        photoUrl
      }
    }
  `)
}

export const useAddTimeOffPolicy = () => {
  return useMutation<{addTimeOffPolicy: TimeOffPolicy}>(`
    mutation addTimeOffPolicy(
      $name: String!,
      $paid: Boolean!,
      $unlimited: Boolean!,
      $totalDaysOff: Int,
      $accrual: TimeOffPolicyAccrual,
      $probationPeriodDays: Int,
      $carryoverMaxDays: Int,
      $carryoverExpirationMonths: Int
    ) {
      addTimeOffPolicy(input: {
        name: $name,
        paid: $paid,
        unlimited: $unlimited,
        totalDaysOff: $totalDaysOff,
        accrual: $accrual,
        probationPeriodDays: $probationPeriodDays,
        carryoverMaxDays: $carryoverMaxDays,
        carryoverExpirationMonths: $carryoverExpirationMonths
      }) {
        id,
        name,
        paid,
        unlimited,
        totalDaysOff,
        accrual,
        probationPeriodDays,
        carryoverMaxDays,
        carryoverExpirationMonths
      }
    }
`)
}

export const useUpdateTimeOffPolicy = () => {
  return useMutation<{updateTimeOffPolicy: TimeOffPolicy}>(`
    mutation updateTimeOffPolicy(
      $id: UUID!,
      $name: String!,
      $paid: Boolean!,
      $unlimited: Boolean!,
      $totalDaysOff: Int,
      $accrual: TimeOffPolicyAccrual,
      $probationPeriodDays: Int,
      $carryoverMaxDays: Int,
      $carryoverExpirationMonths: Int
    ) {
      updateTimeOffPolicy(input: {
        id: $id,
        name: $name,
        paid: $paid,
        unlimited: $unlimited,
        totalDaysOff: $totalDaysOff,
        accrual: $accrual,
        probationPeriodDays: $probationPeriodDays,
        carryoverMaxDays: $carryoverMaxDays,
        carryoverExpirationMonths: $carryoverExpirationMonths
      }) {
        id,
        name,
        paid,
        unlimited,
        totalDaysOff,
        accrual,
        probationPeriodDays,
        carryoverMaxDays,
        carryoverExpirationMonths
      }
    }
`)
}

export interface TimeOffPolicies {
  timeOffPolicies: [TimeOffPolicy]
}

export const useTimeOffPolicies = () => {
  return useQuery<TimeOffPolicies>(`
    query timeOffPolicies {
      timeOffPolicies {
        id,
        name,
        paid,
        unlimited,
        totalDaysOff,
        accrual,
        probationPeriodDays,
        carryoverMaxDays,
        carryoverExpirationMonths
      }
    }
  `)
}

export const useTimeOffPolicy = (id: string) => {
  return useQuery<{timeOffPolicy: TimeOffPolicy}>(`
    query timeOffPolicy($id: UUID!) {
      timeOffPolicy(id: $id) {
        id,
        name,
        paid,
        unlimited,
        totalDaysOff,
        accrual,
        probationPeriodDays,
        carryoverMaxDays,
        carryoverExpirationMonths
      }
    }
  `,{
    variables: {
      id: id
  }})
}

export const useUpdateTimeOffBalance = () => {
  return useMutation<{}>(`
    mutation updateTimeOffBalance($id: UUID!, $minutes: Int!, $comment: String) {
      updateTimeOffBalance(id: $id, minutes: $minutes, comment: $comment)
    }
  `)
}

export const useUpdateTimeOffBalanceApprover = () => {
  return useMutation<{}>(`
    mutation updateTimeOffBalance($id: UUID!, $canManagerApprove: Boolean!, $canAdminApprove: Boolean!, $approvers: [UUID!]!) {
      updateTimeOffBalanceApproval(id: $id, canManagerApprove: $canManagerApprove, canAdminApprove: $canAdminApprove, approvers: $approvers)
    }
  `)
}

export const useUpdateTimeOffBalanceActivationDate = () => {
  return useMutation<{}>(`
    mutation updateTimeOffBalanceActivationDate($id: UUID!, $date: LocalDate!, $comment: String) {
      updateTimeOffBalanceActivationDate(id: $id, date: $date, comment: $comment)
    }
  `)
}

export interface TimeOffPolicyUser {
  id: string
  fullName: string
  title: string
  photoUrl: string
}

export const useTimeOffPolicyUsers = (id: string) => {
  return useQuery<{timeOffPolicy: { id: string, name: string, users: [TimeOffPolicyUser] }}>(`
    query timeOffPolicy($id: UUID!) {
      timeOffPolicy(id: $id) {
        id
        name
        users {
          id
          fullName
          title
          photoUrl
        }
      }
    }
  `,{
    variables: {
      id: id
    }})
}

export const useDeleteTimeOffPolicy = () => {
  return useMutation<{}>(`
  mutation deleteTimeOffPolicy($id: UUID!) {
    deleteTimeOffPolicy(id: $id)
  }
`)
}

export const useUserTimeOffBalances = (userId: string) => {
  return useQuery<{user: {timeOffBalances: [TimeOffBalance]}}>(`
    query user($id: UUID!) {
      user(id: $id) {
        timeOffBalances {
          id
          availableMinutes
          usedHours
          canManagerApprove
          canAdminApprove
          approvers
          timeOffPolicy {
            id
            name
            unlimited
            totalDaysOff
            accrual
            probationPeriodDays
            carryoverMaxDays
            carryoverExpirationMonths
          }
          activationDate
        }
      }
    }
  `,{
    variables: {
      id: userId
    }})
}

export const useAddTimeOffRequest = () => {
  return useMutation<{}>(`
    mutation addTimeOffRequest(
      $balanceId: UUID!,
      $start: String!,
      $end: String!,
      $description: String,
      $firstDayDuration: TimeOffDuration,
      $lastDayDuration: TimeOffDuration
    ) {
      addTimeOffRequest(input: {
        balanceId: $balanceId,
        start: $start,
        end: $end,
        description: $description,
        firstDayDuration: $firstDayDuration,
        lastDayDuration: $lastDayDuration
      })
    }
  `)
}

export const useAddUserTimeOffPolicy = () => {
  return useMutation<{}>(`
    mutation addUserTimeOffPolicy(
      $timeOffPolicyId: UUID!,
      $userIds: [UUID!]!
    ) {
      addUserTimeOffPolicy(
        timeOffPolicyId: $timeOffPolicyId,
        userIds: $userIds
      )
    }
  `)
}

export const useDeleteUserTimeOffPolicy = () => {
  return useMutation<{}>(`
    mutation deleteUserTimeOffPolicy(
      $timeOffPolicyId: UUID!,
      $userId: UUID!
    ) {
      deleteUserTimeOffPolicy(
        timeOffPolicyId: $timeOffPolicyId,
        userId: $userId
      )
    }
  `)
}

export const useUserTimeOffRequests = (userId: string, year: number) => {
  return useQuery<{user: {timeOffRequests: [TimeOffRequest]}}>(`
    query user($id: UUID!, $year: Int!) {
      user(id: $id) {
        timeOffRequests(year: $year) {
          id
          start
          end
          description
          status
          user {
            id
            fullName
          }
          amountHours
          createdAt
          timeOffPolicy {
            id
            name
          }
          approverComment
        }
      }
    }
  `,{
    variables: {
      id: userId,
      year: year
    }})
}

export const useUserTimeOffHistory = (userId: string, year: number) => {
  return useQuery<{user: {timeOffHistory: [TimeOffHistory]}}>(`
    query user($id: UUID!, $year: Int!) {
      user(id: $id) {
        timeOffHistory(year: $year) {
          id
          type
          createdAt
          amountMinutes
          description
        }
      }
    }
  `,{
    variables: {
      id: userId,
      year: year
    }})
}

export const usePendingTimeOffRequests = () => {
  return useQuery<{pendingTimeOffRequests: [TimeOffRequest]}>(`
    query pendingTimeOffRequests {
      pendingTimeOffRequests {
        id
        start
        end
        description
        status
        user {
          id
          fullName
          title
          photoUrl
        }
        amountHours
        createdAt
        timeOffPolicy {
          id
          name
        }
      }
    }
  `)
}

export const useUpdateTimeOffRequestStatus = () => {
  return useMutation<{}>(`
    mutation updateTimeOffRequestStatus(
      $timeOffRequestId: UUID!,
      $status: TimeOffRequestStatus!,
      $comment: String
    ) {
      updateTimeOffRequestStatus(input: {
        timeOffRequestId: $timeOffRequestId,
        status: $status,
        comment: $comment
      })
    }
  `)
}

export const useUpcomingTimeOffRequests = () => {
  return useQuery<{upcomingTimeOffRequests: [TimeOffRequest]}>(`
    query upcomingTimeOffRequests {
      upcomingTimeOffRequests {
        id
        start
        end
        description
        status
        user {
          id
          fullName
          title
          photoUrl
        }
        amountHours
        createdAt
        timeOffPolicy {
          id
          name
        }
      }
    }
  `)
}

export const useCurrentTimeOffRequests = () => {
  return useQuery<{currentTimeOffRequests: [TimeOffRequest]}>(`
    query currentTimeOffRequests {
      currentTimeOffRequests {
        id
        start
        end
        description
        status
        user {
          id
          fullName
          title
          photoUrl
        }
        amountHours
        createdAt
        timeOffPolicy {
          id
          name
        }
      }
    }
  `)
}

export interface PayrollReport {
  timeOffPolicies: Array<TimeOffPolicy>
  users: Array<PayrollReportUser>
}

export interface PayrollReportUser {
  user: User
  total: number
  paid: number
  timeOff: Array<PayrollReportUserTimeOff>
  overtimeHours: number
}

export interface PayrollReportUserTimeOff {
  policyId: string
  hours: number
}

export const usePayrollReport = (year: number, month: number, locationId?: string, departmentId?: string, teamId?: string) => {
  if (locationId === '') {
    locationId = undefined
  }

  if (departmentId === '') {
    departmentId = undefined
  }

  if (teamId === '') {
    teamId = undefined
  }

  return useQuery<{payrollReport: PayrollReport}>(`
    query payrollReport($period: YearMonth, $locationId: UUID, $departmentId: UUID, $teamId: UUID) {
      payrollReport(period: $period, locationId: $locationId, departmentId: $departmentId, teamId: $teamId) {
        timeOffPolicies {
          id
          name
          paid
        }
        users {
          total
          paid
          timeOff {
            policyId
            hours
          }
          user {
            id
            fullName
            photoUrl
            title
          }
          overtimeHours
        }
      }
    }
`, {
    variables: {
      period: {year: year, month: month},
      locationId: locationId,
      departmentId: departmentId,
      teamId: teamId
    }
  })
}

export enum HolidaysCalendarType {
  SYSTEM = "SYSTEM",
  CUSTOM = "CUSTOM"
}

export interface HolidaysCalendar {
  id: string
  name: string
  type: HolidaysCalendarType
  events: Array<HolidaysCalendarEvent>
}

export interface HolidaysCalendarEvent {
  id: string
  year: number
  name: string
  from: string
  to: string
  enabled: boolean
}

export const useSystemHolidaysCalendars = (year: number) => {
  return useQuery<{systemHolidaysCalendars: Array<HolidaysCalendar>}>(`
    query systemHolidaysCalendars($year: Int!) {
      systemHolidaysCalendars(year: $year) {
        id
        name
        events {
          id
          year
          name
          from
          to
        }
      }
    }
  `, {
    variables: {
      year: year
    }
  })
}

export const useAddHolidaysCalendar = () => {
  return useMutation<{addHolidaysCalendar: string}>(`
    mutation addHolidaysCalendar(
      $name: String!,
      $systemHolidaysCalendarId: UUID,
    ) {
      addHolidaysCalendar(input: {
        name: $name,
        systemHolidaysCalendarId: $systemHolidaysCalendarId
      })
    }
  `)
}

export const useHolidaysCalendars = () => {
  return useQuery<{holidaysCalendars: Array<HolidaysCalendar>}>(`
    query holidaysCalendars {
      holidaysCalendars {
        id
        name
      }
    }
  `)
}

export const useUpdateHolidaysCalendar = () => {
  return useMutation<{}>(`
  mutation updateHolidaysCalendar($id: UUID!, $name: String!) {
    updateHolidaysCalendar(id: $id, name: $name)
  }
`)
}

export const useDeleteHolidaysCalendar = () => {
  return useMutation<{}>(`
  mutation deleteHolidaysCalendar($id: UUID!) {
    deleteHolidaysCalendar(id: $id)
  }
`)
}

export interface HolidaysCalendarUser {
  id: string
  fullName: string
  title: string
  photoUrl: string
}

export const useHolidaysCalendarUsers = (id: string) => {
  return useQuery<{holidaysCalendar: { id: string, name: string, users: [HolidaysCalendarUser] }}>(`
    query holidaysCalendar($id: UUID!) {
      holidaysCalendar(id: $id) {
        id
        name
        users {
          id
          fullName
          title
          photoUrl
        }
      }
    }
  `,{
    variables: {
      id: id
    }})
}

export const useAddHolidaysCalendarUser = () => {
  return useMutation<{}>(`
    mutation addHolidaysCalendarUser(
      $id: UUID!,
      $userIds: [UUID!]!
    ) {
      addHolidaysCalendarUser(
        id: $id,
        userIds: $userIds
      )
    }
  `)
}

export const useDeleteHolidaysCalendarUser = () => {
  return useMutation<{}>(`
    mutation deleteHolidaysCalendarUser(
      $id: UUID!,
      $userId: UUID!
    ) {
      deleteHolidaysCalendarUser(
        id: $id,
        userId: $userId
      )
    }
  `)
}

export interface HolidaysCalendarEvent {
  id: string
  year: number
  name: string
  from: string
  to: string
}

export const useHolidaysCalendarEvents = (id: string, year: number) => {
  return useQuery<{holidaysCalendar: { id: string, name: string, events: [HolidaysCalendarEvent] }}>(`
    query holidaysCalendar($id: UUID!, $year: Int!) {
      holidaysCalendar(id: $id) {
        id
        name
        events(year: $year) {
          id
          year
          name
          from
          to
          enabled
        }
      }
    }
  `,{
    variables: {
      id: id,
      year: year
    }})
}

export const useEnableHolidaysCalendarEvent = () => {
  return useMutation<{}>(`
    mutation enableHolidaysCalendarEvent(
      $calendarId: UUID!,
      $eventId: UUID!
    ) {
      enableHolidaysCalendarEvent(
        calendarId: $calendarId,
        eventId: $eventId
      )
    }
  `)
}

export const useDisableHolidaysCalendarEvent = () => {
  return useMutation<{}>(`
    mutation disableHolidaysCalendarEvent(
      $calendarId: UUID!,
      $eventId: UUID!
    ) {
      disableHolidaysCalendarEvent(
        calendarId: $calendarId,
        eventId: $eventId
      )
    }
  `)
}

export const useAddHolidaysCalendarEvent = () => {
  return useMutation<{}>(`
    mutation addHolidaysCalendarEvent(
      $calendarId: UUID!,
      $name: String!,
      $from: LocalDate!,
      $to: LocalDate!
    ) {
      addHolidaysCalendarEvent(event: {
        calendarId: $calendarId,
        name: $name,
        from: $from,
        to: $to    
      })
    }
  `)
}


export const useDeleteHolidaysCalendarEvent = () => {
  return useMutation<{}>(`
    mutation deleteHolidaysCalendarEvent($eventId: UUID!) {
      deleteHolidaysCalendarEvent(eventId: $eventId)
    }
  `)
}

export interface UserDocument {
  id: string
  userId: string
  name: string
  url: string
  type: string
}

export const useUserDocuments = (userId: string) => {
  return useQuery<{ userDocuments: [UserDocument] }>(`
    query userDocuments($userId: UUID!) {
      userDocuments(userId: $userId) {
        id
        userId
        name
        url
        type
      }
    }
  `,{
    variables: {
      userId: userId
    }})
}

export const useDeleteUserDocument = () => {
  return useMutation<{}>(`
    mutation deleteUserDocument($id: UUID!) {
      deleteUserDocument(id: $id)
    }
  `)
}

export const uploadUserPhoto = (userId: string, file: File) => {

  const formData = new FormData()
  formData.append('file', file)

  return axios.post(`/api/v1/user/${userId}/photo-upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}

export const uploadCompanyLogo = (file: File) => {

  const formData = new FormData()
  formData.append('file', file)

  return axios.post(`/api/v1/company/logo-upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}

export const useUpdateCompanyName = () => {
  return useMutation<{}>(`
    mutation updateCompanyName($name: String!) {
      updateCompanyName(name: $name)
    }
  `)
}

export enum WorkScheduleType {
  FIXED = "FIXED",
  FLEXIBLE = "FLEXIBLE"
}

export interface WorkScheduleUser {
  id: string
  fullName: string
  title: string
  photoUrl: string
}

export interface WorkSchedule {
  id: string
  name: string
  type: WorkScheduleType
  users: Array<WorkScheduleUser>
  weekDays: Array<WorkScheduleWeekDay>
}

export interface WorkScheduleWeekDay {
  id: string
  isoWeekDay: number
  amountHours: number
}

export interface AddWorkScheduleWeekDay {
  isoWeekDay: number
  amountHours: number
}

export interface UpdateWorkScheduleWeekDay {
  id: string
  amountHours: number
}

export const useWorkSchedules = () => {
  return useQuery<{workSchedules: Array<WorkSchedule>}>(`
    query workSchedules {
      workSchedules {
        id
        name
        type
      }
    }
  `)
}

export const useAddWorkSchedule = () => {
  return useMutation<{}>(`
  mutation addWorkSchedule(
    $name: String!,
    $type: WorkScheduleType!,
    $weekDays: [AddWorkScheduleWeekDay!]
  ) {
    addWorkSchedule(name: $name, type: $type, weekDays: $weekDays)
  }
`)
}

export const useUpdateWorkSchedule = () => {
  return useMutation<{}>(`
  mutation updateWorkSchedule(
    $id: UUID!,
    $name: String!,
    $weekDays: [UpdateWorkScheduleWeekDay!]
  ) {
    updateWorkSchedule(id: $id, name: $name, weekDays: $weekDays)
  }
`)
}

export const useDeleteWorkSchedule = () => {
  return useMutation<{}>(`
    mutation deleteWorkSchedule($id: UUID!) {
      deleteWorkSchedule(id: $id)
    }
  `)
}


export const useWorkSchedule = (id: string) => {
  return useQuery<{workSchedule: WorkSchedule}>(`
    query workSchedule($id: UUID!) {
      workSchedule(id: $id) {
        id
        type
        name
        users {
          id
          fullName
          title
          photoUrl
        }
        weekDays {
          id
          isoWeekDay
          amountHours
        }
      }
    }
  `,{
    variables: {
      id: id
    }})
}

export const useWorkScheduleUsers = (id: string) => {
  return useQuery<{ workSchedule: WorkSchedule }>(`
    query workSchedule($id: UUID!) {
      workSchedule(id: $id) {
        id
        type
        name
        users {
          id
          fullName
          title
          photoUrl
        }
      }
    }
  `,{
    variables: {
      id: id
    }})
}

export const useDeleteWorkScheduleUser = () => {
  return useMutation<{}>(`
    mutation deleteWorkScheduleUser(
      $id: UUID!,
      $userId: UUID!
    ) {
      deleteWorkScheduleUser(
        id: $id,
        userId: $userId
      )
    }
  `)
}

export const useAddWorkScheduleUser = () => {
  return useMutation<{}>(`
    mutation addWorkScheduleUser(
      $id: UUID!,
      $userIds: [UUID!]!
    ) {
      addWorkScheduleUser(
        id: $id,
        userIds: $userIds
      )
    }
  `)
}

export const useAddUserTimeSlot = () => {
  return useMutation<{}>(`
    mutation addUserTimeSlot(
      $userId: UUID!,
      $date: LocalDate!,
      $fromTime: LocalTime!,
      $toTime: LocalTime!
    ) {
      addUserTimeSlot(
        userId: $userId,
        date: $date,
        fromTime: $fromTime,
        toTime: $toTime
      )
    }
  `)
}

export const useUpdateUserTimeSlot = () => {
  return useMutation<{}>(`
    mutation updateUserTimeSlot(
      $id: UUID!,
      $fromTime: LocalTime!,
      $toTime: LocalTime!
    ) {
      updateUserTimeSlot(
        id: $id,
        fromTime: $fromTime,
        toTime: $toTime
      )
    }
  `)
}

export const useDeleteUserTimeSlot = () => {
  return useMutation<{}>(`
    mutation deleteUserTimeSlot(
      $id: UUID!
    ) {
      deleteUserTimeSlot(
        id: $id
      )
    }
  `)
}

export interface CalendarWorkSchedule {
  users: Array<CalendarWorkScheduleUser>
}

export interface CalendarWorkScheduleUser {
  user: User
  days: Array<CalendarWorkScheduleUserTime>
}

export enum CalendarWorkScheduleType {
  FIXED = "FIXED",
  FLEXIBLE = "FLEXIBLE",
  HOLIDAY = "HOLIDAY",
  TIME_OFF = "TIME_OFF"
}

export interface CalendarWorkScheduleUserTime {
  id: string
  date: string
  fromTime: string
  toTime: string
  type: CalendarWorkScheduleType
  description: string
}

export const useCalendarWorkSchedule = (
  fromDate: string,
  toDate: string,
  workScheduleId?: string,
  locationId?: string,
  departmentId?: string,
  teamId?: string) => {

  if (workScheduleId === '') {
    workScheduleId = undefined
  }

  if (locationId === '') {
    locationId = undefined
  }

  if (departmentId === '') {
    departmentId = undefined
  }

  if (teamId === '') {
    teamId = undefined
  }

  return useQuery<{calendarWorkSchedule: CalendarWorkSchedule}>(`
    query calendarWorkSchedule($fromDate: LocalDate, $toDate: LocalDate, $workScheduleId: UUID, $locationId: UUID, $departmentId: UUID, $teamId: UUID) {
      calendarWorkSchedule(fromDate: $fromDate, toDate: $toDate, workScheduleId: $workScheduleId, locationId: $locationId, departmentId: $departmentId, teamId: $teamId) {
        users {
          user {
            id
            fullName
            photoUrl
            title
          }
          days {
            id
            date
            fromTime
            toTime
            type
            description
          }
        }
      }
    }
`, {
    variables: {
      fromDate: fromDate,
      toDate: toDate,
      workScheduleId: workScheduleId,
      locationId: locationId,
      departmentId: departmentId,
      teamId: teamId
    }
  })
}

export const addUserDocument = (userId: string, file: File) => {

  const formData = new FormData()
  formData.append('file', file)

  return axios.post(`/api/v1/user/${userId}/document`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
}

export const getUserDocumentLink = (userId: string, id: string) => {
  return axios.get<{url: string}>(`/api/v1/user/${userId}/document/${id}/download-url`)
    .then(x => x.data.url)
}

export enum AnnouncementType {
  NEW_HIRE = "NEW_HIRE",
  ANNIVERSARY = "ANNIVERSARY",
  BIRTHDAY = "BIRTHDAY"
}

export interface AnnouncementUser {
  id: string
  fullName: string
  title: string
  photoUrl: string
  date: string
}

export interface Announcement {
  type: AnnouncementType
  users: Array<AnnouncementUser>
}

export const useAnnouncements = () => {
  return useQuery<{announcements: [Announcement]}>(`
    query announcements {
      announcements {
        type
        users {
          id
          fullName
          title
          photoUrl
          date
        }
      }
    }
  `)
}

export enum OvertimeRequestStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  CANCELLED = "CANCELLED"
}

export interface OvertimeRequestUser {
  id: string
  fullName: string
  title: string
  photoUrl: string
}

export interface OvertimeRequest {
  id: string
  date: string
  fromTime: string
  toTime: string
  description: string
  status: OvertimeRequestStatus
  user: OvertimeRequestUser
  approverComment: string
  createdAt: string
}

export const useUserOvertimeRequests = (userId: string, year: number) => {
  return useQuery<{user: {overtimeRequests: [OvertimeRequest]}}>(`
    query user($id: UUID!, $year: Int!) {
      user(id: $id) {
        overtimeRequests(year: $year) {
          id
          date
          fromTime
          toTime
          description
          status
          user {
            id
            fullName
          }
          createdAt
          approverComment
        }
      }
    }
  `,{
    variables: {
      id: userId,
      year: year
    }})
}

export const usePendingOvertimeRequests = () => {
  return useQuery<{pendingOvertimeRequests: [OvertimeRequest]}>(`
    query pendingOvertimeRequests {
      pendingOvertimeRequests {
        id
        date
        fromTime
        toTime
        description
        status
        user {
          id
          fullName
          title
          photoUrl
        }
        createdAt
      }
    }
  `)
}

export const useUpdateOvertimeRequestStatus = () => {
  return useMutation<{}>(`
    mutation updateOvertimeRequestStatus(
      $overtimeRequestId: UUID!,
      $status: OvertimeRequestStatus!,
      $comment: String
    ) {
      updateOvertimeRequestStatus(
        overtimeRequestId: $overtimeRequestId,
        status: $status,
        comment: $comment
      )
    }
  `)
}

export const useRequestedOvertimeRequests = () => {
  return useQuery<{requestedOvertimeRequests: [OvertimeRequest]}>(`
    query requestedOvertimeRequests {
      requestedOvertimeRequests {
        id
        date
        fromTime
        toTime
        description
        status
        user {
          id
          fullName
          title
          photoUrl
        }
        createdAt
      }
    }
  `)
}

export const useAddOvertimeRequest = () => {
  return useMutation<{}>(`
    mutation addOvertimeRequest(
      $date: LocalDate!,
      $fromTime: LocalTime!,
      $toTime: LocalTime!
      $description: String!   
    ) {
      addOvertimeRequest(input: {
        date: $date,
        fromTime: $fromTime,
        toTime: $toTime,
        description: $description    
      })
    }
  `)
}

interface TimeOffReportUser {
  user: TimeOffReportItemUser
  timeOff: [TimeOffReportUserTimeOff]
}

interface TimeOffReportUserTimeOff {
  policyId: string
  availableDays: number
  usedDays: number
}

interface TimeOffReportItemUser {
  id: string
  fullName: string
  title: string
  photoUrl: string
}

interface TimeOffReport {
  users: [TimeOffReportUser]
}

export const useTimeOffReport = () => {
  return useQuery<{timeOffReport: TimeOffReport}>(`
    query timeOffReport {
      timeOffReport {
        users {
          user {
            id
            fullName
            title
            photoUrl
          }
          timeOff {
            policyId
            availableDays
            usedDays
          }   
        }
      }
    }
  `)
}

export const useSlackIntegrationConnectUrl = () => {
  return useQuery<{slackIntegrationConnectUrl: string}>(`
    query slackIntegrationConnectUrl {
      slackIntegrationConnectUrl
    }
  `)
}

export const useAddSlackIntegration = () => {
  return useMutation<{addSlackIntegration: boolean}>(`
    mutation addSlackIntegration($code: String!) {
      addSlackIntegration(code: $code)
    }
  `)
}


export enum SlackIntegrationNotificationType {
  TIME_OFF = "TIME_OFF",
  TIME_OFF_REQUEST = "TIME_OFF_REQUEST",
  BIRTHDAY = "BIRTHDAY",
  ANNIVERSARY = "ANNIVERSARY",
  WELCOME = "WELCOME"
}

interface SlackIntegration {
  channelName: string
  channelId: string
  notifications: Array<SlackIntegrationNotificationType>
}

export const useSlackIntegration = () => {
  return useQuery<{slackIntegration: SlackIntegration}>(`
    query slackIntegration {
      slackIntegration {
        channelName
        channelId
        notifications
      }
    }
  `)
}

export const useDeleteSlackIntegration = () => {
  return useMutation<{}>(`
    mutation deleteSlackIntegration {
      deleteSlackIntegration
    }
  `)
}

export const useEnableSlackIntegrationNotification = () => {
  return useMutation<{}>(`
    mutation enableSlackIntegrationNotification(
      $notification: SlackIntegrationNotificationType!
    ) {
      enableSlackIntegrationNotification(
        notification: $notification
      )
    }
  `)
}

export const useDisableSlackIntegrationNotification = () => {
  return useMutation<{}>(`
    mutation disableSlackIntegrationNotification(
      $notification: SlackIntegrationNotificationType!
    ) {
      disableSlackIntegrationNotification(
        notification: $notification
      )
    }
  `)
}

export const useTestSlackIntegration = () => {
  return useMutation<{}>(`
    mutation testSlackIntegration {
      testSlackIntegration
    }
  `)
}

//Profile photos must be GIFs, JPGs or PNGs.
// NOT image/webp
export const SupportedImageTypes = "image/png, image/gif, image/jpeg, image/jpg"