import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import * as React from "react";
import {MouseEvent, useState} from "react";


export const CreatableSelect = (props: {
  label: string,
  value?: string,
  values: Array<{id: string, name: string}>
  onChange: (id: string) => void
  onAdd: (name: string, onOk: () => void) => void
  addError: string
}) => {

  const [openDepartmentDialog, setOpenDepartmentDialog] = useState(false)
  const [newValue, setNewValue] = useState("")

  const onAddNewDepartmentDialog = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setOpenDepartmentDialog(true)
  }

  const onCancelAddNewDepartmentDialog = () => {
    setOpenDepartmentDialog(false)
    setNewValue("")
  }

  const onAdd = () => {
    props.onAdd(newValue, onCancelAddNewDepartmentDialog)
  }

  return (
    <>
      <FormControl variant="outlined" fullWidth margin="normal" size="small">
        <InputLabel>{props.label}</InputLabel>
        <Select
          value={props.value}
          label={props.label}
          onChange={e => props.onChange(e.target.value)}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          {props.values.map(x =>
            <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
          )}
          <MenuItem value="add" sx={{borderTop: "1px solid #eeeeee", padding: 0}}>
            <Link
              sx={{
                width: "100%",
                height: "100%",
                padding: "10px 15px",
                textDecoration: "none"
              }}
              onClick={onAddNewDepartmentDialog}
            >+ add new</Link>
          </MenuItem>
        </Select>
      </FormControl>
      <Dialog onClose={onCancelAddNewDepartmentDialog} open={openDepartmentDialog}>
        <DialogTitle>Add {props.label}</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          <TextField
            autoFocus
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            margin="normal"
            error={props.addError !== ''}
            helperText={props.addError}
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={onCancelAddNewDepartmentDialog}>Cancel</Button>
          <Button variant="contained" onClick={onAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}