import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton, InputLabel, MenuItem, Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material"
import {
  HolidaysCalendarEvent,
  useAddHolidaysCalendarEvent,
  useDeleteHolidaysCalendarEvent,
  useDisableHolidaysCalendarEvent,
  useEnableHolidaysCalendarEvent,
  useHolidaysCalendarEvents
} from "../api";
import * as React from "react";
import {useState} from "react";
import {ApiError} from "../errors";
import {MobileDatePicker} from "@mui/x-date-pickers-pro";
import moment, {Moment} from "moment/moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {useAuth} from "../auth/AuthProvider";

export const HolidaysCalendarEventsList = (props: {holidaysCalendarId: string}) => {

  const auth = useAuth()
  const today = moment()

  const startYear = moment(auth.user?.company?.createdAt).year()
  const endYear = today.year()
  const yearsRange = Array.from(Array(endYear - startYear + 2).keys()).map(x => x + startYear)

  const [year, setYear] = useState<number>(today.year())

  const events = useHolidaysCalendarEvents(props.holidaysCalendarId, year)
  const [enableHolidaysCalendarEvent] = useEnableHolidaysCalendarEvent()
  const [disableHolidaysCalendarEvent] = useDisableHolidaysCalendarEvent()

  const [eventName, setEventName] = useState<string>()
  const [openAddEventDialog, setOpenAddEventDialog] = useState(false)

  const minDate = moment().subtract(100, 'years')
  const maxDate = moment().add(100, 'years')

  const [eventFromDate, setEventFromDate] = useState<Moment | null>(null)
  const [eventToDate, setEventToDate] = useState<Moment | null>(null)
  const [addHolidaysCalendarEvent] = useAddHolidaysCalendarEvent()

  const [selectedItem, setSelectedItem] = useState<HolidaysCalendarEvent | null>(null)
  const [deleteDialog, setDeleteDialog] = useState(false)

  const [deleteHolidaysCalendarEvent] = useDeleteHolidaysCalendarEvent()

  const onCancelAddUsersDialog = () => {
    setOpenAddEventDialog(false)
    setEventName("")
    setEventFromDate(null)
    setEventToDate(null)
  }

  const onChangeEnable = (eventId: string, enable: boolean) => {
    const params = {
      calendarId: props.holidaysCalendarId,
      eventId: eventId
    };

    (enable ? enableHolidaysCalendarEvent({variables: params}) : disableHolidaysCalendarEvent({variables: params}))
      .then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          //TODO
        }
      } else {
        events.refetch()
      }
    })
  }

  const onAddEvent = () => {
    addHolidaysCalendarEvent({
      variables: {
        calendarId: props.holidaysCalendarId,
        name: eventName,
        from: eventFromDate?.utc(true).format('YYYY-MM-DD'),
        to: eventToDate?.utc(true).format('YYYY-MM-DD')
      }
    }).then(response => {
      if (response.error) {
        /*const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNameError(e.message)
        }*/
      } else {
        onCancelAddUsersDialog()
        events.refetch()
      }
    })
  }

  const onOpenDeleteDialog = (item: HolidaysCalendarEvent) => {
    setSelectedItem(item)
    setDeleteDialog(true)
  }

  const onConfirmDelete = () => {
    if (!selectedItem) {
      return
    }

    deleteHolidaysCalendarEvent({
      variables: {
        eventId: selectedItem.id
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          //TODO
        }
      } else {
        events.refetch()
        setDeleteDialog(false)
      }
    })
  }

  return (
    <Box>

      <Box
        sx={{
          display: "flex",
          marginBottom: "10px"
        }}
      >
        <FormControl variant="outlined" margin="normal" size="small" sx={{margin: "0px"}}>
          <InputLabel>Year</InputLabel>
          <Select
            value={year}
            label={"Year"}
            SelectDisplayProps={{style: {padding: "5px 35px 5px 15px"}}}
            onChange={e => setYear(e.target.value as number)}
          >
            {yearsRange.map(x =>
              <MenuItem key={x} value={x}>{x}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Name</TableCell>
                <TableCell variant="head" sx={{width: "100px"}}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.data?.holidaysCalendar?.events?.map(x => {
                return (
                  <TableRow key={x.id}>
                    <TableCell sx={{padding: "10px"}}>
                      <Box key={x.id}>
                        <Switch size="small" checked={x.enabled} onChange={e => onChangeEnable(x.id, e.target.checked)}/><span style={{fontSize: "16px"}}>{x.name} ({x.from === x.to ? x.from : x.from + ' - ' + x.to})</span>
                      </Box>
                    </TableCell>
                    <TableCell sx={{padding: "10px"}}>
                      <IconButton onClick={() => onOpenDeleteDialog(x)}>
                        <DeleteOutlineIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: "15px"}}>
        <Button variant="outlined" onClick={_ => setOpenAddEventDialog(true)}>+ Add Event</Button>
        <Dialog open={openAddEventDialog}>
          <DialogTitle>Add event</DialogTitle>
          <DialogContent sx={{width: "400px"}}>
            <Box sx={{display: "flex", flexDirection: "column", gap: "15px"}}>
              <FormControl variant="outlined" fullWidth margin="normal" size="small">
                <TextField
                  size="small"
                  autoFocus
                  label="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={eventName}
                  onChange={e => setEventName(e.target.value)}
                />
              </FormControl>
              <Box sx={{display: "flex", alignItems: "center", gap: "5px"}}>
                <MobileDatePicker
                  showToolbar={false}
                  label="Start"
                  inputFormat="YYYY-MM-DD"
                  minDate={minDate}
                  maxDate={maxDate}
                  value={eventFromDate}
                  onChange={setEventFromDate}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
                <Box sx={{padding: "0px 10px"}}>to</Box>
                <MobileDatePicker
                  showToolbar={false}
                  label="End"
                  inputFormat="YYYY-MM-DD"
                  minDate={eventFromDate || minDate}
                  maxDate={maxDate}
                  value={eventToDate}
                  onChange={setEventToDate}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{padding: "10px 25px"}}>
            <Button onClick={onCancelAddUsersDialog}>Cancel</Button>
            <Button variant="contained" onClick={onAddEvent}>Add</Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Dialog open={deleteDialog}>
        <DialogTitle>Delete event</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          Are you sure you want to delete '{selectedItem?.name ?? ''}'?
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={_ => setDeleteDialog(false)}>No</Button>
          <Button variant="contained" color="error" onClick={onConfirmDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
