import {Avatar, Box, colors, Grid, Paper, Typography} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import {UserAddUpdate} from "./UserAddUpdate";
import {useNavigate} from "react-router-dom";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {SupportedImageTypes, uploadUserPhoto, User} from "../api";
import {headerStyle} from "../style-util";

export const AddUser = () => {
  const navigate = useNavigate()
  const inputFileRef = React.useRef<HTMLInputElement>(null)
  const [newPhotoPreview, setNewPhotoPreview] = useState<string | ArrayBuffer | null>()
  const [newPhotoFile, setNewPhotoFile] = useState<File>()

  const onUserAdded = (user: User) => {
    if (newPhotoFile) {
      return uploadUserPhoto(user.id, newPhotoFile).then(() => {
        navigate("/people")
      })
    } else {
      navigate("/people")
    }
  }

  const onClickSelectFile = () => {
    inputFileRef.current?.click()
  }

  const onSelectPhotoFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader()
      reader.addEventListener("load", () => setNewPhotoPreview(reader.result))
      reader.readAsDataURL(file)
      setNewPhotoFile(file)
    }
  }

  return (
    <Box>
      <Box sx={{marginBottom: 1, ...headerStyle}}>
        Add User
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper>
            <Box
              sx={{display: "flex", justifyContent: "center", position: "relative", width: 150, height: 150, margin: "auto"}}
              onClick={onClickSelectFile}
            >
              <input style={{display: "none"}}
                     type="file"
                     accept={SupportedImageTypes}
                     onChange={onSelectPhotoFile}
                     ref={inputFileRef}/>
              <Avatar
                sx={{
                  width: "calc(100% - 16px)",
                  height: "calc(100% - 16px)"
                }}
                src={newPhotoPreview as string}
              >
                <AddAPhotoIcon sx={{backgroundColor: "rgb(190 190 190 / 64%)"}}/>
              </Avatar>
              <Box
                sx={{
                  width: "calc(100% - 16px)",
                  height: "calc(100% - 16px)",
                  display: "flex",
                  borderRadius: "50%",
                  position: "absolute",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  zIndex: "9",
                  opacity: "0",
                  color: "rgb(255, 255, 255)",
                  backgroundColor: "rgba(22, 28, 36, 0.64)",
                  cursor: "pointer",
                  "&:hover": {
                    opacity: "0.75"
                  }
                }}
              >
                <AddAPhotoIcon/>
              </Box>
            </Box>
            <Box sx={{textAlign: "center", color: colors.blueGrey[400], fontSize: 14}}>
              <Box>Allowed *.jpeg, *.jpg, *.png, *.gif, *.webp</Box>
              <Box>max size of 3mb</Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper>
            <UserAddUpdate submitLabel={"Add"} onOk={onUserAdded} onCancel={() => navigate("/people")}/>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}