import * as React from 'react';
import {
  Avatar,
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {useTimeOffPolicies, useTimeOffReport} from "../api";
import {Link as RouterLink} from "react-router-dom";
import {nameAbbreviation} from "../string-format-util";
import {Spinner} from "../common/Spinner";

export const TimeOffReport = () => {

  const policies = useTimeOffPolicies()
  const timeOffReport = useTimeOffReport()

  const userTimeOff = new Map(
    timeOffReport.data?.timeOffReport.users.map(x =>
      [
        x.user.id,
        new Map(x.timeOff.map(timeOff => [timeOff.policyId, timeOff]))
      ]
    )
  )

  const cellStyle = {
    textAlign: "center",
    verticalAlign: "top"
  }


  if (timeOffReport.loading) {
    return <Spinner marginTop={"30vh"} />
  }

  const headerDataStyle = {
    overflow: "hidden",
    overflowY: "hidden",
    width: "70px"
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head" sx={{width: "200px", verticalAlign: "top"}}>Full Name</TableCell>
              {policies.data?.timeOffPolicies?.map(x =>
                <TableCell sx={cellStyle} variant="head" key={x.id}>
                  <Box sx={headerDataStyle}>
                    <Box>{x.name}</Box>
                    <Box sx={{fontSize: "12px", color: "#a3a9ae", lineHeight: "12px"}}>{x.paid ? 'paid' : 'unpaid'}</Box>
                  </Box>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {timeOffReport.data?.timeOffReport.users.map(x => {
              return (
                <TableRow key={x.user.id}>
                  <TableCell>
                    <Link underline="none" component={RouterLink} to={"/people/person/" + x.user.id + "/profile"}>
                      <Box sx={{display: "flex", gap: "10px", alignItems: "center"}}>
                        <Avatar src={x.user.photoUrl} sx={{fontSize: "14px"}}>
                          {nameAbbreviation(x.user.fullName)}
                        </Avatar>
                        {x.user.fullName}
                      </Box>
                    </Link>
                  </TableCell>
                  {policies.data?.timeOffPolicies.map(a => {
                    const b = userTimeOff.get(x.user.id)?.get(a.id)
                    return (<TableCell title="available/used days" sx={cellStyle} key={a.id}>{b ? `${b?.availableDays}/${b?.usedDays}d` : ""}</TableCell>)
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}