import {useTimeOffPolicy} from "../api";
import * as React from "react";
import {useParams} from "react-router-dom";
import {TimeOffPolicyAddUpdate} from "./TimeOffPolicyAddUpdate";

export const TimeOffPolicyEdit = () => {
  const {timeOffPolicyId} = useParams()
  const policy = useTimeOffPolicy(timeOffPolicyId!)

  if (!policy.data) {
    return <></>
  }

  return <TimeOffPolicyAddUpdate timeOffPolicy={policy.data.timeOffPolicy}/>
}