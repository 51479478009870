import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Paper
} from "@mui/material"
import * as React from "react";
import {useState} from "react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {Team, useDeleteWorkSchedule, UserRole, useWorkSchedules, WorkSchedule} from "../api";
import {useAuth} from "../auth/AuthProvider";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {ApiError} from "../errors";
import {NavLink} from "../common/NavLink";

export const WorkScheduleList = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState<Team | null>(null)
  const workSchedulers = useWorkSchedules()
  const [deleteWorkSchedule] = useDeleteWorkSchedule()

  const onOpenDeleteDialog = (x: WorkSchedule) => {
    setSelectedItem(x)
    setDeleteDialog(true)
  }

  const onConfirmDelete = () => {
    if (!selectedItem) {
      return
    }

    deleteWorkSchedule({
      variables: {
        id: selectedItem.id
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          // setNewNameError(e.message)
        }
      } else {
        workSchedulers.refetch()
        setDeleteDialog(false)
      }
    })
  }

  return (
    <Box sx={{marginTop: 2, display: "flex", gap: "10px", flexWrap: "wrap"}}>

      {workSchedulers.data?.workSchedules.map(x =>
        <Paper sx={{minWidth: "300px", maxWidth: "300px"}} key={x.id}>

          <Box sx={{display: "flex", justifyContent: "space-between", padding: "10px"}}>
            <Box sx={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
              <Link component={RouterLink} to={"/settings/work-schedule/" + x.id}>
                {x.name}
              </Link>
            </Box>
            <Badge badgeContent={4}>
              <PeopleIcon color="action" />
            </Badge>
          </Box>

          {auth.user?.role === UserRole.COMPANY_ADMIN &&
            <>
              <Divider/>

              <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: "5px"}}>
                <IconButton onClick={_ => onOpenDeleteDialog(x)}>
                  <DeleteIcon fontSize={"small"}/>
                </IconButton>
                <IconButton onClick={_ => navigate(`/settings/work-schedule/${x.id}/edit`)}>
                  <EditIcon fontSize={"small"}/>
                </IconButton>
              </Box>
            </>
          }

        </Paper>
      )}

      <Box sx={{display: "flex", width: "100%"}}>
        <Button variant="outlined" component={NavLink} to={"/settings/work-schedule/add"} color="primary">
          + Add
        </Button>
      </Box>

      <Dialog open={deleteDialog}>
        <DialogTitle>Delete work schedule</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          Are you sure you want to delete '{selectedItem?.name ?? ''}'?
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={_ => setDeleteDialog(false)}>No</Button>
          <Button variant="contained" color="error" onClick={onConfirmDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}