import {Box, Button, MenuItem, Paper, Select, TextField} from "@mui/material"
import {TimeOffPolicy, useAddTimeOffPolicy, useUpdateTimeOffPolicy} from "../api";
import * as React from "react";
import {useEffect, useState} from "react";
import {ApiError} from "../errors";
import {useNavigate} from "react-router-dom";
import {headerStyle} from "../style-util";

export const TimeOffPolicyAddUpdate = (props: {timeOffPolicy?: TimeOffPolicy}) => {
  const navigate = useNavigate()
  const edit = props.timeOffPolicy !== undefined

  const [addTimeOffPolicy] = useAddTimeOffPolicy()
  const [updateTimeOffPolicy] = useUpdateTimeOffPolicy()

  const defaultDays = 20
  const defaultAccrual = 'MONTHLY'
  const defaultCarryOverMaxDays = 10
  const defaultCarryOverExpireMonths = 3

  const [name, setName] = useState(props.timeOffPolicy?.name || "")
  const [paid, setPaid] = useState(edit ? props.timeOffPolicy?.paid ?? true : true)
  const [unlimited, setUnlimited] = useState(edit ? props.timeOffPolicy?.unlimited ?? false : false)
  const [nameError, setNameError] = useState("")
  const [days, setDays] = useState<number | null>(edit ? props.timeOffPolicy?.totalDaysOff ?? null : defaultDays)
  const [accrual, setAccrual] = useState<string | null>(edit ? props.timeOffPolicy?.accrual ?? null : defaultAccrual)
  const [probationPeriodDays, setProbationPeriodDays] = useState(edit ? props.timeOffPolicy?.probationPeriodDays : 30)
  const [carryOverMaxDays, setCarryOverMaxDays] = useState<number | null>(edit ? (props.timeOffPolicy?.carryoverMaxDays ?? null) : defaultCarryOverMaxDays)
  const [enableCarryOverExpire, setEnableCarryOverExpire] = useState(edit ? props.timeOffPolicy?.carryoverMaxDays !== 0 : false)
  const [carryOverExpireMonths, setCarryOverExpireMonths] = useState<number | null>(edit ? (props.timeOffPolicy?.carryoverExpirationMonths ?? null) : defaultCarryOverExpireMonths)

  useEffect(() => {
    setEnableCarryOverExpire(carryOverMaxDays !== 0)
    if (enableCarryOverExpire && carryOverMaxDays === 0) {
      setCarryOverExpireMonths(0)
    } else if (!enableCarryOverExpire && carryOverMaxDays !== 0) {
      setCarryOverExpireMonths(3)
    }
  }, [carryOverMaxDays])

  useEffect(() => {
    if (unlimited) {
      setDays(null)
      setAccrual(null)
      setCarryOverMaxDays(null)
      setCarryOverExpireMonths(null)
    } else {
      setDays(edit ? props.timeOffPolicy?.totalDaysOff ?? null : defaultDays)
      setAccrual(edit ? props.timeOffPolicy?.accrual ?? null : defaultAccrual)
      setCarryOverMaxDays(edit ? (props.timeOffPolicy?.carryoverMaxDays ?? null) : defaultCarryOverMaxDays)
      setCarryOverExpireMonths(edit ? (props.timeOffPolicy?.carryoverExpirationMonths ?? null) : defaultCarryOverExpireMonths)
    }
  }, [unlimited])

  const addOrUpdate = () => {
    if (edit) {
      onUpdate()
    } else {
      onAdd()
    }
  }

  const onUpdate = () => {
    updateTimeOffPolicy({
      variables: {
        id: props.timeOffPolicy?.id!,
        name: name,
        paid: paid,
        unlimited: unlimited,
        totalDaysOff: days,
        accrual: accrual,
        probationPeriodDays: probationPeriodDays,
        carryoverMaxDays: carryOverMaxDays,
        carryoverExpirationMonths: carryOverExpireMonths
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNameError(e.message)
        }
      } else {
        navigate("/settings/time-off-policy")
      }
    })
  }

  const onAdd = () => {
    addTimeOffPolicy({
      variables: {
        name: name,
        paid: paid,
        unlimited: unlimited,
        totalDaysOff: days,
        accrual: accrual,
        probationPeriodDays: probationPeriodDays,
        carryoverMaxDays: carryOverMaxDays,
        carryoverExpirationMonths: carryOverExpireMonths
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNameError(e.message)
        }
      } else {
        navigate("/settings/time-off-policy")
      }
    })
  }

  const rowStyle = {height: "55px"}
  const rowLabel = {minWidth: "180px"}

  return (
    <Box sx={{width: "600px"}}>
      <Box sx={{marginBottom: 1, marginLeft: 3, ...headerStyle}}>
        {edit ? "Update" : "Add"} Time Off Policy
      </Box>

      <Paper>
        <table style={{width: "100%"}}>
          <thead></thead>
          <tbody>
          <tr style={rowStyle}>
            <td style={rowLabel}>Name</td>
            <td>
              <TextField
                autoFocus
                type="text"
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
                error={nameError !== ''}
                helperText={nameError}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </td>
          </tr>
          <tr style={rowStyle}>
            <td style={rowLabel}>Paid</td>
            <td>
              <Select
                fullWidth
                size="small"
                margin="dense"
                value={paid}
                onChange={e => setPaid(e.target.value === 'true')}
              >
                <MenuItem value="false">no</MenuItem>
                <MenuItem value="true">yes</MenuItem>
              </Select>
            </td>
          </tr>
          <tr style={rowStyle}>
            <td style={rowLabel}>Unlimited</td>
            <td>
              <Select
                fullWidth
                size="small"
                margin="dense"
                value={unlimited}
                onChange={e => setUnlimited(e.target.value === 'true')}
              >
                <MenuItem value="false">no</MenuItem>
                <MenuItem value="true">yes</MenuItem>
              </Select>
            </td>
          </tr>
          {!unlimited &&
            <tr style={rowStyle}>
              <td style={rowLabel}>Days off per year</td>
              <td>
                <TextField
                  type="number"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                  //error={nameError !== ''}
                  //helperText={nameError}
                  disabled={unlimited}
                  value={days}
                  onChange={e => setDays(parseInt(e.target.value))}
                />
              </td>
            </tr>
          }
          {!unlimited &&
            <tr style={rowStyle}>
              <td style={rowLabel}>Accrual</td>
              <td>
                <Select
                  fullWidth
                  size="small"
                  margin="dense"
                  disabled={unlimited}
                  value={accrual}
                  onChange={e => setAccrual(e.target.value)}
                >
                  <MenuItem value="MONTHLY">Monthly</MenuItem>
                  <MenuItem value="IMMEDIATELY">Immediately</MenuItem>
                </Select>
              </td>
            </tr>
          }
          <tr style={rowStyle}>
            <td style={rowLabel}>Available after</td>
            <td>
              <Select
                fullWidth
                size="small"
                margin="dense"
                value={probationPeriodDays}
                onChange={e => setProbationPeriodDays(parseInt(e.target.value.toString()))}
              >
                <MenuItem value="0">none</MenuItem>
                <MenuItem value="7">1 week</MenuItem>
                <MenuItem value="14">2 weeks</MenuItem>
                <MenuItem value="21">3 weeks</MenuItem>
                <MenuItem value="30">1 month</MenuItem>
                <MenuItem value="44">1.5 months</MenuItem>
                <MenuItem value="60">2 months</MenuItem>
                <MenuItem value="74">2.5 months</MenuItem>
                <MenuItem value="90">3 months</MenuItem>
                <MenuItem value="120">4 months</MenuItem>
                <MenuItem value="150">5 months</MenuItem>
                <MenuItem value="180">6 months</MenuItem>
                <MenuItem value="210">7 months</MenuItem>
                <MenuItem value="240">8 months</MenuItem>
                <MenuItem value="270">9 months</MenuItem>
                <MenuItem value="300">10 months</MenuItem>
                <MenuItem value="330">11 months</MenuItem>
                <MenuItem value="360">12 months</MenuItem>
              </Select>
            </td>
          </tr>
          {!unlimited &&
            <tr style={rowStyle}>
              <td style={rowLabel}>Max carryover days</td>
              <td>
                <Select
                  fullWidth
                  size="small"
                  margin="dense"
                  disabled={unlimited}
                  value={carryOverMaxDays}
                  onChange={e => setCarryOverMaxDays(e.target.value === null ? null : parseInt(e.target.value?.toString()))}
                >
                  <MenuItem value="-1">unlimited</MenuItem>
                  <MenuItem value="0">disabled</MenuItem>
                  {Array.from(Array(100).keys()).slice(1).map(x =>
                    <MenuItem value={x} key={x}>{x}</MenuItem>
                  )}
                </Select>
              </td>
            </tr>
          }
          {!unlimited && carryOverMaxDays !== 0 &&
            <tr style={rowStyle}>
              <td style={rowLabel}>Carryover expires after</td>
              <td>
                <Select
                  fullWidth
                  size="small"
                  margin="dense"
                  value={carryOverExpireMonths}
                  disabled={unlimited || !enableCarryOverExpire}
                  onChange={e => setCarryOverExpireMonths(e.target.value === null ? null : parseInt(e.target.value.toString()))}
                >
                  <MenuItem value="-1">never</MenuItem>
                  <MenuItem value="1">1 month</MenuItem>
                  {Array.from(Array(37).keys()).slice(2).map(x =>
                    <MenuItem value={x} key={x}>{x} months</MenuItem>
                  )}
                </Select>
              </td>
            </tr>
          }
          </tbody>
        </table>

        <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}>
          <Button sx={{marginRight: 2}} onClick={() => navigate("/settings")}>Cancel</Button>
          <Button variant="contained" onClick={addOrUpdate}>{edit ? 'Update' : 'Add'}</Button>
        </Box>

      </Paper>
    </Box>
  )
}