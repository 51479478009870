import * as React from "react";


export const nameAbbreviation = (text?: string) => {
  if (!text) {
    return text
  }

  let result = ""
  const chunks = text.split(/(\s+)/).map(x => x.trim()).filter(x => x.length > 0)

  if (chunks.length > 0) {
    result += chunks[0][0].toUpperCase()
  }
  if (chunks.length > 1) {
    result += chunks[1][0].toUpperCase()
  } else if (chunks.length > 0) {
    result += chunks[0][1]?.toUpperCase() || ''
  }

  return result
}

export const round = (x: number, points: number) => {
  const a = Math.pow(10, points)
  return Math.round(x * a) / a
}