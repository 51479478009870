import {useAuth} from "../auth/AuthProvider";
import React, {useEffect} from "react";

export const Chat = () => {

  const auth = useAuth()

  useEffect(() => {
    if (auth.loaded && auth.loggedIn && auth.user) {
      const doc = document as any;

      doc.tidioIdentify = {
        distinct_id: auth.user?.id,
        email: auth.user?.email,
        name: auth.user?.fullName,
      };

      const script = document.createElement('script')
      script.src = '//code.tidio.co/8xeohitbcask0i3robr6alubqex52hpl.js'
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script);
      }
    }
  }, [auth])

  return (
    <></>
  )
}