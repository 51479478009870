import {Box} from "@mui/material";
import * as React from "react";
import {Spinner} from "./Spinner";

export const DataLoaderSpinner = (props: {isLoaded: boolean, hasData: boolean}) => {

  const style = {
    display: "flex",
    justifyContent: "center",
    margin: "10px",
    color: "gray"
  };

  return !props.isLoaded ? <Spinner  margin="10px auto"/> : !props.hasData ? <Box sx={style}>No data</Box> : <></>
}