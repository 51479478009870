import {
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import * as React from "react";
import {useRef, useState} from "react";
import {
  OvertimeRequest,
  OvertimeRequestStatus,
  User,
  UserRole,
  useUpdateOvertimeRequestStatus,
  useUserOvertimeRequests
} from "../api";
import moment from "moment";
import {useAuth} from "../auth/AuthProvider";
import {headerStyle, StatusColor, statusLabel} from "../style-util";
import {OvertimeAdd} from "./OvertimeAdd";
import {toTimeDuration} from "../data-time-util";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {MoreLessText} from "../common/MoreLessText";
import {DataLoaderSpinner} from "../common/DataLoaderSpinner";

export const PersonOvertime = (props: { user: User }) => {

  const auth = useAuth()
  const isAdmin = auth.user?.role === UserRole.COMPANY_ADMIN

  const today = moment();
  const startYear = moment(auth.user?.company?.createdAt).year()
  const endYear = today.year()
  const yearsRange = Array.from(Array(endYear - startYear + 1).keys()).map(x => x + startYear)

  const [year, setYear] = useState<number>(today.year())

  const requests = useUserOvertimeRequests(props.user.id, year)

  const [updateOvertimeRequestStatus] = useUpdateOvertimeRequestStatus()

  const [selectedOvertimeRequest, setSelectedOvertimeRequest] = useState<OvertimeRequest | null>(null)
  const [cancelOvertimeRequestDialog, setCancelOvertimeRequestDialog] = useState(false)
  const [overtimeRequestApproverComment, setOvertimeRequestApproverComment] = useState<string | null>(null)

  const overtimeRequestPopoverBtnAnchorRef = useRef(new Map())

  const onOpenCancelOvertimeRequestDialog = (item: OvertimeRequest) => {
    setSelectedOvertimeRequest(item)
    setCancelOvertimeRequestDialog(true)
    setOvertimeRequestApproverComment(null)
  }

  const onCloseCancelOvertimeRequestDialog = () => {
    setSelectedOvertimeRequest(null)
    setCancelOvertimeRequestDialog(false)
    setOvertimeRequestApproverComment(null)
  }

  const onChangeOvertimeRequestStatus = (status: OvertimeRequestStatus) => {
    if (!selectedOvertimeRequest) {
      return
    }

    updateOvertimeRequestStatus({
      variables: {
        overtimeRequestId: selectedOvertimeRequest.id,
        status: status,
        comment: overtimeRequestApproverComment
      }
    }).then(response => {
      if (response.error) {
        console.error(response.error)
      } else {
        requests.refetch()
      }
      onCloseCancelOvertimeRequestDialog()
    })
  }

  const cellStyle = {
    verticalAlign: "top"
  }

  return (
    <Box>
      <Box sx={{marginBottom: "15px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        {auth.user?.id === props.user.id &&
          <OvertimeAdd user={props.user} onAdded={() => requests.refetch()}/>
        }
        {auth.user?.id !== props.user.id &&
          <Box sx={headerStyle}>
            Overtimes history
          </Box>
        }

        <FormControl variant="outlined" margin="normal" size="small" sx={{margin: "0px"}}>
          <InputLabel>Year</InputLabel>
          <Select
            value={year}
            label={"Year"}
            SelectDisplayProps={{style: {padding: "5px 35px 5px 15px"}}}
            onChange={e => setYear(e.target.value as number)}
          >
            {yearsRange.map(x =>
              <MenuItem key={x} value={x}>{x}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Requested on</TableCell>
                <TableCell variant="head">Date</TableCell>
                <TableCell variant="head">Time from</TableCell>
                <TableCell variant="head">Time to</TableCell>
                <TableCell variant="head">Duration</TableCell>
                <TableCell variant="head">Comment</TableCell>
                <TableCell variant="head">Status</TableCell>
                {isAdmin &&
                  <TableCell variant="head" sx={{textAlign: "center"}}>Action</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.data?.user?.overtimeRequests?.map(x => {
                return (
                  <TableRow key={x.id}>
                    <TableCell sx={cellStyle}>{moment(x.createdAt).format("YYYY-MM-DD")}</TableCell>
                    <TableCell sx={{...cellStyle, whiteSpace: "nowrap"}}>{x.date}</TableCell>
                    <TableCell sx={{...cellStyle, whiteSpace: "nowrap"}}>{moment(x.fromTime, [moment.ISO_8601, 'HH:mm:ss']).format('HH:mm')}</TableCell>
                    <TableCell sx={{...cellStyle, whiteSpace: "nowrap"}}>{moment(x.toTime, [moment.ISO_8601, 'HH:mm:ss']).format('HH:mm')}</TableCell>
                    <TableCell sx={{...cellStyle, whiteSpace: "nowrap"}}>{toTimeDuration(x.fromTime, x.toTime).text}</TableCell>
                    <TableCell sx={{...cellStyle, maxWidth: "200px", wordBreak: "break-word"}}>
                      <MoreLessText text={x.description} len={40}/>
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {
                        statusLabel(
                          x.status,
                          x.status === 'APPROVED' ? StatusColor.GREEN :
                            x.status === 'DECLINED' ? StatusColor.RED :
                              x.status === 'CANCELLED' ? StatusColor.ORANGE
                                : StatusColor.BLUE
                        )
                      }
                      {x.approverComment &&
                        <Tooltip title={x.approverComment}>
                          <InfoOutlinedIcon sx={{color: colors.grey[500], position: "absolute", marginTop: "3px", marginLeft: "5px", fontSize: "16px"}}/>
                        </Tooltip>
                      }
                    </TableCell>
                    {isAdmin &&
                      <TableCell variant="head" style={{padding: "7px 0px", textAlign: "center", verticalAlign: "top"}}>
                        {x.status !== OvertimeRequestStatus.DECLINED && x.status !== OvertimeRequestStatus.CANCELLED &&
                          <>
                            <IconButton onClick={() => setSelectedOvertimeRequest(x)}
                                        ref={ref => overtimeRequestPopoverBtnAnchorRef.current.set(x.id, ref)}>
                              <MoreVertIcon/>
                            </IconButton>

                            <Menu
                              open={x === selectedOvertimeRequest}
                              anchorEl={overtimeRequestPopoverBtnAnchorRef.current.get(x.id)}
                              onClose={() => setSelectedOvertimeRequest(null)}
                            >
                              {x.status === OvertimeRequestStatus.REQUESTED &&
                                <>
                                <MenuItem onClick={_ => onChangeOvertimeRequestStatus(OvertimeRequestStatus.APPROVED)}>Approve</MenuItem>
                                <MenuItem onClick={_ => onChangeOvertimeRequestStatus(OvertimeRequestStatus.DECLINED)}>Decline</MenuItem>
                                </>
                              }
                              {x.status === OvertimeRequestStatus.APPROVED &&
                                <MenuItem onClick={() => onOpenCancelOvertimeRequestDialog(x)}>Cancel</MenuItem>
                              }
                            </Menu>
                          </>
                        }
                      </TableCell>
                    }
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <DataLoaderSpinner isLoaded={!requests.loading} hasData={(requests.data?.user?.overtimeRequests?.length ?? 0) > 0}/>

        <Dialog open={cancelOvertimeRequestDialog}>
          <DialogTitle>Cancel overtime request</DialogTitle>
          <DialogContent sx={{width: "400px"}}>
            <Box>
              Are you sure you want to cancel overtime request?
            </Box>
            <TextField
              multiline
              rows={2}
              type="text"
              label="Comment"
              fullWidth
              variant="outlined"
              margin="normal"
              value={overtimeRequestApproverComment}
              onChange={e => setOvertimeRequestApproverComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions sx={{padding: "10px 25px"}}>
            <Button onClick={onCloseCancelOvertimeRequestDialog}>No</Button>
            <Button variant="contained" color="error" onClick={_ => onChangeOvertimeRequestStatus(OvertimeRequestStatus.CANCELLED)}>Yes</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  )
}