import {Box, Divider, Paper} from "@mui/material";
import * as React from "react";
import Tab from "@mui/material/Tab";
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import {SlackIntegration} from "./SlackIntegration";

export const Integrations = () => {
  const location = useLocation();

  return (
    <Box sx={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
      <Paper sx={{width: "100%", display: "flex", flexDirection: "column", paddingTop: "5px"}}>
        <Tabs
          value={location.pathname}
          //onChange={handleChange}
          variant="scrollable"
        >
          <Tab
            label="Slack"
            component={Link}
            value={`/settings/integration/slack`}
            to={`/settings/integration/slack`}
            icon={<svg width="20px" height="20px" viewBox="0 0 2447.6 2452.5" xmlns="http://www.w3.org/2000/svg"><g clipRule="evenodd" fillRule="evenodd"><path d="m897.4 0c-135.3.1-244.8 109.9-244.7 245.2-.1 135.3 109.5 245.1 244.8 245.2h244.8v-245.1c.1-135.3-109.5-245.1-244.9-245.3.1 0 .1 0 0 0m0 654h-652.6c-135.3.1-244.9 109.9-244.8 245.2-.2 135.3 109.4 245.1 244.7 245.3h652.7c135.3-.1 244.9-109.9 244.8-245.2.1-135.4-109.5-245.2-244.8-245.3z" fill="#36c5f0"/><path d="m2447.6 899.2c.1-135.3-109.5-245.1-244.8-245.2-135.3.1-244.9 109.9-244.8 245.2v245.3h244.8c135.3-.1 244.9-109.9 244.8-245.3zm-652.7 0v-654c.1-135.2-109.4-245-244.7-245.2-135.3.1-244.9 109.9-244.8 245.2v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.3z" fill="#2eb67d"/><path d="m1550.1 2452.5c135.3-.1 244.9-109.9 244.8-245.2.1-135.3-109.5-245.1-244.8-245.2h-244.8v245.2c-.1 135.2 109.5 245 244.8 245.2zm0-654.1h652.7c135.3-.1 244.9-109.9 244.8-245.2.2-135.3-109.4-245.1-244.7-245.3h-652.7c-135.3.1-244.9 109.9-244.8 245.2-.1 135.4 109.4 245.2 244.7 245.3z" fill="#ecb22e"/><path d="m0 1553.2c-.1 135.3 109.5 245.1 244.8 245.2 135.3-.1 244.9-109.9 244.8-245.2v-245.2h-244.8c-135.3.1-244.9 109.9-244.8 245.2zm652.7 0v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.2v-653.9c.2-135.3-109.4-245.1-244.7-245.3-135.4 0-244.9 109.8-244.8 245.1 0 0 0 .1 0 0" fill="#e01e5a"/></g></svg>}
            iconPosition="start"
          />
        </Tabs>
        <Divider/>

        <Box sx={{marginTop: "25px"}}>
          <Routes>
            <Route path="/slack" element={<SlackIntegration/>}/>
            <Route path="/*" element={<Navigate to={`/settings/integration/slack`} replace/>}/>
          </Routes>
        </Box>
      </Paper>
    </Box>
  )
}