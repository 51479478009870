import {useWorkSchedule} from "../api";
import * as React from "react";
import {useParams} from "react-router-dom";
import {WorkScheduleAddUpdate} from "./WorkScheduleAddUpdate";

export const WorkScheduleEdit = () => {
  const {workScheduleId} = useParams()
  const workSchedule = useWorkSchedule(workScheduleId!)

  if (!workSchedule.data) {
    return <></>
  }

  return <WorkScheduleAddUpdate workSchedule={workSchedule.data.workSchedule}/>
}