import Box from "@mui/material/Box";


export const PrivacyPolicyPage = () => {
  return (
    <Box sx={{width: "900px", margin: "auto"}}>
        <p>Privacy Policy</p>
        <p>The SagaTeam platform Service Provider is committed to maintaining the confidentiality of information and Personal Data received and will take all measures necessary to ensure that they are safely stored.</p>
        <p>This Privacy Policy governs the relationship between SagaTeam (the Service Provider) and the User regarding the use of Confidential Information and Personal Data, its receipt, protection, processing, transfer and deletion, and is an integral part of the SagaTeam platform Service Agreement.</p>
        <p>What this Policy is about</p>

        <p>This privacy policy aims to give you information on how SagaTeam collects and processes your personal data through your use of our websites (including this SagaTeam.io), and any other web-apps (together referred to as our SagaTeam Platform in this Policy).</p>

        <p>Not intended for children: The SagaTeam Platform are not intended for children and we do not knowingly collect data relating to children.</p>

        <p>It is important that you read this privacy policy together with any other privacy information or notices we provide, so that you may understand how we use and keep your data secure.</p>

        <p>Collection of personal data</p>
        <p>We collect Personal Data from you for one or more of the following purposes:</p>

        <p>To provide you with information that you have requested or that we think may be relevant to a subject in which you have demonstrated an interest.</p>

        <p>To initiate and complete commercial transactions with you, or the entity that you represent, for the purchase of products and/or services.</p>

        <p>To fulfil an Agreement that we have entered into with you or with the entity that you represent. In these circumstances it may be your entity, rather than yourself, that has provided us with your Personal Data.</p>

        <p>To ensure the security and safe operation of our websites and underlying business infrastructure.</p>

        <p>To manage any communication between you and us.</p>

        <p>SagaTeam users</p>
        <p>A SagaTeam User is a natural or legal person who is registered in the SagaTeam system and is the owner of an account. If you are a SagaTeam User, we may process the following personal information about you:</p>

        <p>Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</p>

        <p>Contact Data includes address, email address and telephone numbers.</p>

        <p>Profile Data includes your basic account information; username password and access information; photos you may choose to upload; other information you may provide in response to a questionnaire or survey; your interests, preferences and feedback and survey responses; any other information you provide to use in relation to your SagaTeam Account.</p>

        <p>Marketing and Communications Data includes your preferences in receiving marketing from us, any third parties and your communication preferences, including registering for our newsletter.</p>

        <p>If you work for an Affiliate, Partner or Supplier</p>
        <p>In order to collaborate, receive each other’s products or services, we may need the following information about you in order to communicate with you about your work or organisation.</p>

        <p>Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</p>

        <p>Contact Data includes address, email address and telephone numbers.</p>

        <p>Technical information</p>
        <p>In addition, to ensure that each visitor to our website can use and navigate the site effectively, we collect the following:</p>

        <p>Technical information, including the IP (Internet Protocol) address used to connect your device to the Internet.</p>

        <p>Your login information, browser type and version, time zone setting, browser plugin types and versions.</p>

        <p>Operating system and platform.</p>

        <p>Information about your visit, including the URL (Uniform Resource Locator) clickstream to, through and from our site.</p>

        <p>Our Cookies Policy, which can be viewed from the homepage of our website, describes in detail how we use Cookies.</p>

        <p>Below, we identify your rights in respect of the Personal Data that we collect and describe how you can exercise those rights.</p>

        <p>The SagaTeam follows the ISO/IEC 27001 standards for information security management, as well as complies with ISO 27701 and GDPR regulations when handling Personal Data and Confidential Information.</p>

        <p>When placing an order for the use of the SagaTeam platform and entering his/her Personal Data, the User confirms that he/she has read the Privacy Policy and agrees to the processing of his/her Personal Data by SagaTeam for the specified purposes. In case of consent, the User can revoke his/her consent to this processing at any time.</p>

        <p>In this case, we will stop processing your data and restrict access to the platform without undue delay.</p>

        <p>Approval of this Privacy Policy is mandatory for obtaining the right to access the service, and is considered to be voluntary consent for the transfer and processing of Personal Data and Confidential Information in accordance with these terms and conditions, and the Terms of Services.</p>

        <p>Guarantees</p>
        <p>The User undertakes to respect the confidentiality of all data received in connection with the provision of the service of using the platform SagaTeam.</p>

        <p>Compliance with the general data protection regulation</p>
        <p>The Service Provider, in working with Personal Data, complies with the requirements of the GDPR, in particular:</p>

        <p>Processes Personal Data in a legal, legitimate and transparent manner in relation to the Data subject, namely, only after obtaining the consent of such data subject to the processing of Personal Data and taking into account that such processing is necessary for the performance of the Agreement on the provision of services for the use of the platform SagaTeam, to which the data subject is a party, or in order to take steps at the request of the data subject prior to entering into a Agreement;</p>
        <p>Collects Personal Data for the specified, clear and lawful purposes described in the SagaTeam platform Services Agreement and does not further process such data in a way that is incompatible with such purposes;</p>
        <p>Clearly identifies the received Personal Data and limits them to the extent necessary in view of the purposes of processing (“Data Minimization”);</p>
        <p>If necessary, updates the Personal Data and takes all appropriate measures to ensure that inaccurate Personal Data, taking into account the purposes of their processing, is deleted or corrected without delay (“accuracy”);</p>
        <p>Keeps Personal Data in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the Personal Data are processed;</p>
        <p>Processes Personal Data in a manner that ensures appropriate security of the Personal Data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures (‘integrity and confidentiality’);</p>
        <p>Monitors the availability of consent to receive and process Personal Data;</p>
        <p>Provides the possibility for the data subject to withdraw their consent to the processing of Personal Data at any time and guarantees that the withdrawal of consent will not affect the legality of the processing of Personal Data, which was based on consent before its withdrawal. That is, it equally provides the possibility of both withdrawal and consent.</p>
        <p>Provides the possibility of rectification or erasure of Personal Data;</p>
        <p>Provides the possibility of restriction of processing of Personal Data concerning the data subject or to object to such processing;</p>
        <p>Depersonalises data for their further use by the Service Provider;</p>
        <p>Guarantees compliance with the GDPR 2016/679 regulations of all entities that have access to Personal Data;</p>
        <p>Does not receive and does not process Personal Data that reveal racial or ethnic origin, political beliefs, religious or philosophical beliefs, or membership in professional unions, as well as genetic data, biometric data for the purpose of unique identification of a natural person, data related to the state of health or data about the sexual life of a natural person or their sexual orientation and other data with a special regime for their access and storage;</p>
        <p>Stores data on secure data carriers that meet the requirements of GDPR and ensures ongoing confidentiality, integrity, availability and stability of systems and services for processing Personal Data;</p>
        <p>Involve only processors providing sufficient guarantees to implement appropriate technical and organisational measures in such a manner that processing will meet the requirements of this Regulation and ensure the protection of the rights of the data subject.</p>
        <p>Sources of obtaining personal data</p>
        <p>The Service Provider receives Personal Data from the User solely for the purpose of providing access to the Platform (its modules), namely the data necessary for the User's registration in the Service Provider's system and further formation of the User's personal account.</p>

        <p>After granting the User access to the Platform, the User independently (and at his/her own discretion) collects and uploads Personal Data of his/her employees, recruiters, third parties to the Service Provider's systems, while the Service Provider does not collect such Personal Data, but only stores them, and therefore is not responsible for their reliability, accuracy, legality, legality, legal way of their collection, etc.</p>

        <p>The User is responsible for the truthfulness of the provided Personal Data, as well as for their timely updating. The User guarantees that they have received and transmitted Personal Data on legal grounds and with the sufficient consent and permission of the owner of such data. The Service Provider has the right to request confirmation of the veracity of such data and the sufficiency of the consent of the Personal Data owner.</p>

        <p>Data import</p>
        <p>After the SagaTeam platform has become available for use, the User can independently upload data to the Platform. The Service Provider guarantees the security of such data in accordance with this Privacy Policy, the Data Processing Agreement and the SagaTeam platform Service Agreement. The data entered by the User immediately after receipt is transferred to the cloud storage and cannot be used by the Service Provider.</p>

        <p>Third parties</p>
        <p>The Service Provider may provide Confidential Information and Personal Data to Third Parties based on the Users’ direct order (e.g. for the purpose of Platform integration with other services). In this case, the Service Provider is not responsible for the safety of such data and cannot guarantee compliance with the special regime of such information.</p>

        <p>The Service Provider may disclose Confidential Information and Personal Data to Third Parties solely for the purpose of providing services to the User, including involving third parties professionals, or integration with third-party software. In this case, the Service Provider is responsible for the actions of such third parties as its own. The use of Personal Data will only be carried out in accordance with the professional or employment duties of this Third Party. These Third Parties are obliged not to disclose in any way Personal Data that has been entrusted to them or that has become known to them in connection with their professional or official duties or employment.</p>

        <p>By accepting the terms of this Privacy Policy, the User consents to the transfer of information to Third Parties.</p>

        <p>Information may also be provided to law enforcement and other public bodies where such disclosure is mandatory and upon proper request, and the Service Provider shall immediately notify the User.</p>

        <p>Data protection of information, personal data and security of their processing</p>
        <p>The Service Provider guarantees protection of Personal Data and Confidential Information from accidental loss or destruction, from illegal processing, including illegal destruction or access to Personal Data, and implements appropriate technical and organisational measures which are designed to implement data-protection principles, such as data minimisation, in an effective manner and to integrate the necessary safeguards into the processing in order to meet the requirements of the GDPR and protect the rights of data subjects, namely:</p>

        <p>Uses data encryption at the content level;</p>
        <p>Personal Data is isolated and protected by multiple levels of security using multi-conditional access and multi-factor authentication;</p>
        <p>The Service Provider ensures continuous confidentiality, integrity, availability and stability of processing systems and services;</p>
        <p>Ensures timely restoration of Personal Data in the event of a technical accident;</p>
        <p>Ensures regular testing, evaluation and analysis of the effectiveness of technical and organizational measures to guarantee the security of data processing;</p>
        <p>Keeps Data locked down at every level and applies many technical and organizational measures to ensure its preservation and to prevent data leakage and unauthorized access to data, such as:</p>
        <p>Active error search program;</p>
        <p>Frequent vulnerability scans;</p>
        <p>Web application firewall;</p>
        <p>Verification of input data;</p>
        <p>Annual third-party penetration test;</p>
        <p>Continuous security management and monitoring;</p>
        <p>Backup data daily, every 24 hours.</p>
        <p>The Service Provider uses exclusively safe and secure resources to store Confidential Information and Personal Data, including Cloud storage media.</p>

        <p>The Service Provider engages operators to carry out Data processing, who provide sufficient guarantees regarding the implementation of the necessary technical and organizational measures in a way that allows for the compliance of the processing with the requirements of the GDPR and guaranteeing the protection of the rights of the Data Subject.</p>

        <p>The Service Provider has the international ISO / IEC 27001 Certificate, which is a guarantee that the service provider complies with the ISO/IEC 27001 information security management standards, as well as the GDPR when working with confidential information and Personal Data.</p>

        <p>The Service Provider has what he believes are appropriate security controls in place to protect Personal Data.</p>

        <p>Risk assessment, including assessing risks to the rights and freedoms of data subjects, is at the heart of the Service Provider’s ISMS. The Service Provider does not, however, have any control over what happens between the User's device and the boundary of the Service Provider's information infrastructure. Users should be aware of the many information security risks that exist and take appropriate steps to safeguard your own information. The Service Provider accepts no liability in respect of breaches that occur beyond his sphere of control.</p>

        <p>Purpose for which we will use personal data</p>
        <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the lawful bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>

        <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us for further information.</p>

        <p>Purpose/Activity	Type of data	Lawful basis</p>
        <p>To open SagaTeam Account. 	About who you are and how to contact you. 	To perform an Agreement with you (i.e. access our service. Necessary for our legitimate interest.</p>
        <p>To administer and protect our business and SagaTeam Platform (including troubleshooting, fraud prevention, data analysis, testing, system maintenance, support, reporting and hosting of data).	Information on who you are, how you use the SagaTeam Platform. Information on how you use our services.  	(a) Necessary for our legitimate interests (for running our business, improving our services, providing relevant information on activities and services, provision of administration and IT services (relevant technical support), network security, to prevent fraud).(b) Necessary to comply with a legal obligation.</p>
        <p>To deliver relevant SagaTeam content to you and measure or understand the effectiveness of our website and services. To offer you other services and products of SagaTeam.	Information about who you are your profile with SagaTeam. Information on how you use our SagaTeam Platform.	(a) Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our strategy). (b) With your consent when processing your health and well-being information.</p>
        <p>To use data analytics to improve SagaTeam Platform, products/services, marketing, client relationships. 	Information about how you use our SagaTeam Platform and other technical information. 	Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our strategy).</p>
        <p>To make suggestions and recommendations to you about services, that may be of interest for you and/or your Employer, Partner or Supplier.	Information about who you are, how to contact you and who you work for. 	Necessary for our legitimate interests (to develop our products/services and grow our business).</p>
        <p>User’s rights as a data subject</p>
        <p>As a Data Subject whose personal information SagaTeam hold, the User has certain rights. If the User wishes to exercise any of these rights, he/she may write to the email:system@sagateam.io. To process the User's request, the Service Provider will ask to provide two valid forms of identification for verification purposes. The User’s rights are as follows:</p>

        <p>The right to be informed</p>

        <p>The Service Provider is obliged to provide clear and transparent information about its data processing activities. This is provided by this privacy notice and any related communications the Service Provider may send to the User.</p>

        <p>The right of access</p>

        <p>The User may request a copy of their Personal Data held by the Service Provider free of charge. Once the Service Provider has verified User's identity and, if relevant, the authority of any third-party requester, the Service Provider will provide access to the personal data it stores about the User, as well as to the following information:</p>

        <p>The purposes of the processing.</p>

        <p>The categories of Personal Data concerned.</p>

        <p>The recipients to whom the Personal Data has been disclosed.</p>

        <p>The retention period or envisioned retention period for that Personal Data.</p>

        <p>When Personal Data has been collected from a Third Party, the source of the Personal Data.</p>

        <p>If there are exceptional circumstances that mean that the Service Provider may refuse to provide information, the Service Provider will explain this to the User. If responding to a request may require additional time or entail unreasonable costs (which may have to be borne by the User), the Service Provider will notify the User.</p>

        <p>The right to erasure (the ‘right to be forgotten’)</p>

        <p>Where no overriding legal basis or legitimate reason continues to exist for processing Personal Data, the User may request to delete the Personal Data. This includes Personal Data that may have been unlawfully processed. The Service Provider will take all reasonable steps to ensure erasure.</p>

        <p>The right to restrict processing</p>

        <p>The User may ask the Service Provider to stop processing his/her Personal Data. The Service Provider will still hold the Data, but will not process it any further. This right is an alternative to the right to erasure. If one of the following conditions applies the User may exercise the right to restrict processing.</p>

        <p>The right to rectification</p>

        <p>When the User believes that the Service Provider holds inaccurate or incomplete personal information about the User, he/she may exercise his/her right to correct or complete this data. This may be used with the right to restrict processing to make sure that incorrect/incomplete information is not processed until it is corrected.</p>

        <p>The accuracy of the Personal Data is contested.</p>

        <p>The right to data portability</p>

        <p>The User may request his/her set of Personal Data be transferred to another controller or processor, provided in a commonly used and machine-readable format. This right is only available if the original processing was on the basis of consent, the processing is by automated means and if the processing is based on the fulfilment of a contractual obligation.</p>

        <p>The right to object</p>

        <p>The User has the right to object processing of his/her data where:</p>

        <p>Processing is based on legitimate interest;</p>

        <p>Processing is for the purpose of direct marketing;</p>

        <p>Processing is for the purposes of scientific or historic research; or</p>

        <p>Processing involves automated decision-making and profiling.</p>

        <p>Retention of information</p>
        <p>SagaTeam retains your data for as long as it serves the purposes outlined in our Privacy Policy. Once your data is no longer needed for these purposes, we will either delete it or anonymize it in our active databases. Additionally, your data will be securely stored on backup drives but isolated from further processing until it can be deleted.</p>
        <p>SagaTeam deletes all information in the customer's account 90 calendar days after the termination of services (access to the platform) or within another period upon a separate request of the customer.</p>
        <p>Data provided by users through registration forms on our website, such as 'Request a demo,' 'sign up for a newsletter,' 'partner forms,' 'integration forms,' etc., is stored indefinitely by default.</p>
        <p>Please note that SagaTeam may occasionally retain customer data for longer periods as permitted or required by law, for example, to maintain no-fly lists, prevent abuse, if necessary in connection with a legal claim or legal proceeding, to enforce our agreements, for tax, accounting or other legal obligations.</p>

        <p>Disclosures of your personal data</p>
        <p>We may share your personal data with the parties set out below for the purposes set out in the table above.</p>

        <p>Internal third parties such as our employees or officers and legal entities within the SagaTeam group of companies.</p>

        <p>With research and trial partners, being commercial research.</p>

        <p>External third parties, payment providers, specialist IT support, cloud hosting providers, communications partners and sub-contractors.</p>

        <p>Professional advisers including lawyers, bankers, marketing agencies, auditors and insurers who provide consultancy, banking, legal, insurance and accounting services.</p>

        <p>Credit reference agencies, law enforcement and fraud prevention agencies.</p>

        <p>HM Revenue & Customs, regulators and other authorities who require reporting or processing activities in certain circumstances.</p>

        <p>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</p>

        <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>

        <p>International transfers</p>
        <p>We do not usually or regularly transfer data outside of the UK in the course of our business. In the event we transfer your personal data out of the UK, we will ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:</p>

        <p>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the UK Government (including the EEA as at the date of this version of the Policy).</p>

        <p>Where we use certain service providers, we may use specific contracts approved for use in the UK which give personal data the same protection it has in the UK, as well as conducting an appropriate risk assessment.</p>

        <p>Limited Use Policy</p>
        <p>SagaTeam adheres to the restricted use requirements set out in the Google API User Data Policy.</p>

        <p>We ensure that:</p>

        <p>- our data handling practices comply with Google API Services User Data Policy;</p>

        <p>- SagaTeam will only use the data obtained from Google APIs for the purposes specified in our Privacy Policy;</p>

        <p>- Use and transfer of information received from Google APIs to any other app will adhere to Google API Services User Data Policy including the Limited use requirements.</p>

        <p>Contact details</p>
        <p>If you have any questions about this privacy policy or our privacy practices, please contact our Data Protection Officer (DPO).</p>

        <p>The Service Provider has appointed a Data Protection Officer to oversee its management of the User’s personal information in accordance with this Privacy Policy. If the User has any questions or concerns about SagaTeam’s privacy practices with respect to User’s personal information, the User can reach out to our Data Protection Officer by sending an email to system@sagateam.io.</p>

        <p>Time limit to respond</p>
        <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

        <p>No fee usually required</p>
        <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>

        <p>Privacy Policy Change Notice</p>
        <p>Dear SagaTeam Users,</p>

        <p>We value your privacy and are committed to maintaining the security and transparency of your personal information. As part of our ongoing efforts to comply with data protection laws and to better serve you, we occasionally update our Privacy Policy.</p>

        <p>We are writing to inform you that our Privacy Policy has been updated as of</p>

        <p>January, 01, 2024.</p>

        <p>Your continued use of our services signifies your acceptance of these changes. We encourage you to read the updated Privacy Policy in full to understand how your personal data is collected, processed, and protected.</p>

        <p>If you have any questions, concerns, or feedback regarding our Privacy Policy or data protection practices, please don't hesitate to contact us at system@sagateam.io.</p>

        <p>Thank you for trusting us with your data.</p>

        <p>Sincerely,</p>

        <p>SagaTeam</p>
    </Box>
  )
}