import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Paper,
  TextField
} from "@mui/material"
import * as React from "react";
import {useState} from "react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {
  HolidaysCalendar,
  Team,
  useAddHolidaysCalendar,
  useDeleteHolidaysCalendar,
  useHolidaysCalendars,
  UserRole,
  useSystemHolidaysCalendars,
  useUpdateHolidaysCalendar
} from "../api";
import {useAuth} from "../auth/AuthProvider";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {ApiError} from "../errors";

export const HolidaysCalendarList = () => {
  const navigate = useNavigate();
  const auth = useAuth()

  const [addDialog, setAddDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)

  const [newName, setNewName] = useState("")
  const [newNameError, setNewNameError] = useState("")

  const [selectedItem, setSelectedItem] = useState<Team | null>(null)

  //const systemCalendars = useSystemHolidaysCalendars(moment().year())
  const holidaysCalendars = useHolidaysCalendars()

  //const [systemHolidaysCalendarId, setSystemHolidaysCalendarId] = useState<string>()

  const [addHolidaysCalendar] = useAddHolidaysCalendar()
  const [deleteHolidaysCalendar] = useDeleteHolidaysCalendar()
  const [updateHolidaysCalendar] = useUpdateHolidaysCalendar()

  const onCancelAddDialog = () => {
    setAddDialog(false)
    setNewName("")
    setNewNameError("")
  }

  const onAdd = () => {
    addHolidaysCalendar({
      variables: {
        name: newName,
        //systemHolidaysCalendarId: systemHolidaysCalendarId
      }
    }).then(response => {
      if (response.error) {
        /*const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }*/
      } else {
        navigate(`/settings/holidays-calendar/${response.data?.addHolidaysCalendar}`)
        //departments.refetch()
        //onCancelAddDialog()
        //go to details
      }
    })
  }

  const onOpenEditDialog = (x: HolidaysCalendar) => {
    setSelectedItem(x)
    setNewName(x.name)
    setEditDialog(true)
  }

  const onOpenDeleteDialog = (x: HolidaysCalendar) => {
    setSelectedItem(x)
    setDeleteDialog(true)
  }

  const onConfirmDelete = () => {
    if (!selectedItem) {
      return
    }

    deleteHolidaysCalendar({
      variables: {
        id: selectedItem.id
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }
      } else {
        holidaysCalendars.refetch()
        setDeleteDialog(false)
      }
    })
  }

  const onUpdate = () => {
    if (!selectedItem) {
      return
    }

    updateHolidaysCalendar({
      variables: {
        id: selectedItem.id,
        name: newName
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }
      } else {
        holidaysCalendars.refetch()
        onCancelUpdateDialog()
      }
    })
  }

  const onCancelUpdateDialog = () => {
    setEditDialog(false)
    setNewName("")
  }

  return (
    <Box sx={{marginTop: 2, display: "flex", gap: "10px", flexWrap: "wrap"}}>

      {holidaysCalendars.data?.holidaysCalendars.map(x =>
        <Paper sx={{minWidth: "300px", maxWidth: "300px"}} key={x.id}>

          <Box sx={{display: "flex", justifyContent: "space-between", padding: "10px"}}>
            <Box sx={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
              <Link component={RouterLink} to={"/settings/holidays-calendar/" + x.id}>
                {x.name}
              </Link>
            </Box>
            <Badge badgeContent={4}>
              <PeopleIcon color="action" />
            </Badge>
          </Box>

          {auth.user?.role === UserRole.COMPANY_ADMIN &&
            <>
              <Divider/>

              <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: "5px"}}>
                <IconButton onClick={e => onOpenDeleteDialog(x)}>
                  <DeleteIcon fontSize={"small"}/>
                </IconButton>
                <IconButton onClick={e => onOpenEditDialog(x)}>
                  <EditIcon fontSize={"small"}/>
                </IconButton>
              </Box>
            </>
          }

        </Paper>
      )}

      <Box sx={{display: "flex", width: "100%"}}>
        <Button variant="outlined" onClick={e => setAddDialog(true)}>+ Add</Button>
      </Box>
      <Dialog open={addDialog}>
        <DialogTitle>Add Holidays Calendar</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          <TextField
            size="small"
            autoFocus
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            margin="normal"
            error={newNameError !== ''}
            helperText={newNameError}
            value={newName}
            onChange={e => setNewName(e.target.value)}
          />

          {/*<Box sx={{margin: "10px 0px"}}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Country (Optional)</InputLabel>
              <Select
                size="small"
                label="Country (Optional)"
                value={systemHolidaysCalendarId}
                onChange={(e) => setSystemHolidaysCalendarId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {systemCalendars.data?.systemHolidaysCalendars.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>*/}
          {/*{countryHolidays.map(x =>
            <Box key={x.id} sx={{display: "flex", alignItems: "center"}}>
              <Checkbox/>
              <Box sx={{width: "120px", maxWidth: "120px"}}>Name</Box>
              <Box sx={{width: "180px"}}>{x.from} - {x.to}</Box>
            </Box>

          )}*/}


          {/*<Box sx={{margin: "20px 0px"}}>
            <Button variant="outlined">Add custom date</Button>
          </Box>*/}

        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={onCancelAddDialog}>Cancel</Button>
          <Button variant="contained" onClick={onAdd}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={onCancelUpdateDialog} open={editDialog}>
        <DialogTitle>Update Department</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          <TextField
            autoFocus
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            margin="normal"
            error={newNameError !== ''}
            helperText={newNameError}
            value={newName}
            onChange={e => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={e => onCancelUpdateDialog()}>Cancel</Button>
          <Button variant="contained" onClick={() => onUpdate()}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialog}>
        <DialogTitle>Delete Department</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          Are you sure you want to delete '{selectedItem?.name ?? ''}'?
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={e => setDeleteDialog(false)}>No</Button>
          <Button variant="contained" color="error" onClick={onConfirmDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}