import * as React from 'react';
import {Box, Paper} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import {PeopleList} from "./PeopleList";
import {OrgChart} from "./OrgChart";
import {useAuth} from "../auth/AuthProvider";
import {UserRole, UserStatus} from "../api";

export const People = () => {

  const location = useLocation()
  const {user} = useAuth()

  return (
    <Box>
      <Paper sx={{padding: "0px 20px"}}>
      <Tabs
        value={location.pathname}
        //onChange={handleChange}
        variant="scrollable"
      >
        <Tab
          label="List"
          component={Link}
          value={"/people/list"}
          to={"/people/list"}
        />
        {user?.role === UserRole.COMPANY_ADMIN &&
        <Tab
          label="Inactive List"
          component={Link}
          value={"/people/inactive-list"}
          to={"/people/inactive-list"}
        />
        }
        <Tab
          label="Org chart"
          component={Link}
          value={"/people/org-chart"}
          to={"/people/org-chart"}
        />
      </Tabs>
      </Paper>
      <Box sx={{marginTop: 2}}>
        <Routes>
          <Route path="/list" element={<PeopleList statuses={[UserStatus.ACTIVE, UserStatus.INVITED]}/>}/>
          {user?.role === UserRole.COMPANY_ADMIN &&
            <Route path="/inactive-list" element={<PeopleList statuses={[UserStatus.INACTIVE]}/>}/>
          }
          <Route path="/org-chart" element={<OrgChart/>}/>
          {/*<Route path="/locations/:locationId" element={<LocationDetails/>}/>*/}
          <Route path="/*" element={<Navigate to="/people/list" replace/>}/>
        </Routes>
      </Box>
    </Box>
  )
}

