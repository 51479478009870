import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import {blueGrey, grey} from "@mui/material/colors";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {Home} from "../home/Home";
import {Settings} from "../settings/Settings";
import React, {useRef, useState} from "react";
import {AddUser} from "../people/AddUser";
import {NavLink} from "../common/NavLink";
import {People} from "../people/People";
import {Person} from "../people/Person";
import {Reports} from "../report/Reports";
import {useAuth} from "../auth/AuthProvider";
import PersonIcon from '@mui/icons-material/Person';
import {useCompanies, useLogout, UserRole, useSwitchCompany} from "../api";
import {nameAbbreviation} from "../string-format-util";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {Schedule} from "../schedule/Schedule";
import {color} from "../color";
import {Chat} from "../chat/Chat";
import {PrivacyPolicyPage} from "../PrivacyPolicyPage";
import {useGlobalScope} from "../auth/GlobalScopeProvider";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

export const Main = () => {
  const auth = useAuth()
  const globalScope = useGlobalScope()
  const location = useLocation();
  const [openAccountContextMenu, setOpenAccountContextMenu] = useState(false)
  const accountContextMenuAnchorRef = useRef(null)
  const companies = useCompanies()
  const [logout] = useLogout()
  const [switchCompany] = useSwitchCompany()

  const onLogout = () => {
    logout().then(response => {
      if (response.data && response.data?.logout) {
        window.location.reload()
      }
    })
  }

  const onSwitchCompany = (companyId: string) => {
    switchCompany({variables: {companyId}}).then(response => {
      if (response.data && response.data?.switchCompany) {
        window.location.pathname = '/'
      }
    })
  }

  const activeMenuColor = 'black'
  const inactiveMenuColor = blueGrey[300]
  const menuItemFontSize = "15px"
  const drawerWidth = 150
  const drawerWidthWithPadding = drawerWidth + 35;

  const menuItem = {
    padding: "2px 15px",
    "&.Mui-selected": {
      //backgroundColor: color.selectedMenuItemBackground
    },
    "&:hover, &.Mui-selected:hover": {
      //backgroundColor: color.selectedMenuItemBackground
    },
    borderRadius: '8px'
  }

  const menuList =
    /*<Paper>*/
    <Box>
      <Box sx={{display: "flex", justifyContent: "center", marginBottom: "20px"}}>
        <Avatar src={auth.user?.photoUrl} sx={{fontSize: "14px", width: "70px", height: "70px"}}>
          {nameAbbreviation(auth.user?.fullName)}
        </Avatar>
      </Box>
      <List component="nav">
        <ListItem disablePadding sx={{paddingBottom: '3px'}}>
          <ListItemButton component={NavLink} to={"/home"} sx={menuItem}>
            <ListItemIcon sx={{minWidth: '30px'}}>
              <DashboardIcon fontSize="small"
                             sx={{color: location.pathname === '/home' ? activeMenuColor : inactiveMenuColor}}/>
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{
              color: location.pathname === '/home' ? activeMenuColor : inactiveMenuColor,
              fontSize: menuItemFontSize
            }}>Home</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{paddingBottom: '3px'}}>
          <ListItemButton component={NavLink} to={"/profile/" + auth.user?.id + "/profile"} sx={menuItem}>
            <ListItemIcon sx={{minWidth: '30px'}}>
              <PersonIcon fontSize="small"
                          sx={{color: location.pathname.startsWith('/profile') ? activeMenuColor : inactiveMenuColor}}/>
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{
              color: location.pathname.startsWith('/profile') ? activeMenuColor : inactiveMenuColor,
              fontSize: menuItemFontSize
            }}>Profile</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{paddingBottom: '3px'}}>
          <ListItemButton component={NavLink} to={"/people"} sx={menuItem}>
            <ListItemIcon sx={{minWidth: '30px'}}>
              <PeopleIcon fontSize="small"
                          sx={{color: location.pathname.startsWith('/people') ? activeMenuColor : inactiveMenuColor}}/>
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{
              color: location.pathname.startsWith('/people') ? activeMenuColor : inactiveMenuColor,
              fontSize: menuItemFontSize
            }}>People</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{paddingBottom: '3px'}}>
          <ListItemButton component={NavLink} to={"/schedule"} sx={menuItem}>
            <ListItemIcon sx={{minWidth: '30px'}}>
              <CalendarTodayIcon fontSize="small"
                                 sx={{color: location.pathname.startsWith('/schedule') ? activeMenuColor : inactiveMenuColor}}/>
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{
              color: location.pathname.startsWith('/schedule') ? activeMenuColor : inactiveMenuColor,
              fontSize: menuItemFontSize
            }}>Calendar</ListItemText>
          </ListItemButton>
        </ListItem>
        {(auth.user?.role === UserRole.COMPANY_ADMIN) &&
          <ListItem disablePadding sx={{paddingBottom: '3px'}}>
            <ListItemButton component={NavLink} to={"/report"} sx={menuItem}>
              <ListItemIcon sx={{minWidth: '30px'}}>
                <AssessmentIcon fontSize="small"
                                sx={{color: location.pathname.startsWith('/report') ? activeMenuColor : inactiveMenuColor}}/>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{
                color: location.pathname.startsWith('/report') ? activeMenuColor : inactiveMenuColor,
                fontSize: menuItemFontSize
              }}>Reports</ListItemText>
            </ListItemButton>
          </ListItem>
        }
        {auth.user?.role === UserRole.COMPANY_ADMIN &&
          <>
            <ListItem disablePadding sx={{paddingBottom: '3px'}}>
              <ListItemButton component={NavLink} to={"/settings"} sx={menuItem}>
                <ListItemIcon sx={{minWidth: '30px'}}>
                  <SettingsIcon fontSize="small"
                                sx={{color: location.pathname.startsWith('/settings') ? activeMenuColor : inactiveMenuColor}}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{
                  color: location.pathname.startsWith('/settings') ? activeMenuColor : inactiveMenuColor,
                  fontSize: menuItemFontSize
                }}>Settings</ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        }
      </List>
    </Box>
    /*</Paper>*/

  const pageWidth = "1200px"

  const header =
    <Box sx={{
      //position: "fixed",
      height: "60px",
      padding: "10px",
      background: "#ffffffd4",
      borderBottom: "1px solid " + grey[200],
      width: "100%",
      minWidth: pageWidth,
      //width: "calc(100% - " + drawerWidthWithPadding + "px)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: 1000
    }}>
      <Box sx={{
        padding: "0px 10px",
        color: "gray",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        gap: "15px"
      }}>
        {globalScope.company?.logoUrl &&
          <img src={globalScope.company?.logoUrl + "?" + Date.now()} height="40px"/>
        }
        {globalScope.company?.name}
      </Box>
      {/*<Box>People &gt; List</Box>
          <TextField label={"Find"} size="small"/>*/}
      {/*<Link sx={{fontSize: "14px", lineHeight: "34px"}} component={RouterLink} to={"/people/person/" + auth.user?.id + "/profile"}>
            {auth.user?.fullName}
          </Link>*/}

      <Box sx={{display: "flex", alignItems: "center"}}>
        <Box sx={{fontSize: "14px", marginRight: "10px"}}>
          {auth.user?.fullName}
        </Box>
        <IconButton
          size="small"
          onClick={() => setOpenAccountContextMenu(true)}
          ref={accountContextMenuAnchorRef}
        >
          <Avatar src={auth.user?.photoUrl} sx={{fontSize: "14px"}}>
            {nameAbbreviation(auth.user?.fullName)}
          </Avatar>
        </IconButton>
        <Menu
          sx={{minWidth: "200px"}}
          open={openAccountContextMenu}
          anchorEl={accountContextMenuAnchorRef.current}
          MenuListProps={{sx: {minWidth: "150px"}}}
          onClose={() => setOpenAccountContextMenu(false)}
        >
          <MenuItem onClick={onLogout} sx={{fontSize: "15px"}}>
            <LogoutIcon sx={{color: "rgba(0, 0, 0, 0.54)", marginRight: "10px"}}/>Log out
          </MenuItem>
          {companies.data?.companies.filter(x => !x.active).map(x =>
            <MenuItem sx={{fontSize: "15px"}} onClick={_ => onSwitchCompany(x.id)} key={x.id}>
              <LoginIcon sx={{color: "rgba(0, 0, 0, 0.54)", marginRight: "10px"}}/>{x.name}
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>


  {/*      <Drawer open={true} variant="permanent" sx={{["& > .MuiDrawer-paper"]: { backgroundColor: "#00322c", borderRadius: 0}}}>
        <Box sx={{width: drawerWidth}} >
          <Box sx={{padding: 1, color: grey[100], borderRadius: 1, fontWeight: "bold"}}>{companyName}</Box>
          {menuList}
        </Box>
      </Drawer>*/
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column", backgroundColor: color.rootBackground}}>
      {header}
      <Box
        sx={{
          paddingTop: "30px",
          width: pageWidth,
          margin: "auto",
          flexGrow: 1,
          //height: '100vh',
          overflow: 'auto',
          //marginLeft: drawerWidthWithPadding + 'px',
          //paddingTop: 1,
          //paddingRight: 2,
          //paddingLeft: 2,
          display: "flex"
        }}
      >
        <Box>{menuList}</Box>
        <Box sx={{
          width: "100%",
          marginLeft: 4,
          //paddingTop: "80px",
          paddingRight: 2,
          paddingLeft: 2,
          paddingBottom: 2,
        }}>
          <Routes>
            <Route path="/home" element={<Home/>}/>
            <Route path={"/profile/:userId/*"} element={<Person basePath="/profile"/>}/>
            <Route path="/people/*" element={<People/>}/>
            <Route path="/people/add" element={<AddUser/>}/>
            <Route path="/people/person/:userId/*" element={<Person basePath="/people/person"/>}/>
            <Route path="/schedule/*" element={<Schedule/>}/>
            <Route path="/report/*" element={<Reports/>}/>
            <Route path="/settings/*" element={<Settings/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
            <Route path="/*" element={<Navigate to="/home" replace/>}/>
          </Routes>
        </Box>
      </Box>
      <Chat/>
    </Box>
  );

}