
import "./spinner.css"
import {CSSProperties} from "react";

export const Spinner = (style: CSSProperties) => {
  return (
    <div className="spinner" style={style}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  )
}