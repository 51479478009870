import * as React from 'react';
import {useState} from 'react';
import {
  Avatar,
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {
  CalendarWorkScheduleType,
  CalendarWorkScheduleUserTime,
  useAddUserTimeSlot,
  useCalendarWorkSchedule,
  useDeleteUserTimeSlot,
  useDepartments,
  useLocations,
  User,
  UserRole,
  useTeams,
  useUpdateUserTimeSlot,
  useWorkSchedules
} from "../api";
import {Link as RouterLink} from "react-router-dom";
import {useAuth} from "../auth/AuthProvider";
import {nameAbbreviation} from "../string-format-util";
import moment from "moment/moment";
import {Moment} from "moment";
import {formatTime, toTimeDuration, weekDaysShort} from "../data-time-util";
import {color} from "../color";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Spinner} from "../common/Spinner";

interface Week {
  from: Moment,
  to: Moment,
  days: Moment[]
}

export const Schedule = () => {
  const auth = useAuth()
  const isWriteAccess = auth.user?.role === UserRole.COMPANY_ADMIN
  const rowsPerPage = 10
  const [page, setPage] = useState(0)

  const [workScheduleId, setWorkScheduleId] = useState("")
  const [locationId, setLocationId] = useState("")
  const [departmentId, setDepartmentId] = useState("")
  const [teamId, setTeamId] = useState("")
  const [periodType, setPeriodType] = useState("Weekly")

  const workSchedules = useWorkSchedules()
  const locations = useLocations()
  const departments = useDepartments()
  const teams = useTeams()

  const now = moment()
  const currentWeekStart = now.clone().startOf('isoWeek')

  const rangeSize = 5

  const createWeeksList = (currentWeek: Moment) => {
    let from = currentWeek.clone().subtract(rangeSize, 'w')
    let to = currentWeek.clone().add(rangeSize, 'w')

    const weeks = Array<Week>()

    while (from.isSameOrBefore(to)) {
      weeks.push({
        from: from.clone(),
        to: from.clone().add(6, 'd'),
        days: Array.from(Array(7).keys()).map(x => from.clone().add(x, 'd'))
      })

      from = from.clone().add(7, 'd')
    }

    return weeks
  }

  const [currentTimestamp, setCurrentTimestamp] = useState<number>(currentWeekStart.unix())
  const [weeks, setWeeks] = useState<Array<Week>>(createWeeksList(currentWeekStart))
  const [selectedWeek, setSelectedWeek] = useState(weeks[rangeSize])

  const onSelectWeek = (unix: number) => {
    const newWeeksList = createWeeksList(moment.unix(unix))
    setCurrentTimestamp(unix)
    setWeeks(newWeeksList)
    setSelectedWeek(newWeeksList[rangeSize])
  }

  const [selectedUserId, setSelectedUserId] = useState<string>()
  const [selectedDate, setSelectedDate] = useState<Moment>()
  const [selectedFromHour, setSelectedFromHour] = useState<number>(0)
  const [selectedFromMinute, setSelectedFromMinute] = useState<number>(0)
  const [selectedToHour, setSelectedToHour] = useState<number>(0)
  const [selectedToMinute, setSelectedToMinute] = useState<number>(0)
  const [cellDialog, setCellDialog] = useState(false)
  const [addUserTimeSlot] = useAddUserTimeSlot()
  const [updateUserTimeSlot] = useUpdateUserTimeSlot()
  const [deleteUserTimeSlot] = useDeleteUserTimeSlot()

  const users = useCalendarWorkSchedule(
    selectedWeek.from.format('YYYY-MM-DD'),
    selectedWeek.to.format('YYYY-MM-DD'),
    workScheduleId,
    locationId,
    departmentId,
    teamId)

  //userId -> date -> slot
  const userSlot: Map<string, [User, Map<string, CalendarWorkScheduleUserTime>]> =
    new Map(
    users.data?.calendarWorkSchedule?.users.map(x =>
       [
        x.user.id,
        [
          x.user,
          new Map(x.days.map(a => [a.date, a]))
        ]
      ]
    ) ?? []);

  const onCancelCellDialog = () => {
    setSelectedFromHour(0)
    setSelectedFromMinute(0)
    setSelectedDate(undefined)
    setSelectedUserId(undefined)
    setCellDialog(false)
  }

  const onDeleteSlot = () => {
    if (selectedUserId && selectedDate) {
      let date = selectedDate.format('YYYY-MM-DD')
      let userData = userSlot.get(selectedUserId)

      if (userData && userData[1] && userData[1].size > 0) {

        const slotId = userData[1]?.get(date)?.id

        deleteUserTimeSlot({
          variables: {
            id: slotId
          }
        }).then(response => {
          users.refetch()
        })
      }
    }

    setSelectedDate(undefined)
    setSelectedUserId(undefined)
    setCellDialog(false)
  }

  const onUpdateSlot = () => {
    if (selectedUserId && selectedDate) {
      const date = selectedDate.format('YYYY-MM-DD')
      const slotId = userSlot.get(selectedUserId)?.[1]?.get(date)?.id

      if (slotId) {
        updateUserTimeSlot({
          variables: {
            id: slotId,
            fromTime: `${formatTime(selectedFromHour)}:${formatTime(selectedFromMinute)}`,
            toTime: `${formatTime(selectedToHour)}:${formatTime(selectedToMinute)}`
          }
        }).then(response => {
          users.refetch()
        })
      } else {
        addUserTimeSlot({
          variables: {
            userId: selectedUserId,
            date: date,
            fromTime: `${formatTime(selectedFromHour)}:${formatTime(selectedFromMinute)}`,
            toTime: `${formatTime(selectedToHour)}:${formatTime(selectedToMinute)}`
          }
        }).then(response => {
          users.refetch()
        })
      }
    }
    setSelectedDate(undefined)
    setSelectedUserId(undefined)
    setCellDialog(false)
  }

  const nameHeaderCellStyle = {
    textAlign: "left",
    width: "200px",
    paddingRight: "5px",
    paddingLeft: "15px"
  }

  const headerCellStyle = {
    textAlign: "center",
    //width: "30px",
    paddingRight: "5px",
    paddingLeft: "5px"
  }

  const cellStyle = {
    //width: "50px",
    textAlign: "center",
    padding: "5px"
  }

  const cellWidth = "90px"
  const tableWidth = "950px"
  const nameCellWith = "220px"

  const selectEmptyCell = (userId: string, date: Moment) => {
    setSelectedUserId(userId)
    setSelectedDate(date)
    setCellDialog(true)
  }

  const selectBusyCell = (userId: string, date: Moment, slot: CalendarWorkScheduleUserTime) => {
    setSelectedUserId(userId)
    setSelectedDate(date)

    setSelectedFromHour(parseInt(slot.fromTime.split(":")[0]))
    setSelectedFromMinute(parseInt(slot.fromTime.split(":")[1]))

    setSelectedToHour(parseInt(slot.toTime.split(":")[0]))
    setSelectedToMinute(parseInt(slot.toTime.split(":")[1]))

    setCellDialog(true)
  }

  const getCell = (userId: string, slots: Map<string, CalendarWorkScheduleUserTime>, date: Moment) => {

    const dateString = date.format('YYYY-MM-DD')
    const slot = slots.get(dateString)

    if (slot) {
      const {minutes, text, fromHour, fromMinute, toHour, toMinute} = toTimeDuration(slot.fromTime, slot.toTime)

      const cellText =
        slot.type === CalendarWorkScheduleType.FLEXIBLE
          ? `${fromHour}:${fromMinute} - ${toHour}:${toMinute}`
          : slot.description || ''

      //TODO: display diff in hours.mins between from and to, e.g. 1h2m

      const displayDuration = slot.type === CalendarWorkScheduleType.FIXED
        || slot.type === CalendarWorkScheduleType.FLEXIBLE

      const displayText = (color: string) => (
       <Box title={cellText}>
         <Box sx={{color: color, fontWeight: "700"}}>{displayDuration ? text : ''}</Box>
         <Box sx={{fontSize: "12px", overflow: "hidden", whiteSpace: "nowrap", width: "75px", maxHeight: "50px"}}>{cellText}</Box>
        </Box>
      )

      if (slot.type === CalendarWorkScheduleType.FIXED
          || slot.type === CalendarWorkScheduleType.HOLIDAY
          || slot.type === CalendarWorkScheduleType.TIME_OFF) {

        const bgColor =
          slot.type === CalendarWorkScheduleType.FIXED ? colors.lightGreen[50] :
          slot.type === CalendarWorkScheduleType.HOLIDAY ? colors.orange[50] :
          slot.type === CalendarWorkScheduleType.TIME_OFF ? colors.red[50] :
          "white"

        const borderColor =
          slot.type === CalendarWorkScheduleType.FIXED ? colors.lightGreen[100] :
          slot.type === CalendarWorkScheduleType.HOLIDAY ? colors.orange[100] :
          slot.type === CalendarWorkScheduleType.TIME_OFF ? colors.red[100] :
          "white"

        if (minutes === 0) {
          return <></>
        }

        const readOnlyBusyFixed =
          <Box key={userId + "-" + dateString} sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: cellWidth,
            height: "50px",
            margin: "auto"
          }}>
            <Box className="busy-cell" sx={{
              backgroundColor: bgColor,
              width: "100%",
              height: "100%",
              display: "flex",
              border: "1px solid",
              borderColor: borderColor,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
            }}>
              {displayText(colors.lightGreen[300])}
            </Box>
          </Box>

        return readOnlyBusyFixed
      }

      const busy =
        <Box key={userId + "-" + dateString} sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: cellWidth,
          margin: "auto",
          height: "50px",
          ":hover .busy-cell": {
            borderColor: colors.lightBlue[200] + " !important",
          },
        }} onClick={_ => selectBusyCell(userId, date, slot)}>
          <Box className="busy-cell" sx={{
            backgroundColor: colors.lightBlue[50],
            width: "100%",
            height: "100%",
            display: "flex",
            border: "1px solid",
            borderColor: colors.lightBlue[100],
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            cursor: "pointer",
            flexDirection: "column"
          }}>
            {displayText(colors.lightBlue[300])}
          </Box>
        </Box>

      const readOnlyBusy =
        <Box key={userId + "-" + dateString} sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: cellWidth,
          height: "50px",
          margin: "auto"
        }}>
          <Box className="busy-cell" sx={{
            backgroundColor: colors.lightBlue[50],
            width: "100%",
            height: "100%",
            display: "flex",
            border: "1px solid",
            borderColor: colors.lightBlue[100],
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
          }}>
            {displayText(colors.lightBlue[300])}
          </Box>
        </Box>

      return isWriteAccess ? busy : readOnlyBusy;
    } else {
      const empty =
        <Box key={userId + "-" + dateString} sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: cellWidth,
          height: "50px",
          ":hover .empty-cell": {
            display: "flex"
          },
          margin: "auto"
        }} onClick={_ => selectEmptyCell(userId, date)}>
          <Box className="empty-cell" sx={{
            width: "100%",
            height: "100%",
            display: "none",
            border: "1px dotted",
            borderColor: colors.grey[500],
            //backgroundColor: "lightgreen",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            cursor: "pointer"
          }}>
            +
          </Box>
        </Box>

      const readOnlyEmpty =
        <Box key={userId + "-" + dateString} sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: cellWidth,
          height: "50px",
          margin: "auto",
        }}>
        </Box>

      return isWriteAccess ? empty : readOnlyEmpty;
    }
  }

  if (users.loading) {
    return <Spinner marginTop={"30vh"} />
  }

  return (
    <Box>
      <Paper>
        <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: "16px"}}>
          <Box sx={{display: "flex", gap: "10px"}}>
            <FormControl variant="outlined" size="small" sx={{width: "150px"}}>
              <InputLabel>Work Schedule</InputLabel>
              <Select
                size="small"
                label="Work Schedule"
                value={workScheduleId}
                onChange={(e) => setWorkScheduleId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {workSchedules.data?.workSchedules.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{width: "120px"}}>
              <InputLabel>Location</InputLabel>
              <Select
                size="small"
                label="Location"
                value={locationId}
                onChange={(e) => setLocationId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {locations.data?.locations.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{width: "120px"}}>
              <InputLabel>Department</InputLabel>
              <Select
                size="small"
                label="Department"
                value={departmentId}
                onChange={(e) => setDepartmentId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {departments.data?.departments.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{width: "120px"}}>
              <InputLabel>Team</InputLabel>
              <Select
                size="small"
                label="Team"
                value={teamId}
                onChange={(e) => setTeamId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {teams.data?.teams.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{display: "flex", gap: "10px", justifyContent: "flex-end", width: "100%"}}>
            <IconButton>
              <NavigateBeforeIcon onClick={_ => onSelectWeek(moment.unix(currentTimestamp).subtract(1, 'w').unix())}/>
            </IconButton>
            <FormControl variant="outlined" size="small" sx={{width: "120px"}}>
              <InputLabel>Period</InputLabel>
              <Select
                size="small"
                label="Period"
                value={periodType}
                onChange={(e) => setPeriodType(e.target.value as string)}
              >
                {/*, 'Monthly'*/}
                {['Weekly'].map(x =>
                  <MenuItem value={x} key={x}>
                    {x}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{width: "160px"}}>
              <InputLabel>Interval</InputLabel>
              <Select
                size="small"
                label="Interval"
                value={currentTimestamp}
                onChange={(e) => onSelectWeek(parseInt(e.target.value as string))}
              >
                {weeks.map(x =>
                  <MenuItem value={x.from.unix()} key={x.from.unix()}>
                    <Box sx={{display: "flex", gap: "5px"}}>
                      <Box sx={{width: "50px"}}>{x.from.format('MMM DD')}</Box>
                      -
                      <Box sx={{width: "50px"}}>{x.to.format('MMM DD')}</Box>
                    </Box>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <IconButton>
              <NavigateNextIcon onClick={_ => onSelectWeek(moment.unix(currentTimestamp).add(1, 'w').unix())}/>
            </IconButton>
          </Box>
        </Box>

        <TableContainer>
          <Table style={{width: tableWidth}}>
            <TableHead>
              <TableRow>
                <TableCell variant="head" sx={{nameHeaderCellStyle}}>Name</TableCell>
                {selectedWeek.days.map(x =>
                  <TableCell key={x.dayOfYear()} sx={{width: cellWidth, ...headerCellStyle}} variant="head">
                    <Box>
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          margin: "auto",
                          border: "1px solid",
                          borderColor: "#d7d9d8",
                          borderRadius: "30px",
                          lineHeight: "29px"
                        }}
                      >
                        {x.date()}
                      </Box>
                    </Box>
                    <Box>{x.format('dd')}</Box>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(userSlot.entries()).map(([userId, [user, slots]]) => {
                return (
                  <TableRow key={user.id} sx={{borderBottom: "1px solid", borderColor: colors.grey[200]}}>
                    <TableCell>
                      <Link underline="none" component={RouterLink} to={"/people/person/" + user.id + "/profile"}>
                        <Box sx={{display: "flex", gap: "10px", alignItems: "center", width: nameCellWith}}>
                          <Avatar src={user.photoUrl} sx={{fontSize: "14px"}}>
                            {nameAbbreviation(user.fullName)}
                          </Avatar>
                          {user.fullName}
                        </Box>
                      </Link>
                    </TableCell>

                    {Array.from(new Array(7), (x, i) => i).map(i =>
                      <TableCell key={i}
                                 sx={cellStyle}>
                        {
                          getCell(user.id, slots, selectedWeek.from.clone().add(i, 'd'))
                        }
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog onClose={onCancelCellDialog} open={cellDialog}>
          <DialogTitle align={"center"}>Update schedule</DialogTitle>
          <DialogContent sx={{width: "300px"}}>
            <Box sx={{display: "flex", flexDirection: "row", gap: "5px", justifyContent: "center", margin: "15px", alignItems: "center"}}>
              <Box sx={{width: "50px"}}>From</Box>
              <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
                <InputLabel>hour</InputLabel>
                <Select
                  size="small"
                  label="hour"
                  value={selectedFromHour}
                  onChange={e => setSelectedFromHour(e.target.value as number)}
                >
                  {Array.from(new Array(24), (x, i) => i).map(i =>
                    <MenuItem value={i} key={i}>
                      {formatTime(i)}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              :
              <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
                <InputLabel>min</InputLabel>
                <Select
                  size="small"
                  label="min"
                  value={selectedFromMinute}
                  onChange={e => setSelectedFromMinute(e.target.value as number)}
                >
                  {Array.from(new Array(60), (x, i) => i).map(i =>
                    <MenuItem value={i} key={i}>
                      {i < 10 ? '0' + i : i}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{display: "flex", flexDirection: "row", gap: "5px", justifyContent: "center", margin: "15px", alignItems: "center"}}>
              <Box sx={{width: "50px"}}>To</Box>
              <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
                <InputLabel>hour</InputLabel>
                <Select
                  size="small"
                  label="hour"
                  value={selectedToHour}
                  onChange={e => setSelectedToHour(e.target.value as number)}
                >
                  {Array.from(new Array(24), (x, i) => i).map(i =>
                    <MenuItem value={i} key={i}>
                      {i < 10 ? '0' + i : i}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              :
              <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
                <InputLabel>min</InputLabel>
                <Select
                  size="small"
                  label="min"
                  value={selectedToMinute}
                  onChange={e => setSelectedToMinute(e.target.value as number)}
                >
                  {Array.from(new Array(60), (x, i) => i).map(i =>
                    <MenuItem value={i} key={i}>
                      {i < 10 ? '0' + i : i}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{display: "flex", justifyContent: "center"}}>
            <Button onClick={onDeleteSlot} sx={{color: color.error}}>
              Delete
            </Button>
            <Button onClick={onCancelCellDialog}>Cancel</Button>
            <Button variant="contained" onClick={_ => onUpdateSlot()}>Update</Button>
          </DialogActions>
        </Dialog>

      </Paper>
    </Box>
  )
}