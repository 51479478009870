import * as React from "react";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {
  SlackIntegrationNotificationType,
  useAddSlackIntegration,
  useDeleteSlackIntegration,
  useDisableSlackIntegrationNotification,
  useEnableSlackIntegrationNotification,
  useSlackIntegration,
  useSlackIntegrationConnectUrl,
  useTestSlackIntegration
} from "../api";
import {ApiError} from "../errors";
import {
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch
} from "@mui/material";
import {color} from "../color";
import {formatTime} from "../data-time-util";
import moment from "moment-timezone";
import {useToast} from "../common/Toast";


export const SlackIntegration = () => {
  const [searchParams, _] = useSearchParams();
  const code = searchParams.get("code")
  const slackIntegrationConnectUrl = useSlackIntegrationConnectUrl()
  const [addSlackIntegration] = useAddSlackIntegration()
  const [deleteSlackIntegration] = useDeleteSlackIntegration()
  const [testSlackIntegration] = useTestSlackIntegration()
  const slackIntegration = useSlackIntegration();
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [enableSlackIntegrationNotification] = useEnableSlackIntegrationNotification()
  const [disableSlackIntegrationNotification] = useDisableSlackIntegrationNotification()

  const [notificationHour, setNotificationHour] = useState<number>(9)
  const [notificationMinute, setNotificationMinute] = useState<number>(0)
  const [notificationTimezone, setNotificationTimezone] = useState<string>(moment.tz.guess(true))

  const toast = useToast()

  const onConfirmDelete = () => {
    if (!slackIntegration.data?.slackIntegration) {
      return
    }

    deleteSlackIntegration().then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          // setNewNameError(e.message)
        }
      } else {
        slackIntegration.refetch()
        setDeleteDialog(false)
      }
    })
  }

  const onChangeEnable = (notification: SlackIntegrationNotificationType, enable: boolean) => {
    const params = {
      notification
    };

    (enable ? enableSlackIntegrationNotification({variables: params}) : disableSlackIntegrationNotification({variables: params}))
      .then(response => {
        if (response.error) {
          const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
          if (e) {
            //TODO
          }
        } else {
          slackIntegration.refetch()
        }
      })
  }

  useEffect(() => {
    if (code) {
      addSlackIntegration({
        variables: {
          code: code
        }
      }).then(response => {
        if (response.error) {
          const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
          if (e) {
            // setNewNameError(e.message)
          }
        } else {
          slackIntegration.refetch()
        }
      })
    }
  }, [])


  const onTestMessage = () => {
    testSlackIntegration().then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          toast.error(e.message)
        }
      } else {
        toast.info("Test message sent")
      }
    })
  };

  if (slackIntegration.loading) {
    return <></>
  }

  const headerStyle = {
    color: color.lightHeader,
    fontWeight: 600,
    fontSize: '14px',
    paddingRight: "50px"
  }

  if (slackIntegration.data?.slackIntegration) {
    const data = slackIntegration.data.slackIntegration
    return (
      <Box>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            <Box sx={headerStyle}>Connected channel</Box> {data.channelName + " " }
            <Box sx={{color: colors.grey[500], fontSize: "14px"}}>(Channel ID: {data.channelId})</Box>
          </Box>
          <Box sx={{display: "flex", gap: "10px"}}>
            <Button variant="outlined" sx={{width: "85px"}} onClick={onTestMessage}>Test</Button>
            <Button variant="outlined" color="error" onClick={_ => setDeleteDialog(true)}>Remove</Button>
          </Box>
        </Box>

        <Box sx={{marginTop: "35px"}}>
          <Box sx={headerStyle}>Daily notifications</Box>
          <Box sx={{marginTop: "15px"}}>
            <Switch size="small"
                    checked={data.notifications.indexOf(SlackIntegrationNotificationType.TIME_OFF) > -1}
                    onChange={e => onChangeEnable(SlackIntegrationNotificationType.TIME_OFF, e.target.checked)}
            />Who is out today
          </Box>
          <Box sx={{marginTop: "15px"}}>
            <Switch size="small"
                    checked={data.notifications.indexOf(SlackIntegrationNotificationType.BIRTHDAY) > -1}
                    onChange={e => onChangeEnable(SlackIntegrationNotificationType.BIRTHDAY, e.target.checked)}
            />Birthdays
          </Box>
          <Box sx={{marginTop: "15px"}}>
            <Switch size="small"
                    checked={data.notifications.indexOf(SlackIntegrationNotificationType.ANNIVERSARY) > -1}
                    onChange={e => onChangeEnable(SlackIntegrationNotificationType.ANNIVERSARY, e.target.checked)}
            />Anniversaries
          </Box>
          <Box sx={{marginTop: "15px"}}>
            <Switch size="small"
                    checked={data.notifications.indexOf(SlackIntegrationNotificationType.WELCOME) > -1}
                    onChange={e => onChangeEnable(SlackIntegrationNotificationType.WELCOME, e.target.checked)}
            />Newcomers
          </Box>
        </Box>

        <Box sx={{marginTop: "35px"}}>
          <Box sx={headerStyle}>Direct message notifications</Box>
          <Box sx={{marginTop: "15px"}}>
            <Switch size="small"
                    checked={data.notifications.indexOf(SlackIntegrationNotificationType.TIME_OFF_REQUEST) > -1}
                    onChange={e => onChangeEnable(SlackIntegrationNotificationType.TIME_OFF_REQUEST, e.target.checked)}
            />Time off request
          </Box>
        </Box>

        {false && <Box sx={{marginTop: "35px"}}>
          <Box sx={headerStyle}>Notification time</Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            justifyContent: "flex-start",
            marginTop: "20px",
            alignItems: "center"
          }}>
            <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
              <InputLabel>hour</InputLabel>
              <Select
                size="small"
                label="hour"
                value={notificationHour}
                onChange={e => setNotificationHour(e.target.value as number)}
              >
                {Array.from(new Array(24), (x, i) => i).map(i =>
                  <MenuItem value={i} key={i}>
                    {formatTime(i)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{width: "70px"}}>
              <InputLabel>min</InputLabel>
              <Select
                size="small"
                label="min"
                value={notificationMinute}
                onChange={e => setNotificationMinute(e.target.value as number)}
              >
                {Array.from(new Array(60), (x, i) => i).map(i =>
                  <MenuItem value={i} key={i}>
                    {i < 10 ? '0' + i : i}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{width: "170px"}}>
              <InputLabel>timezone</InputLabel>
              <Select
                size="small"
                label="timezone"
                value={notificationTimezone}
                onChange={e => setNotificationTimezone(e.target.value)}
              >
                {moment.tz.names().map(i =>
                  <MenuItem value={i} key={i}>
                    {i}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>
        }

        <Dialog open={deleteDialog}>
          <DialogTitle>Delete Slack Integration</DialogTitle>
          <DialogContent sx={{width: "400px"}}>
            Are you sure you want to delete Slack integration?
          </DialogContent>
          <DialogActions sx={{padding: "10px 25px"}}>
            <Button onClick={_ => setDeleteDialog(false)}>No</Button>
            <Button variant="contained" color="error" onClick={onConfirmDelete}>Yes</Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }

  if (slackIntegrationConnectUrl.loading || !slackIntegrationConnectUrl.data?.slackIntegrationConnectUrl) {
    return <></>
  }

  return (
    <Button href={slackIntegrationConnectUrl.data.slackIntegrationConnectUrl} variant="outlined">
      Connect
    </Button>
  )
}