import {Box, Divider, Paper} from "@mui/material";
import * as React from "react";
import Tab from "@mui/material/Tab";
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import {LocationList} from "./LocationList";
import {DepartmentList} from "./DepartmentList";
import {TeamList} from "./TeamList";
import {CompanyGeneral} from "./CompanySettings";

export const General = () => {
  const location = useLocation();

  return (
    <Box sx={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
      <Paper sx={{width: "100%", display: "flex", flexDirection: "column", paddingTop: "5px"}}>
        <Tabs
          value={location.pathname}
          //onChange={handleChange}
          variant="scrollable"
        >
          <Tab
            label="Company"
            component={Link}
            value={`/settings/general/company`}
            to={`/settings/general/company`}
          />
          <Tab
            label="Locations"
            component={Link}
            value={`/settings/general/locations`}
            to={`/settings/general/locations`}
          />
          <Tab
            label="Departments"
            component={Link}
            value={"/settings/general/departments"}
            to={"/settings/general/departments"}
          />
          <Tab
            label="Teams"
            component={Link}
            value={"/settings/general/teams"}
            to={"/settings/general/teams"}
          />
        </Tabs>
        <Divider/>
        <Box>
          <Routes>
            <Route path="/company" element={<CompanyGeneral/>}/>
            <Route path="/locations" element={<LocationList/>}/>
            <Route path="/departments" element={<DepartmentList/>}/>
            <Route path="/teams" element={<TeamList/>}/>
            <Route path="/*" element={<Navigate to={`/settings/general/company`} replace/>}/>
          </Routes>
        </Box>
      </Paper>
    </Box>
  )
}