import * as React from 'react';
import {useState} from 'react';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from "@mui/material";
import {NavLink} from "../common/NavLink";
import {useDepartments, useLocations, UserRole, UserStatus, useTeams, useUsers} from "../api";
import {Link as RouterLink} from "react-router-dom";
import {useAuth} from "../auth/AuthProvider";
import {userStatusLabel} from "../style-util";
import {nameAbbreviation} from "../string-format-util";
import {Spinner} from "../common/Spinner";

export const PeopleList = (props: {
  statuses?: Array<UserStatus>
}) => {
  const auth = useAuth()
  const rowsPerPage = 10
  const [page, setPage] = useState(0)

  const [search, setSearch] = useState("")
  const [locationId, setLocationId] = useState("")
  const [departmentId, setDepartmentId] = useState("")
  const [teamId, setTeamId] = useState("")

  const users = useUsers(page, rowsPerPage, locationId, departmentId, teamId, search, props.statuses)
  const locations = useLocations()
  const departments = useDepartments()
  const teams = useTeams()

  const headerCellStyle = {
    whiteSpace: "nowrap"
  }

  return (
    <Paper>
      <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 1}}>

        <Box sx={{width: "100%", display: "flex", justifyContent: 'space-between', marginBottom: 1}}>
          <Box sx={{display: "flex", gap: "10px"}}>
            <TextField placeholder="Search"
                       variant="outlined"
                       value={search}
                       onChange={e => setSearch(e.target.value)}
                       sx={{flexGrow: 2}}
                       type="search"
                       size="small"/>

            <FormControl variant="outlined" size="small" sx={{width: "180px"}}>
              <InputLabel>Location</InputLabel>
              <Select
                size="small"
                label="Location"
                value={locationId}
                onChange={(e) => setLocationId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {locations.data?.locations.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{width: "180px"}}>
              <InputLabel>Department</InputLabel>
              <Select
                size="small"
                label="Department"
                value={departmentId}
                onChange={(e) => setDepartmentId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {departments.data?.departments.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{width: "180px"}}>
              <InputLabel>Team</InputLabel>
              <Select
                size="small"
                label="Team"
                value={teamId}
                onChange={(e) => setTeamId(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {teams.data?.teams.map(x =>
                  <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          {auth.user?.role === UserRole.COMPANY_ADMIN &&
            <Box sx={{display: 'flex', justifyContent: 'end'}}>
              <Button sx={{whiteSpace: "nowrap"}} variant="outlined" component={NavLink} to={"/people/add"} color="primary">
                + Add User
              </Button>
            </Box>
          }
        </Box>
      </Box>

      {users.loading &&
        <Spinner marginTop={"30vh"} />
      }

      {!users.loading &&
      <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head" sx={headerCellStyle}>Full Name</TableCell>
              <TableCell variant="head" sx={headerCellStyle}>Title</TableCell>
              <TableCell variant="head" sx={headerCellStyle}>Location</TableCell>
              <TableCell variant="head" sx={headerCellStyle}>Department</TableCell>
              <TableCell variant="head" sx={headerCellStyle}>Team</TableCell>
              <TableCell variant="head" sx={headerCellStyle}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.data?.users.list.map(x => {
              return (
                <TableRow key={x.email}>
                  <TableCell>
                    <Link underline="none" component={RouterLink} to={"/people/person/" + x.id + "/profile"}>
                      <Box sx={{display: "flex", gap: "10px", alignItems: "center", minWidth: "200px"}}>
                        <Avatar src={x.photoUrl} sx={{fontSize: "14px"}}>
                          {nameAbbreviation(x.fullName)}
                        </Avatar>
                        {x.fullName}
                      </Box>
                    </Link>
                  </TableCell>
                  <TableCell>{x.title}</TableCell>
                  <TableCell>{x.location?.name ?? ""}</TableCell>
                  <TableCell>{x.department?.name ?? ""}</TableCell>
                  <TableCell>{x.team?.name ?? ""}</TableCell>
                  <TableCell>
                    {userStatusLabel(x.status)}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={rowsPerPage}
        component="div"
        count={users.data?.users.total ?? 0}
        page={page}
        onPageChange={(e, page) => setPage(page)}
      />
    </>
    }
    </Paper>
  )
}