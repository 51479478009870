import {Box, Paper} from "@mui/material";
import * as React from "react";
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {PayrollReport} from "./PayrollReport";
import {TimeOffReport} from "./TimeOffReport";

export const Reports = () => {

  const location = useLocation()

  const sectionStyle = {
    marginBottom: "40px"
  }

/*  <>
    <Box sx={sectionStyle}>
      <Box sx={headerStyle}>
        TODO: time report
      </Box>
    </Box>
  </>*/

  return (
    <Box>
      <Paper sx={{padding: "0px 20px"}}>
        <Tabs
          value={location.pathname}
          //onChange={handleChange}
          variant="scrollable"
        >
          <Tab
            label="Payroll"
            component={Link}
            value={"/report/payroll"}
            to={"/report/payroll"}
          />
          <Tab
            label="Time Off"
            component={Link}
            value={"/report/time-off"}
            to={"/report/time-off"}
          />
        </Tabs>
      </Paper>
      <Box sx={{marginTop: 2, maxWidth: "995px"}}>
        <Routes>
          <Route path="/payroll" element={<PayrollReport/>}/>
          <Route path="/time-off" element={<TimeOffReport/>}/>
          <Route path="/*" element={<Navigate to="/report/payroll" replace/>}/>
        </Routes>
      </Box>
    </Box>
  )
}