import {useEffect, useState} from "react";
import {Box, Container, CssBaseline, Link} from "@mui/material";
import {useVerifyUserRegistration} from "../api";
import {useSearchParams} from "react-router-dom";
import {Spinner} from "../common/Spinner";

export const RegistrationVerificationPage = () => {

  const [message, setMessage] = useState("")

  const [verifyUserRegistration] = useVerifyUserRegistration()

  const [searchParams, _] = useSearchParams();
  const token = searchParams.get("token")

  useEffect(() => {
    if (token) {
      verifyUserRegistration({
        variables: {
          token: token
        }
      }).then(response => {
        if (response.data?.verifyUserRegistration) {
          window.location.href = "/"
        } else {
          setMessage("Unable to find related registration request")
        }
      })
    }
    //TODO: error message
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          height: "100vh",
          display: "flex"
        }}
      >
        <Box
          sx={{
            margin: "auto",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: "400px"
          }}
        >
          {message &&
            <>
              <Box>
                {message}
              </Box>
              <Link href="/login" variant="body2">
                Back to login page
              </Link>
            </>
          }
          {!message &&
            <Spinner margin="auto"/>
          }
        </Box>
      </Box>
    </Container>
  )
}