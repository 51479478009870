import {useState} from "react";
import {Box, Button, Container, CssBaseline, Grid, Link, TextField} from "@mui/material";
import {useRequestPasswordReset} from "../api";
import {onEnter} from "../event-util";

export const PasswordResetRequestPage = () => {

  const [submitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState("")

  const [requestPasswordReset] = useRequestPasswordReset()

  const onRequestPasswordReset = () => {
    requestPasswordReset({
      variables: {
        email: email
      }
    })
      .then(response => {
        //TODO: error handling

        setSubmitted(true)
      })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          height: "100vh",
          display: "flex"
        }}
      >
        <Box
          sx={{
            margin: "auto",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: "400px"
          }}
        >
          {!submitted &&
            <Box sx={{mt: 1, width: "100%"}}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                size="small"
                value={email}
                onChange={x => setEmail(x.target.value)}
                onKeyDown={onEnter(onRequestPasswordReset)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                onClick={onRequestPasswordReset}
              >
                Reset password
              </Button>
            </Box>
          }

          {submitted &&
          <Box>
            <Box>If your account exists in the system you will get email notification with password reset link.</Box>
            <Box>Please check your email.</Box>
          </Box>
          }
          <Grid container>
            <Grid item>
              <Link href="/login" variant="body2">
                Back to login page
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}