import "./spinner.css"
import {useState} from "react";
import Box from "@mui/material/Box";
import {Link} from "@mui/material";

export const MoreLessText = (props: {text: string, len: number}) => {

  const [showMore, setShowMore] = useState(false);

  if (props.text === null || props.text === undefined || props.text === '' || props.text.length <= props.len) {
    return <>{props.text}</>
  }

  return (
    <Box>
      {showMore ? props.text : `${props.text.substring(0, Math.min(props.len, props.text.length))}`}
      <Link sx={{cursor: "pointer", paddingLeft: "2px"}} onClick={() => setShowMore(!showMore)}>
        {showMore ? "less" : "..more"}
      </Link>
    </Box>
  )
}