import {Alert, Slide, SlideProps, Snackbar} from "@mui/material";
import * as React from "react";
import {useState} from "react";

const generateID = () => {
  let first = (Math.random() * 46656) | 0
  let second = (Math.random() * 46656) | 0
  return ('000' + first.toString(36)).slice(-3) + ('000' + second.toString(36)).slice(-3)
}


export const Toast = (props: {onClose: () => void, message: string, type: "info" | "error"}) => {

  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    props.onClose()
  }

  //useTimeout(handleClose, 3000);

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      TransitionComponent={(props: SlideProps) => {
        return <Slide {...props}  direction="left" />
      }}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={props.type === "error" ? "error" : "success"}
        sx={{ width: '100%' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  )
}


const ToastContext = React.createContext<{
  info: (message: string) => void,
  error: (message: string) => void
}>({

  info: () => {},
  error: () => {}
});

export const useToast = () => {
  return React.useContext(ToastContext);
}

export const ToastProvider = (props: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState(Array<{id: string, message: string, type: 'info' | 'error'}>());

  const info = (message: string) => show(message, "info")

  const error = (message: string) => show(message, "error")

  const show = (message: string, type: 'info' | 'error') =>
    setToasts((currentToasts) => [
      ...currentToasts,
      { id: generateID(), message, type: type },
    ])

  const onClose = (id: string) =>
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );

  return (
    <ToastContext.Provider value={{info: info, error: error}}>
      {toasts.map((toast) => (
        <Toast key={toast.id} message={toast.message} type={toast.type} onClose={() => onClose(toast.id)}/>
      ))}

      {props.children}
    </ToastContext.Provider>
  );
};