import Box from "@mui/material/Box";
import {Button, Link} from "@mui/material";


export const LandingPage = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", paddingTop: "20px", width: "900px" }}>
        <Box sx={{ display: "flex", alignItems: "center"}}>
          <img width={"20px"} height={"20px"} src={"logo.png"}/>
          <Box sx={{marginLeft: "10px"}}>SagaTeam</Box>
        </Box>
        <Button href="/login" variant="outlined">Log in</Button>
      </Box>
      <Box sx={{width: "900px"}}>
        <Box sx={{textAlign: "center", fontSize: "32px", margin: "50px"}}>
          Simple HRM tool for small teams
        </Box>

        <Box sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Box sx={{color: "gray", fontSize: "18px", fontWeight: "bold"}}>Quick access to people's status</Box>
          <Box sx={{marginTop: "10px", marginBottom: "10px"}}>Get to know who is out today or who are the newcomers</Box>
          <img width={"100%"} style={{borderTop: "1px solid rgb(240 240 240 / 52%)"}} src={"landing-preview.png"}/>
        </Box>

        <Box sx={{marginTop: "100px", display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Box sx={{color: "gray", fontSize: "18px", fontWeight: "bold"}}>Self-Service</Box>
          <Box sx={{marginTop: "10px", marginBottom: "10px"}}>Access personal profile and request time off at any time</Box>
          <img width={"100%"} style={{borderTop: "1px solid rgb(240 240 240 / 52%)"}} src={"landing-preview-profile.png"}/>
        </Box>

        <Box sx={{marginTop: "100px", display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Box sx={{color: "gray", fontSize: "18px", fontWeight: "bold"}}>Company view</Box>
          <Box sx={{marginTop: "10px", marginBottom: "10px"}}>Find any member in the org chart</Box>
          <img width={"100%"} style={{borderTop: "1px solid rgb(240 240 240 / 52%)"}} src={"landing-preview-org.png"}/>
        </Box>
      </Box>
      <Box sx={{margin: "20px"}}>
        <Link href="/privacy-policy">Privacy Policy</Link>
      </Box>
    </Box>
  )
}