import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  TextField
} from "@mui/material"
import {EmploymentType, useAddEmploymentType, useDeleteEmploymentType, useEmploymentTypes, UserRole, useUpdateEmploymentType} from "../api";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as React from "react";
import {useState} from "react";
import {ApiError} from "../errors";
import {useAuth} from "../auth/AuthProvider";

export const EmploymentTypeList = () => {
  const auth = useAuth()
  const employmentTypes = useEmploymentTypes()
  const [selectedEmploymentType, setSelectedEmploymentType] = useState<EmploymentType | null>(null)

  const [addEmploymentType] = useAddEmploymentType()
  const [updateEmploymentType] = useUpdateEmploymentType()
  const [deleteEmploymentType] = useDeleteEmploymentType()

  const [addDialog, setAddDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)

  const [newName, setNewName] = useState("")
  const [newNameError, setNewNameError] = useState("")

  const onConfirmDelete = () => {
    if (!selectedEmploymentType) {
      return
    }

    deleteEmploymentType({
      variables: {
        id: selectedEmploymentType.id
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }
      } else {
        employmentTypes.refetch()
        setDeleteDialog(false)
      }
    })
  }

  const onOpenEditDialog = (EmploymentType: EmploymentType) => {
    setSelectedEmploymentType(EmploymentType)
    setNewName(EmploymentType.name)
    setEditDialog(true)
  }

  const onOpenDeleteDialog = (EmploymentType: EmploymentType) => {
    setSelectedEmploymentType(EmploymentType)
    setDeleteDialog(true)
  }

  const onAdd = () => {
    addEmploymentType({variables: {
        name: newName
      }}).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }
      } else {
        employmentTypes.refetch()
        onCancelAddDialog()
      }
    })
  }

  const onUpdate = () => {
    if (!selectedEmploymentType) {
      return
    }

    updateEmploymentType({
      variables: {
        id: selectedEmploymentType.id,
        name: newName
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }
      } else {
        employmentTypes.refetch()
        onCancelUpdateDialog()
      }
    })
  }

  const onCancelAddDialog = () => {
    setAddDialog(false)
    setNewName("")
    setNewNameError("")
  }

  const onCancelUpdateDialog = () => {
    setEditDialog(false)
    setNewName("")
  }

  return (
    <Box sx={{marginTop: 2, display: "flex", gap: "10px", flexWrap: "wrap"}}>

      {employmentTypes.data?.employmentTypes.map(x =>
        <Paper sx={{minWidth: "300px", maxWidth: "300px"}} key={x.id}>

          <Box sx={{display: "flex", justifyContent: "space-between", padding: "10px"}}>
            <Box sx={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
              {x.name}
            </Box>
            <Badge badgeContent={4}>
              <PeopleIcon color="action" />
            </Badge>
          </Box>

          {auth.user?.role === UserRole.COMPANY_ADMIN &&
            <>
              <Divider/>

              <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: "5px"}}>
                <IconButton onClick={e => onOpenDeleteDialog(x)}>
                  <DeleteIcon fontSize={"small"}/>
                </IconButton>
                <IconButton onClick={e => onOpenEditDialog(x)}>
                  <EditIcon fontSize={"small"}/>
                </IconButton>
              </Box>
            </>
          }

        </Paper>
      )}

      {auth.user?.role === UserRole.COMPANY_ADMIN &&
        <>
          <Box sx={{ display: "flex", width: "100%"}}>
            <Button variant="outlined" onClick={_ => setAddDialog(true)}>+ Add</Button>
          </Box>

          <Dialog open={addDialog}>
            <DialogTitle>Add Employment Type</DialogTitle>
            <DialogContent sx={{width: "400px"}}>
              <TextField
                autoFocus
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                margin="normal"
                error={newNameError !== ''}
                helperText={newNameError}
                value={newName}
                onChange={e => setNewName(e.target.value)}
              />
            </DialogContent>
            <DialogActions sx={{padding: "10px 25px"}}>
              <Button onClick={onCancelAddDialog}>Cancel</Button>
              <Button variant="contained" onClick={onAdd}>Add</Button>
            </DialogActions>
          </Dialog>
        </>
      }

      <Dialog onClose={onCancelUpdateDialog} open={editDialog}>
        <DialogTitle>Update EmploymentType</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          <TextField
            autoFocus
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            margin="normal"
            error={newNameError !== ''}
            helperText={newNameError}
            value={newName}
            onChange={e => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={e => onCancelUpdateDialog()}>Cancel</Button>
          <Button variant="contained" onClick={() => onUpdate()}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialog}>
        <DialogTitle>Delete EmploymentType</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          Are you sure you want to delete '{selectedEmploymentType?.name ?? ''}'?
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={e => setDeleteDialog(false)}>No</Button>
          <Button variant="contained" color="error" onClick={onConfirmDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}