import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
  TextField
} from "@mui/material";
import {color} from "../color";
import * as React from "react";
import {useRef, useState} from "react";
import {roleToString, useDeactivateUser, useDeleteUser, User, UserRole, UserStatus} from "../api";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useAuth} from "../auth/AuthProvider";
import {Moment} from "moment";
import {DatePicker} from "@mui/x-date-pickers-pro";
import moment from "moment/moment";

export const PersonProfile = (props: {user: User, onUpdate: () => void}) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState(false)
  const profilePopoverBtnAnchorRef = useRef(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)

  const [deactivateDialog, setDeactivateDialog] = useState(false)
  const [deactivateUser] = useDeactivateUser()

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [deleteUser] = useDeleteUser()

  const headerStyle = {
    color: color.lightHeader,
    fontWeight: 600,
    fontSize: '14px',
    paddingRight: "50px"
  }

  const dataStyle = {
    padding: "10px",
    height: "44px"
  }

  const onCancelDeactivateDialog = () => {
    setDeactivateDialog(false)
    setEndDate(null)
  }

  const onDeactivate = () => {
    if (!endDate) {
      return
    }

    deactivateUser({
      variables: {
        userId: props.user.id,
        date: endDate?.format("YYYY-MM-DD")
      }
    }).then(response => {
      setDeactivateDialog(false)
      setEndDate(null)
      props.onUpdate()
    })
  }

  const onDelete = () => {
    deleteUser({
      variables: {
        userId: props.user.id
      }
    }).then(response => {
      setDeleteDialog(false)
      props.onUpdate()
      window.location.href = "/people/list"
    })
  }

  return (
    <Box>
      {auth.user?.role === UserRole.COMPANY_ADMIN &&
        <>
          <IconButton sx={{float: "right"}} onClick={() => setShowMenu(true)} ref={profilePopoverBtnAnchorRef}>
            <MoreVertIcon/>
          </IconButton>
          <Menu open={showMenu}
                anchorEl={profilePopoverBtnAnchorRef.current}
                onClose={() => setShowMenu(false)}>
            <MenuItem onClick={() => navigate("/people/person/" + props.user.id + "/profile/edit")}>Edit</MenuItem>
            {props.user.status !== UserStatus.INACTIVE &&
            <MenuItem onClick={() => {setDeactivateDialog(true); setShowMenu(false);}}>Deactivate</MenuItem>
            }
            <MenuItem onClick={() => {setDeleteDialog(true); setShowMenu(false);}}>Delete</MenuItem>
          </Menu>

          <Dialog onClose={onCancelDeactivateDialog} open={deactivateDialog}>
            <DialogTitle>Deactivate user</DialogTitle>
            <DialogContent sx={{width: "400px"}}>
              <DatePicker
                showToolbar={false}
                label="End date"
                inputFormat="YYYY-MM-DD"
                value={endDate}
                onChange={setEndDate}
                renderInput={(params) =>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    margin="normal"
                    size="small"
                    {...params}
                  />
                }
              />
            </DialogContent>
            <DialogActions sx={{padding: "10px 25px"}}>
              <Button onClick={_ => onCancelDeactivateDialog()}>Cancel</Button>
              <Button color="error" disabled={!endDate} variant="contained" onClick={onDeactivate}>Deactivate</Button>
            </DialogActions>
          </Dialog>

          <Dialog onClose={_ => setDeleteDialog(false)} open={deleteDialog}>
            <DialogTitle>Delete user</DialogTitle>
            <DialogContent sx={{width: "400px"}}>
              Are you sure you want to delete this user?
            </DialogContent>
            <DialogActions sx={{padding: "10px 25px"}}>
              <Button onClick={_ => setDeleteDialog(false)}>Cancel</Button>
              <Button color="error" variant="contained" onClick={onDelete}>Confirm</Button>
            </DialogActions>
          </Dialog>
        </>
      }

      <table>
        <tbody>
          <tr>
            <td style={headerStyle}>Full Name</td>
            <td style={dataStyle}>{props.user.fullName}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Title</td>
            <td style={dataStyle}>{props.user.title}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Email</td>
            <td style={dataStyle}>{props.user.email}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Phone number</td>
            <td style={dataStyle}>{props.user.phoneNumber}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Employment Type</td>
            <td style={dataStyle}>{props.user.employmentType?.name}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Location</td>
            <td style={dataStyle}>{props.user.location?.name}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Department</td>
            <td style={dataStyle}>{props.user.department?.name}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Team</td>
            <td style={dataStyle}>{props.user.team?.name}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Manager</td>
            <td style={dataStyle}>
              <Link underline="none" component={RouterLink} to={"/people/person/" + props.user.manager?.id + "/profile"}>
                {props.user.manager?.name}
              </Link>
            </td>
          </tr>
          <tr>
            <td style={headerStyle}>Start date</td>
            <td style={dataStyle}>{props.user.startDate}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Probation end date</td>
            <td style={dataStyle}>{props.user.probationEndDate}</td>
          </tr>
          {props.user.endDate &&
          <tr>
            <td style={headerStyle}>End date</td>
            <td style={dataStyle}>{props.user.endDate}</td>
          </tr>
          }
          {auth.user?.role === UserRole.COMPANY_ADMIN &&
          <tr>
            <td style={headerStyle}>Birth date</td>
            <td style={dataStyle}>{props.user.birthDate}</td>
          </tr>
          }
          {auth.user?.role !== UserRole.COMPANY_ADMIN &&
            <tr>
              <td style={headerStyle}>Birthday</td>
              <td style={dataStyle}>{props.user.birthDate ? moment(props.user.birthDate).format('MMMM DD') : ''}</td>
            </tr>
          }
          <tr>
            <td style={headerStyle}>Work Schedule</td>
            <td style={dataStyle}>{props.user.workSchedule.name}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Holidays Calendars</td>
            <td style={dataStyle}>{props.user.holidaysCalendars.map(x => x.name).join(', ')}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Time Off Policies</td>
            <td style={dataStyle}>{props.user.timeOffBalances.map(x => x.timeOffPolicy.name).join(', ')}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Access</td>
            <td style={dataStyle}>{roleToString(props.user.role)}</td>
          </tr>
        </tbody>
      </table>
    </Box>
  )
}