import {UserAddUpdate} from "./UserAddUpdate";
import {User} from "../api";
import {useNavigate} from "react-router-dom";

export const PersonProfileEdit = (props: {
  user: User,
  onComplete: (u: User) => void
}) => {
  const navigate = useNavigate()

  return <UserAddUpdate
    user={props.user}
    submitLabel={"Update"}
    onOk={props.onComplete}
    onCancel={() => navigate("/people/person/" + props.user.id + "/profile")}
  />
}