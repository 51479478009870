import {Avatar, Box, IconButton, Paper} from "@mui/material";
import Tab from "@mui/material/Tab";
import {Link as RouterLink, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import {PersonProfile} from "./PersonProfile";
import {color} from "../color";
import {SupportedImageTypes, uploadUserPhoto, UserRole, UserStatus, useUser, useUserAccess} from "../api";
import {PersonProfileEdit} from "./PersonProfileEdit";
import {PersonTimeOff} from "./PersonTimeOff";
import {PersonDocuments} from "./PersonDocuments";
import {useAuth} from "../auth/AuthProvider";
import {nameAbbreviation} from "../string-format-util";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {userStatusLabel} from "../style-util";
import {PersonOvertime} from "./PersonOvertime";
import {ReactComponent as OrgChartIcon} from "../icon/org-chart-icon.svg";
import {NavLink} from "../common/NavLink";

export const Person = (props: {basePath: string}) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { userId } = useParams()

  const inputFileRef = React.useRef<HTMLInputElement>(null)
  const user = useUser(userId!)
  const userAccess = useUserAccess(userId!)

  const canEdit = auth.user?.role === UserRole.COMPANY_ADMIN
    || (auth.user?.id && user.data?.user?.id && auth.user?.id === user.data?.user?.id)

  const isAdmin = auth.user?.role === UserRole.COMPANY_ADMIN

  if (!user.data?.user) {
    return <></>
  }

  const onClickSelectFile = () => {
    inputFileRef.current?.click()
  }

  const onSelectPhotoFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      uploadUserPhoto(user.data?.user?.id!!, file).then(() => {
        user.refetch()
      })
    }
  }

  const tabs = [
    <Tab
      key={"tab-0"}
      label="Profile"
      component={RouterLink}
      value={`${props.basePath}/${user.data?.user?.id}/profile`}
      to={`${props.basePath}/${user.data?.user?.id}/profile`}
    />
  ]

  if ((userAccess.data?.userAccess?.timeOffBalances?.length ?? 0) > 0) {
    tabs.push(
      <Tab
        key={"tab-1"}
        label="Time Off"
        component={RouterLink}
        value={`${props.basePath}/${user.data?.user?.id}/time-off`}
        to={`${props.basePath}/${user.data?.user?.id}/time-off`}
      />)
  }

  if (userAccess.data?.userAccess?.overtime?.accessType) {
    tabs.push(
      <Tab
        key={"tab-2"}
        label="Overtime"
        component={RouterLink}
        value={`${props.basePath}/${user.data?.user?.id}/overtime`}
        to={`${props.basePath}/${user.data?.user?.id}/overtime`}
      />)
  }

/*    tabs.push(
      <Tab
        key={"tab-2"}
        label="History"
        component={RouterLink}
        value={`${props.basePath}/${user.data?.user?.id}/history`}
        to={`${props.basePath}/${user.data?.user?.id}/history`}
      />)*/

  if (isAdmin) {
    tabs.push(
      <Tab
        key={"tab-3"}
        label="Documents"
        component={RouterLink}
        value={`${props.basePath}/${user.data?.user?.id}/documents`}
        to={`${props.basePath}/${user.data?.user?.id}/documents`}
      />)
  }

  const photo = canEdit ?
    <Box
      sx={{display: "flex", justifyContent: "center", position: "relative", width: 100, height: 100}}
      onClick={onClickSelectFile}
    >
      <input style={{display: "none"}}
             type="file"
             accept={SupportedImageTypes}
             onChange={onSelectPhotoFile}
             ref={inputFileRef}/>
      <Avatar
        sx={{
          width: "100%",
          height: "100%"
        }}
        src={user.data?.user?.photoUrl ? user.data?.user?.photoUrl + "?" + Date.now() : undefined}
        //src={newPhotoPreview as string}
      >
        {nameAbbreviation(user.data?.user?.fullName)}
      </Avatar>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          borderRadius: "50%",
          position: "absolute",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          zIndex: "9",
          opacity: "0",
          color: "rgb(255, 255, 255)",
          backgroundColor: "rgba(22, 28, 36, 0.64)",
          cursor: "pointer",
          "&:hover": {
            opacity: "0.75"
          }
        }}
      >
        <AddAPhotoIcon/>
      </Box>
    </Box>
    : <Box
      sx={{display: "flex", justifyContent: "center", position: "relative", width: 100, height: 100}}
    >
      <Avatar
        sx={{
          width: 100,
          height: 100
        }}
        src={user.data?.user?.photoUrl}
      >
        {nameAbbreviation(user.data?.user?.fullName)}
      </Avatar>
    </Box>

  return (
    <Box sx={{display: "flex"}}>
      <Box sx={{width: "100%"}}>
        <Paper sx={{padding: "0px 20px"}}>
          <Box sx={{display: "flex", paddingTop: "20px", paddingBottom: "5px", justifyContent: "space-between"}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              {photo}
              <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "15px"}}>
                <Box sx={{fontWeight: 600}}>{user.data?.user?.fullName}</Box>
                <Box sx={{color: color.lightHeader}}>{user.data?.user?.title}</Box>
                <Box sx={{height: "20px"}}>{userStatusLabel(user.data?.user?.status)}</Box>
              </Box>
            </Box>
            {user.data?.user?.status !== UserStatus.INACTIVE &&
            <Box sx={{cursor: "pointer"}}>
              <IconButton sx={{width: "35px", height: "35px"}}>
                <NavLink to={"/people/org-chart?userId=" + user.data?.user?.id}>
                  <OrgChartIcon/>
                </NavLink>
              </IconButton>
            </Box>
            }
          </Box>

          <Tabs
            value={location.pathname}
            //onChange={handleChange}
            variant="scrollable"
          >
            {tabs}
          </Tabs>
        </Paper>

        <Paper sx={{marginTop: 2}}>
          <Routes>
            <Route path="/profile" element={<PersonProfile user={user.data.user} onUpdate={() => user.refetch()}/>}/>
            <Route path="/profile/edit" element={
              <PersonProfileEdit
                user={user.data.user}
                onComplete={(u) => {
                  user.refetch()
                  navigate(`${props.basePath}/${u.id}/profile`)
                }}
              />
            }/>
            <Route path="/time-off" element={<PersonTimeOff user={user.data.user}/>}/>
            <Route path="/overtime" element={<PersonOvertime user={user.data.user}/>}/>
            {/*<Route path="/history" element={<PersonHistory/>}/>*/}
            <Route path="/documents" element={<PersonDocuments userId={user.data.user.id}/>}/>
          </Routes>
        </Paper>
      </Box>
    </Box>
  )
}