import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material"
import {
  TimeOffPolicyUser,
  useAddUserTimeOffPolicy,
  useDeleteUserTimeOffPolicy,
  User, UserStatus,
  useTimeOffPolicyUsers,
  useUsers
} from "../api";
import * as React from "react";
import {useState} from "react";
import {ApiError} from "../errors";
import {Link as RouterLink, useParams} from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {nameAbbreviation} from "../string-format-util";

export const TimeOffPolicyUsersList = () => {

  const {timeOffPolicyId} = useParams()

  const timeOffUsers = useTimeOffPolicyUsers(timeOffPolicyId!!)
  const users = useUsers(0, 10000)
  const [selectedItem, setSelectedItem] = useState<TimeOffPolicyUser | null>(null)

  const [deleteUserTimeOffPolicy] = useDeleteUserTimeOffPolicy()
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<Array<User>>([])
  const [addUserTimeOffPolicy] = useAddUserTimeOffPolicy()

  const onConfirmDelete = () => {
    if (!selectedItem) {
      return
    }

    deleteUserTimeOffPolicy({
      variables: {
        timeOffPolicyId: timeOffPolicyId,
        userId: selectedItem.id
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          //TODO
        }
      } else {
        timeOffUsers.refetch()
        setDeleteDialog(false)
      }
    })
  }

  const onOpenDeleteDialog = (item: TimeOffPolicyUser) => {
    setSelectedItem(item)
    setDeleteDialog(true)
  }

  const headerStyle = {
    color: colors.blueGrey[600],
    fontWeight: 600,
    fontSize: '16px',
  }

  const onCancelAddUsersDialog = () => {
    setOpenAddUserDialog(false)
    setSelectedUsers([])
  }

  const onAddUsers = () => {
    addUserTimeOffPolicy({
      variables: {
        timeOffPolicyId: timeOffPolicyId,
        userIds: selectedUsers.map(x => x.id)
      }
    }).then(response => {
      if (response.error) {
        /*const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNameError(e.message)
        }*/
      } else {
        setOpenAddUserDialog(false)
        timeOffUsers.refetch()
        setSelectedUsers([])
      }
    })
  }

  return (
    <Box sx={{marginTop: 2, display: "flex", gap: "10px", flexWrap: "wrap"}}>
      <Box sx={{width: "100%"}}>
        <Paper>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px"}}>
            <Box sx={headerStyle}>
              Users with '{timeOffUsers.data?.timeOffPolicy.name}' time off policy
            </Box>
          </Box>

          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Name</TableCell>
                    <TableCell variant="head" sx={{width: "100px"}}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeOffUsers.data?.timeOffPolicy?.users?.map(x => {
                    return (
                      <TableRow key={x.id}>
                        <TableCell>
                          <Box sx={{display: "flex", gap: "10px", alignItems: "center"}}>
                            <Avatar src={x.photoUrl} sx={{fontSize: "14px"}}>
                              {nameAbbreviation(x.fullName)}
                            </Avatar>
                            <Link component={RouterLink} to={"/people/person/" + x.id + "/profile"}>
                              {x.fullName}
                            </Link>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => onOpenDeleteDialog(x)}>
                            <DeleteOutlineIcon/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "15px"}}>
            <Box sx={headerStyle}>
            </Box>
            <Button variant="outlined" onClick={_ => setOpenAddUserDialog(true)}>+ Add Users</Button>
            <Dialog open={openAddUserDialog}>
              <DialogTitle>Add users</DialogTitle>
              <DialogContent sx={{width: "400px"}}>
                <Box sx={{display: "flex", flexDirection: "column", gap: "15px"}}>
                  <FormControl variant="outlined" fullWidth margin="normal" size="small">
                    <Autocomplete
                      multiple={true}
                      options={users.data?.users?.list?.filter(x => x.status !== UserStatus.INACTIVE && !timeOffUsers.data?.timeOffPolicy?.users.find(a => a.id === x.id)) || []}
                      value={selectedUsers}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={option => option.fullName}
                      onChange={(e, value) => setSelectedUsers(value)}
                      renderInput={(params) => <TextField {...params} label="Users" />}
                    />
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions sx={{padding: "10px 25px"}}>
                <Button onClick={onCancelAddUsersDialog}>Cancel</Button>
                <Button variant="contained" disabled={selectedUsers.length === 0} onClick={onAddUsers}>Add</Button>
              </DialogActions>
            </Dialog>
          </Box>

        </Paper>
      </Box>

      <Dialog open={deleteDialog}>
        <DialogTitle>Delete user</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          Are you sure you want to delete '{selectedItem?.fullName ?? ''}'?
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={_ => setDeleteDialog(false)}>No</Button>
          <Button variant="contained" color="error" onClick={onConfirmDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}