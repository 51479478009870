import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {ApiError} from "../errors";
import {
  useAddDepartment,
  useAddEmploymentType,
  useAddLocation,
  useAddTeam,
  useAddUser,
  useDepartments,
  useEmploymentTypes,
  useHolidaysCalendars,
  useLocations,
  User,
  useTeams,
  useTimeOffPolicies,
  useUpdateUser,
  useUsers,
  useWorkSchedules
} from "../api";
import {CreatableSelect} from "../common/CreatableSelect";
import {DatePicker} from "@mui/x-date-pickers-pro";
import {Moment} from "moment/moment";
import moment from "moment";
import {useToast} from "../common/Toast";

export const UserAddUpdate = (props: {
  submitLabel: string,
  user?: User,
  onOk?: (user: User) => void,
  onCancel?: () => void
}) => {

  const edit = props?.user !== undefined

  const toast = useToast()
  const people = useUsers(0, 1000)
  const departments = useDepartments()
  const teams = useTeams()
  const locations = useLocations()
  const employmentTypes = useEmploymentTypes()
  const timeOffPolicies = useTimeOffPolicies()
  const holidaysCalendars = useHolidaysCalendars()
  const workSchedules = useWorkSchedules()

  const [fullName, setFullName] = useState(props.user?.fullName ?? "")
  const [fullNameError, setFullNameError] = useState("")

  const [title, setTitle] = useState(props.user?.title ?? "")

  const [phoneNumber, setPhoneNumber] = useState(props.user?.phoneNumber ?? "")

  const [email, setEmail] = useState(props.user?.email ?? "")
  const [emailError, setEmailError] = useState("")

  const [startDate, setStartDate] = useState<Moment | null>(props.user?.startDate ? moment(props.user.startDate) : null)
  const [startDateError, setStartDateError] = useState("")

  const [birthDate, setBirthDate] = useState<Moment | null>(props.user?.birthDate ? moment(props.user.birthDate) : null)

  const [locationId, setLocationId] = useState(props.user?.location?.id ?? "")
  const [locationError, setLocationError] = useState("")

  const [employmentTypeId, setEmploymentTypeId] = useState(props.user?.employmentType?.id ?? "")
  const [employmentTypeError, setEmploymentTypeError] = useState("")

  const [role, setRole] = useState(props.user?.role ?? "USER")

  const [managerId, setManagerId] = useState(props.user?.manager?.id ?? "")

  const [departmentId, setDepartmentId] = useState(props.user?.department?.id ?? "")
  const [departmentError, setDepartmentError] = useState("")

  const [teamId, setTeamId] = useState(props.user?.team?.id ?? "")
  const [teamError, setTeamError] = useState("")

  const [timeOffPolicyIds, setTimeOffPolicyIds] = useState<string[]>(props.user?.timeOffBalances?.map(x => x.timeOffPolicy.id) ?? [])
    //useState(props.user?.timeOffPolicies?.map(x => x.id) ?? [])
  useEffect(() => {
    if (!edit && timeOffPolicies.data) {
      setTimeOffPolicyIds(timeOffPolicies.data.timeOffPolicies.map(x => x.id))
    }
  }, [timeOffPolicies.data])

  const [holidaysCalendarsIds, setHolidaysCalendarsIds] = useState<string[]>(props.user?.holidaysCalendars?.map(x => x.id) ?? [])
/*  useEffect(() => {
    if (!edit && holidaysCalendars.data) {
      setHolidaysCalendarsIds(holidaysCalendars.data.holidaysCalendars.map(x => x.id))
    }
  }, [holidaysCalendars.data])*/

  const [workScheduleId, setWorkScheduleId] = useState<string>(props.user?.workSchedule?.id ?? "")
  const [workScheduleError, setWorkScheduleError] = useState("")

  const [probationEndDate, setProbationEndDate] = useState<Moment | null>(props.user?.probationEndDate ? moment(props.user?.probationEndDate) : null)
  const [probationEndDateNotificationUserIds, setProbationEndDateNotificationUserIds] = useState<Array<string>>(props.user?.probationEndDateNotificationUsers?.map(x => x.id) ?? [])

  useEffect(() => {
    if (probationEndDate && probationEndDateNotificationUserIds && probationEndDateNotificationUserIds.length === 0 && managerId) {
      setProbationEndDateNotificationUserIds([managerId])
    } else if (!probationEndDate) {
      setProbationEndDateNotificationUserIds([])
    }
  }, [managerId, probationEndDate])

  const [updateUser] = useUpdateUser()
  const [addUser] = useAddUser()
  const [addDepartment] = useAddDepartment()
  const [addTeam] = useAddTeam()
  const [addLocation] = useAddLocation()
  const [addEmploymentType] = useAddEmploymentType()

  const onInviteUser = () => {
    if (!startDate) {
      setStartDateError("Start date cannot be empty.")
      return
    }

    addUser({
      variables: {
        fullName: fullName,
        title: title !== '' ? title : null,
        email: email,
        phoneNumber: phoneNumber,
        locationId: locationId !== '' ? locationId : null,
        role: role,
        managerId: managerId !== '' ? managerId : null,
        departmentId: departmentId !== '' ? departmentId : null,
        employmentTypeId: employmentTypeId !== '' ? employmentTypeId : null,
        teamId: teamId !== '' ? teamId : null,
        timeOffPolicyIds: timeOffPolicyIds,
        startDate: startDate?.format("YYYY-MM-DD"),
        birthDate: birthDate?.format("YYYY-MM-DD"),
        holidaysCalendarsIds: holidaysCalendarsIds,
        workScheduleId: workScheduleId,
        probationEndDate: probationEndDate?.format("YYYY-MM-DD"),
        probationEndDateNotificationUserIds: probationEndDateNotificationUserIds
      }
    }).then(response => {
      if (response.error) {
        const e1 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.EmailAlreadyRegistered)
        if (e1) {
          setEmailError(e1.message)
        }
        const e2 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidEmail)
        if (e2) {
          setEmailError(e2.message)
        }
        const e3 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidFullName)
        if (e3) {
          setFullNameError(e3.message)
        }
        const e4 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidStarDate)
        if (e4) {
          setStartDateError(e4.message)
        }
        const e5 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidWorkSchedule)
        if (e5) {
          setWorkScheduleError(e5.message)
        }
      } else {
        toast.info("User added")
        props.onOk && response.data?.addUser && props.onOk(response.data?.addUser)
      }
    })
  }

  const onUpdateUser = () => {
    if (!props.user) {
      return
    }

    updateUser({
      variables: {
        id: props.user.id,
        role: role,
        fullName: fullName,
        title: title !== '' ? title : null,
        phoneNumber: phoneNumber || null,
        locationId: locationId !== '' ? locationId : null,
        managerId: managerId !== '' ? managerId : null,
        departmentId: departmentId !== '' ? departmentId : null,
        employmentTypeId: employmentTypeId !== '' ? employmentTypeId : null,
        teamId: teamId !== '' ? teamId : null,
        timeOffPolicyIds: timeOffPolicyIds,
        startDate: startDate?.format("YYYY-MM-DD"),
        birthDate: birthDate?.format("YYYY-MM-DD"),
        workScheduleId: workScheduleId,
        holidaysCalendarsIds: holidaysCalendarsIds,
        probationEndDate: probationEndDate?.format("YYYY-MM-DD"),
        probationEndDateNotificationUserIds: probationEndDateNotificationUserIds,
      }
    }).then(response => {
      if (response.error) {
        const e3 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidFullName)
        if (e3) {
          setFullNameError(e3.message)
        }
        const e4 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidStarDate)
        if (e4) {
          setStartDateError(e4.message)
        }
      } else {
        toast.info("Profile updated")
        props.onOk && response.data?.updateUser && props.onOk(response.data?.updateUser)
      }
    })
  }

  const onSubmit = () => {
    if (edit) {
      onUpdateUser()
    } else {
      onInviteUser()
    }
  }

  const onAddNewDepartment = (newDepartmentName: string, onOk: () => void) => {
    addDepartment({
      variables: {
        name: newDepartmentName
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setDepartmentError(e.message)
        }
      } else {
        departments.refetch()
        setDepartmentError("")
        toast.info("Department added")
        onOk()
      }
    })
  }

  const onAddNewTeam = (newTeamName: string, onOk: () => void) => {
    addTeam({
      variables: {
        name: newTeamName
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setTeamError(e.message)
        }
      } else {
        teams.refetch()
        setTeamError("")
        toast.info("Team added")
        onOk()
      }
    })
  }

  const onAddNewLocation = (newLocationName: string, onOk: () => void) => {
    addLocation({
      variables: {
        name: newLocationName
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setLocationError(e.message)
        }
      } else {
        locations.refetch()
        toast.info("Location added")
        setLocationError("")
        onOk()
      }
    })
  }

  const onAddNewEmploymentType = (name: string, onOk: () => void) => {
    addEmploymentType({
      variables: {
        name: name
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setEmploymentTypeError(e.message)
        }
      } else {
        employmentTypes.refetch()
        toast.info("Employment type added")
        setEmploymentTypeError("")
        onOk()
      }
    })
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            autoFocus
            label="Full Name"
            type="text"
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            error={fullNameError !== ''}
            helperText={fullNameError}
            required
            value={fullName}
            onChange={e => setFullName(e.target.value)}
          />
          <TextField
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          <TextField
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            error={emailError !== ''}
            helperText={emailError}
            disabled={edit}
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <DatePicker
            showToolbar={false}
            label="Start date"
            inputFormat="YYYY-MM-DD"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) =>
              <TextField
                {...params}
                fullWidth
                required
                error={startDateError !== ''}
                helperText={startDateError}
                variant="outlined"
                margin="normal"
                size="small"
              />
            }
          />
          <CreatableSelect
            label="Employment Type"
            value={employmentTypeId}
            values={employmentTypes.data?.employmentTypes ?? []}
            onChange={setEmploymentTypeId}
            onAdd={onAddNewEmploymentType}
            addError={employmentTypeError}
          />
          <CreatableSelect
            label="Location"
            value={locationId}
            values={locations.data?.locations ?? []}
            onChange={setLocationId}
            onAdd={onAddNewLocation}
            addError={locationError}
          />
          <CreatableSelect
            label="Department"
            value={departmentId}
            values={departments.data?.departments ?? []}
            onChange={setDepartmentId}
            onAdd={onAddNewDepartment}
            addError={departmentError}
          />
          <CreatableSelect
            label="Team"
            value={teamId}
            values={teams.data?.teams ?? []}
            onChange={setTeamId}
            onAdd={onAddNewTeam}
            addError={teamError}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            showToolbar={false}
            label="Birth date"
            inputFormat="YYYY-MM-DD"
            value={birthDate}
            onChange={setBirthDate}
            renderInput={(params) =>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                {...params}
              />
            }
          />
          <TextField
            label="Phone number"
            type="text"
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          />
          <FormControl variant="outlined" fullWidth margin="normal" size="small">
            <InputLabel>Manager</InputLabel>
            <Select
              value={managerId}
              label="Manager"
              onChange={e => setManagerId(e.target.value)}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {/* TODO: disable manager cycle */}
              {people.data?.users.list.filter(x => x.id !== props.user?.id).map(x =>
                <MenuItem key={x.id} value={x.id}>{x.fullName}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="normal" size="small" required>
            <InputLabel>Work Schedule</InputLabel>
            <Select
              value={workScheduleId}
              label="Work Schedule"
              onChange={e => setWorkScheduleId(e.target.value as string)}
              error={workScheduleError !== ''}
            >
              {workSchedules.data?.workSchedules.map(x =>
                <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="normal" size="small">
            <InputLabel>Holidays Calendars</InputLabel>
            <Select
              value={holidaysCalendarsIds}
              label="Holidays Calendars"
              onChange={e => setHolidaysCalendarsIds(e.target.value as string[])}
              multiple={true}
              renderValue={(selected) => holidaysCalendars.data?.holidaysCalendars?.filter(a => selected.includes(a.id))?.map(x => x.name)?.join(', ') || ''}
            >
              {holidaysCalendars.data?.holidaysCalendars.map(x =>
                <MenuItem key={x.id} value={x.id} dense={true}>
                  <Checkbox size={"small"} checked={holidaysCalendarsIds.indexOf(x.id) > -1} />
                  <ListItemText primary={x.name} />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="normal" size="small">
            <InputLabel>Time Off Policy</InputLabel>
            <Select
              value={timeOffPolicyIds}
              label="Time Off Policy"
              onChange={e => setTimeOffPolicyIds(e.target.value as string[])}
              multiple={true}
              renderValue={(selected) => timeOffPolicies.data?.timeOffPolicies?.filter(a => selected.includes(a.id))?.map(x => x.name)?.join(', ') || ''}
            >
              {timeOffPolicies.data?.timeOffPolicies.map(x =>
                <MenuItem key={x.id} value={x.id} dense={true}>
                  <Checkbox size={"small"} checked={timeOffPolicyIds.indexOf(x.id) > -1} />
                  <ListItemText primary={x.name} />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="normal" size="small">
            <InputLabel>Access</InputLabel>
            <Select
              value={role}
              label="Access"
              onChange={e => setRole(e.target.value as string)}
            >
              <MenuItem value={'USER'}>User</MenuItem>
              <MenuItem value={'COMPANY_ADMIN'}>Admin</MenuItem>
            </Select>
          </FormControl>
          <DatePicker
            showToolbar={false}
            label="Probation period end date"
            inputFormat="YYYY-MM-DD"
            value={probationEndDate}
            onChange={setProbationEndDate}
            renderInput={(params) =>
              <TextField
                {...params}
                fullWidth
                //error={startDateError !== ''}
                //helperText={startDateError}
                variant="outlined"
                margin="normal"
                size="small"
              />
            }
          />
          {probationEndDate &&
            <FormControl variant="outlined" fullWidth margin="normal" size="small">
              <InputLabel>Notify people about probation period end</InputLabel>
              <Select
                value={probationEndDateNotificationUserIds}
                label="Notify people about probation period end"
                onChange={e => setProbationEndDateNotificationUserIds(e.target.value as string[])}
                multiple={true}
                renderValue={(selected) => people.data?.users.list?.filter(a => selected.includes(a.id))?.map(x => x.fullName)?.join(', ') || ''}
              >
                {people.data?.users.list?.map(x =>
                  <MenuItem key={x.id} value={x.id} dense={true}>
                    <Checkbox size={"small"} checked={probationEndDateNotificationUserIds.indexOf(x.id) > -1} />
                    <ListItemText primary={x.fullName} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          }
        </Grid>
      </Grid>
      <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}>
        <Button variant="text" sx={{marginRight: 2}} onClick={() => props.onCancel && props.onCancel()}>Cancel</Button>
        <Button variant="contained" onClick={onSubmit} disabled={
          startDate === null || startDate === undefined
          || fullName === null || fullName === '' || fullName === undefined
          || email === null || email === '' || email === undefined
          || workScheduleId === null || workScheduleId === '' || workScheduleId === undefined}>{props.submitLabel}</Button>
      </Box>
    </Box>
  )
}