
export const groupBy = <K, V>(array: V[], grouper: (item: V) => K): Map<K, V[]> =>
  array.reduce((store, item) => {
    const key = grouper(item);
    if (store.has(key)) {
      store.get(key)?.push(item)
    } else {
      store.set(key, [item])
    }
    return store
  }, new Map<K, V[]>());

export const transformMap = <K, V, R>(
  source: Map<K, V>,
  transformer: (value: V, key: K) => R
): Map<K, R> => new Map(
  Array.from(source, v => [v[0], transformer(v[1], v[0])])
);

export const groupByAndMap = <T, K, R>(
  array: T[],
  grouper: (x: T) => K,
  mapper: (x: T[]) => R
): Map<K, R> => {
  let groups = groupBy(array, grouper)
  return transformMap(groups, value => mapper(value))
};