import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import * as React from "react";
import {useState} from "react";
import {addUserDocument, getUserDocumentLink, useDeleteUserDocument, UserDocument, useUserDocuments} from "../api";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {ApiError} from "../errors";
import {useToast} from "../common/Toast";

export const PersonDocuments = (props: {userId: string}) => {
  const toast = useToast()
  const userDocuments = useUserDocuments(props.userId)
  const [selectedItem, setSelectedItem] = useState<UserDocument | null>(null)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [deleteUserDocument] = useDeleteUserDocument()

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      addUserDocument(props.userId, file).then(() => {
        userDocuments.refetch()
        toast.info("Document added")
      })
    }
  }

  const onOpenDeleteDialog = (item: UserDocument) => {
    setSelectedItem(item)
    setDeleteDialog(true)
  }

  const onConfirmDelete = () => {
    if (!selectedItem) {
      return
    }

    deleteUserDocument({
      variables: {
        id: selectedItem.id
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          //TODO
        }
      } else {
        userDocuments.refetch()
        setDeleteDialog(false)
      }
    })
  }

  const onDownload = (item: UserDocument) => {
    getUserDocumentLink(props.userId, item.id).then(url => {
      window.open(url, '_blank')
    })
  }

  return (
    <Box sx={{marginTop: 2, display: "flex", gap: "10px", width: "100%", flexDirection: "column"}}>

      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Name</TableCell>
                <TableCell variant="head" sx={{width: "100px"}}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userDocuments.data?.userDocuments?.map(x => {
                return (
                  <TableRow key={x.id}>
                    <TableCell>
                      <Box sx={{display: "flex", gap: "10px", alignItems: "center"}}>
                        <Link sx={{cursor: "pointer"}} onClick={_ => onDownload(x)}>
                          {x.name}
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => onOpenDeleteDialog(x)}>
                        <DeleteOutlineIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{display: "flex", justifyContent: "flex-end"}}>
        <Button variant="outlined" component="label">
          + Add
          <input style={{display: "none"}}
                 type="file"
                 accept="*/*"
                 onChange={onSelectFile}/>
        </Button>
      </Box>

      <Dialog open={deleteDialog}>
        <DialogTitle>Delete user</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          Are you sure you want to delete '{selectedItem?.name ?? ''}'?
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={_ => setDeleteDialog(false)}>No</Button>
          <Button variant="contained" color="error" onClick={onConfirmDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}