import {useEffect, useState} from "react";
import {Box, Button, Container, CssBaseline, Divider, Grid, Link, TextField} from "@mui/material";
import {ReactComponent as GoogleIcon} from "../icon/google-icon.svg";
import {useQuery} from "graphql-hooks";
import {grey} from "@mui/material/colors";
import {Spinner} from "../common/Spinner";
import {Email} from "@mui/icons-material";
import {useRegisterUser} from "../api";
import {ApiError} from "../errors";
import {onEnter} from "../event-util";

const GOOGLE_SSO_URL_QUERY = `
  query googleSSO($operation: SsoOperation!) {
    googleSSO(operation: $operation)
  }
`;

interface GoogleSsoResponse {
  googleSSO: string
}

export const RegisterPage = () => {

  const {loading, error, data} = useQuery<GoogleSsoResponse>(GOOGLE_SSO_URL_QUERY, {
    variables: {
      operation: 'REGISTRATION'
    }
  })
  const [googleSsoUrl, setGoogleSsoUrl] = useState("")

  useEffect(() => {
    if (data) {
      setGoogleSsoUrl(data.googleSSO)
    } else if (error) {
      console.error(error)
    }
  }, [loading])

  const [submitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const [register] = useRegisterUser()

  const onSubmit = () => {
    register({
      variables: {
        email: email,
        password: password
      }
    })
    .then(response => {
        /*if (response.error) {
          const e1 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.EmailAlreadyRegistered)
          if (e1) {
            setEmailError(e1.message)
          }
          const e2 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidEmail)
          if (e2) {
            setEmailError(e2.message)
          }
          const e3 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidFullName)
          if (e3) {
            setFullNameError(e3.message)
          }
          const e4 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidStarDate)
          if (e4) {
            setStartDateError(e4.message)
          }
          const e5 = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.InvalidWorkSchedule)
          if (e5) {
            setWorkScheduleError(e5.message)
          }
        } else {
          props.onOk && response.data?.addUser && props.onOk(response.data?.addUser)
        }*/

      setSubmitted(true)
    })
  }

  if (!googleSsoUrl) {
    return <Spinner/>
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          height: "100vh",
          display: "flex"
        }}
      >
        <Box
          sx={{
            margin: "auto",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: "400px"
          }}
        >
          {!submitted &&
            <>
              <Button sx={{color: "primary.main", width: "100%"}} variant="outlined" href={googleSsoUrl}>
                <GoogleIcon style={{marginRight: "10px"}}/> Sign up with Google
              </Button>

              <Divider sx={{width: "100%", marginTop: 3, color: grey[500], fontSize: "12px"}}>OR</Divider>

              <Box sx={{mt: 1, width: "100%"}}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  size="small"
                  value={email}
                  onChange={x => setEmail(x.target.value)}
                  onKeyDown={onEnter(onSubmit)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  size="small"
                  value={password}
                  onChange={x => setPassword(x.target.value)}
                  onKeyDown={onEnter(onSubmit)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{mt: 3, mb: 2}}
                  onClick={onSubmit}
                >
                  Register
                </Button>
                <Grid container>
                  <Grid item>
                    <Link href="/login" variant="body2">
                      Already have an account? Log in
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </>
          }

          {submitted &&
            <Box>
              <Email fontSize="large"/>
              Please check the email to complete registration.
            </Box>
          }

        </Box>
      </Box>
    </Container>
  )
}