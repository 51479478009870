import React, {forwardRef} from "react";
import {NavLink as NavLinkBase, NavLinkProps} from "react-router-dom";


export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={(x) => x.isActive ? (props.className as string) + ' Mui-selected' : (props.className as string)}
  />
));