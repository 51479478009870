import React from 'react';
import {Box, CssBaseline} from "@mui/material";
import {Navigate, Route, Routes} from 'react-router-dom';
import {useAuth} from "./auth/AuthProvider";
import {LoginPage} from "./auth/LoginPage";
import {Main} from "./layout/Main";
import {RegisterPage} from "./auth/RegisterPage";
import {CompanySetup} from "./auth/CompanySetup";
import {PrivacyPolicyPage} from "./PrivacyPolicyPage";
import {LandingPage} from "./LandingPage";
import {GlobalScopeProvider} from "./auth/GlobalScopeProvider";
import {ToastProvider} from "./common/Toast";
import {Spinner} from "./common/Spinner";
import {PasswordResetRequestPage} from "./auth/PasswordResetRequestPage";
import {RegistrationVerificationPage} from "./auth/RegistrationVerificationPage";
import {PasswordResetPage} from "./auth/PasswordResetPage";


const App = () => {

  const auth = useAuth()

  if (!auth.loaded) {
    return <Spinner/>
  }

  return (
    <Box>
      <CssBaseline/>
      <Routes>
        {auth.loggedIn &&
          <Route path="/company-registration" element={<CompanySetup/>}/>
        }
        {auth.loggedIn && !auth.user?.company &&
          <Route path="/*" element={<CompanySetup/>}/>
        }
        {auth.loggedIn && auth.user?.company &&
          <Route path="/*" element={
            <GlobalScopeProvider>
              <ToastProvider>
                <Main/>
              </ToastProvider>
            </GlobalScopeProvider>
          }/>
        }
        {!auth.loggedIn &&
          <>
            <Route path="/" element={<LandingPage/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/registration" element={<RegisterPage/>}/>
            <Route path="/password-reset-request" element={<PasswordResetRequestPage/>}/>
            <Route path="/password-reset" element={<PasswordResetPage/>}/>
            <Route path="/registration-verification" element={<RegistrationVerificationPage/>}/>
            <Route path="/*" element={<Navigate to="/login" replace/>}/>
          </>
        }
      </Routes>
    </Box>
  );
}

export default App;
