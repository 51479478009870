import {colors} from "@mui/material";
import * as React from "react";
import {color} from "./color";
import {UserStatus} from "./api";

export const headerStyle = {
  color: colors.blueGrey[600],
  fontWeight: 600,
  fontSize: '14px',
}

export enum StatusColor {
  GREEN,
  BLUE,
  RED,
  ORANGE
}

export const greenLabel = (text: string) => {
  return statusLabel(text, StatusColor.GREEN)
}

export const blueLabel = (text: string, color: StatusColor) => {
  return statusLabel(text, StatusColor.BLUE)
}

export const redLabel = (text: string, color: StatusColor) => {
  return statusLabel(text, StatusColor.RED)
}

export const statusLabel = (text: string, statusColor: StatusColor) => {
  let clr = ''
  let bgColor = ''

  if (statusColor === StatusColor.GREEN) {
    clr = color.success
    bgColor = color.successBackground
  } else if (statusColor === StatusColor.BLUE) {
    clr = color.pending
    bgColor = color.pendingBackground
  } else if (statusColor === StatusColor.RED) {
    clr = color.error
    bgColor = color.errorBackground
  } else if (statusColor === StatusColor.ORANGE) {
    clr = color.warning
    bgColor = color.warningBackground
  }

  return (
    <span style={{
      height: "18px",
      minWidth: "22px",
      lineHeight: "0",
      borderRadius: "6px",
      alignItems: "center",
      whiteSpace: "nowrap",
      display: "inline-flex",
      justifyContent: "center",
      padding: "0px 8px",
      fontSize: "10px",
      color: clr,
      backgroundColor: bgColor,
      fontWeight: "700",
      textTransform: "capitalize"
    }}>
      {text}
    </span>
  )
}

export const userStatusLabel = (status: UserStatus) => {
  let clr = ''
  let bgColor = ''

  if (status === UserStatus.ACTIVE) {
    clr = color.success
    bgColor = color.successBackground
  } else if (status === UserStatus.INVITED) {
    clr = color.pending
    bgColor = color.pendingBackground
  } else if (status === UserStatus.INACTIVE) {
    clr = color.disabled
    bgColor = color.disabledBackground
  }

  return (
    <span style={{
      height: "18px",
      minWidth: "22px",
      lineHeight: "0",
      borderRadius: "6px",
      alignItems: "center",
      whiteSpace: "nowrap",
      display: "inline-flex",
      justifyContent: "center",
      padding: "0px 8px",
      fontSize: "10px",
      color: clr,
      backgroundColor: bgColor,
      fontWeight: "700",
      textTransform: "capitalize"
    }}>
      {status}
    </span>
  )
}

export const paperItemStyle = {
  border: "1px solid " + colors.grey[300],
  borderRadius: "10px",
  padding: "15px",
  width: "200px",
  maxWidth: "200px",
  minWidth: "200px",
}