import React, {useEffect, useState} from 'react';
import {Company, useCompany} from "../api";


class GlobalScope {
  company?: Company
  refreshCompany: () => void = () => {};
}

const GlobalScopeContext = React.createContext<GlobalScope>(new GlobalScope());

export const useGlobalScope = () => {
  return React.useContext(GlobalScopeContext);
}

export const GlobalScopeProvider = (props: { children: React.ReactNode }) => {

  const init = new GlobalScope()
  const [globalScope, setGlobalScope] = useState<GlobalScope>(init)

  const company = useCompany()

  useEffect(() => {
    if (company.data) {
      setGlobalScope(x => ({
        ...x,
        company: company.data?.company
      }))
    }
  }, [company.data])

  init.refreshCompany = () => {
    company.refetch()
  }

  return (
    <GlobalScopeContext.Provider value={globalScope}>
      {props.children}
    </GlobalScopeContext.Provider>
  );
};