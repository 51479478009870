

export const color = {
  //rootBackground: "#00322c08",
  rootBackground: "white",

  lightHeader: "#85a09d",

  success: "rgb(34, 154, 22)",
  successBackground: "rgba(84, 214, 44, 0.16)",

  pending: "#0288d1",
  pendingBackground: "#e1f5fe",

  error: "rgb(183, 29, 24)",
  errorBackground: "rgba(255, 86, 48, 0.16)",

  warning: "#ad945d",
  warningBackground: "#faeeb8",

  disabled: "rgb(115,110,110)",
  disabledBackground: "rgb(239 239 239)",

  selectedMenuItemBackground: "#b6efe9"
}