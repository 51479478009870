import {useLayoutEffect, useRef} from "react";
import {OrgChart as D3OrgChart} from 'd3-org-chart';
import {OrgChartItem, useOrgChart} from "../api";
import {HierarchyNode} from "d3-hierarchy";
import {nameAbbreviation} from "../string-format-util";

import "./OrgChart.css"
import {useAuth} from "../auth/AuthProvider";
import {useSearchParams} from "react-router-dom";

export const OrgChart = () => {
  const [searchParams, _] = useSearchParams();
  const userId = searchParams.get("userId")
  const {user} = useAuth()
  const d3Container = useRef(null);

  const orgChartData = useOrgChart()
  let chart: D3OrgChart<any> | null = null

  useLayoutEffect(() => {
    if (orgChartData.data && d3Container.current) {
      if (!chart) {
        chart = new D3OrgChart();
      }

      const width = 200
      const height = 120

      chart = chart
        .container(d3Container.current)
        .data(orgChartData.data.orgChart.map(x => {
          (x as any)._centered = x.id === user?.id;
          (x as any)._expanded = x.id === user?.id;
          return x
        }))
        .nodeHeight((_: any) => height)
        .nodeWidth((_: any) => width)
        .childrenMargin((d) => 35)
        .compactMarginBetween((d) => 35)
        .compactMarginPair((d) => 50)
        .neightbourMargin((a, b) => 50)
        .siblingsMargin((d) => 50)
        .buttonContent(({node, state}) => {
          return `
            <div style="text-align: center;min-width:22px;min-height:22px;color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9;">
              <span style="font-size:9px">
                ${
                  node.children
                  ? `<i class="fas fa-angle-up"></i>`
                  : `<i class="fas fa-angle-down"></i>`
                }
              </span>
              ${node.data._directSubordinates}
            </div>`;
        })
        .linkUpdate(function (d: any, i, arr) {
          // @ts-ignore
          d3.select(this)
            // @ts-ignore
            .attr('stroke', (d) => d.data._upToTheRootHighlighted ? '#152785' : '#E4E2E9')
            // @ts-ignore
            .attr('stroke-width', (d) => d.data._upToTheRootHighlighted ? 5 : 1);

          if (d.data._upToTheRootHighlighted) {
            // @ts-ignore
            d3.select(this).raise();
          }
        })
        .nodeId(x => x.id)
        .parentNodeId(x => x.managerId)
        .nodeContent(function (d: HierarchyNode<OrgChartItem>, i, arr, state) {
          let shortName = nameAbbreviation(d.data.fullName)

          let img =
            `<img 
                src="${d.data.managerId ? d.data.photoUrl: ''}" 
                style="object-fit: cover; border-radius:100px; width:50px; height:50px;" 
            />`

          if (!d.data.photoUrl) {
            img =
              `
                <div
                    style="border-radius:100px; width:50px; height:50px;display:flex; justify-content:center; align-items:center; background-color:#bdbdbd; color:white; font-size:14px"
                    >
                      ${shortName || ""}    
                </div>
              `

            if (d.data.id === '00000000-0000-0000-0000-000000000000') {
              img = ""
            }
          }

          let link = `<a class="highlight-link" href=${"/people/person/" + d.data.id + "/profile"}>
            ${d.data.fullName}
          </a>`

          if (d.data.id === '00000000-0000-0000-0000-000000000000') {
            link = d.data.fullName
          }

          return `
            <div style="overflow: hidden; padding:10px; background-color:white; width:${width}px; height:${height}px; border-radius:10px; border: 1px solid #E4E2E9; box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px; display: flex; flex-direction: column; align-items: center;">                            
              ${img}
              <div style="font-size: 14px; display: flex; flex-direction: column; align-items: center; margin: auto; text-align: center;">
                <div style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis; width:180px;">
                  ${link}
                </div>
                <div title="${d.data.title ?? ''}" style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis; width:180px; font-weight:700; color:gray; font-size:12px; margin-top:5px;">
                    ${d.data.title ?? ''}
                </div>
              </div>
           </div>`;
        })
        .render();

      expandHierarchy(userId ?? user?.id!!, chart)
    }
  }, [orgChartData.data, d3Container.current]);

  const expandHierarchy = (id: string, chart: D3OrgChart<any>) => {
    const ids = collectHierarchyIds(id)
    ids.forEach(id =>
      chart.setExpanded(id)
    )

    chart.setCentered(id)
    chart.render()
  }

  const collectHierarchyIds = (parentId: string): Array<string> => {
    const ids = orgChartData.data?.orgChart.filter(x => x.managerId === parentId).map(x => x.id) || []
    const nextLevel = Array<string>()
    ids.forEach(x => {
      nextLevel.concat(collectHierarchyIds(x))
    })

    return ids.concat(nextLevel)
  }

  return <div ref={d3Container}/>
}