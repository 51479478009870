import {useMutation} from "graphql-hooks";
import {ChangeEvent, useState} from "react";
import {Box, Button, Container, CssBaseline, Grid, TextField} from "@mui/material";
//import {grey} from "@mui/material/color";
import {ApiError} from "../errors";
import {Chat} from "../chat/Chat";
import {onEnter} from "../event-util";


const REGISTER_COMPANY_MUTATION = `
  mutation registerCompany($companyName: String!) {
    registerCompany(companyName: $companyName)
  }
`;

export const CompanySetup = () => {

  //const [site, setSite] = useState("")
  //const [siteError, setSiteError] = useState("")
  const [companyName, setCompanyName] = useState("")
  //const [companyNameError, seCompanyNameError] = useState("")

  const [registerCompany] = useMutation<{}>(REGISTER_COMPANY_MUTATION)

  /*const onSiteNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setSite(e.target.value)
    if (!/^[a-z]+$/.test(e.target.value)) {
      setSiteError("Please use only a-z for site name.")
    } else {
      setSiteError("")
    }
  }*/

  const onCompanyNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value)

    /* if (!/^[a-z]+$/.test(e.target.value)) {
       setCompanyName("Please use only a-z for site name")
     } else {
       setCompanyName("")
     }*/
  }

  const onSetupCompany = () => {
    registerCompany({
      variables: {
        //site: site,
        companyName: companyName
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.BadCompanyName)
        if (e) {
          //setSiteError(e.message)
        }
      } else {
        window.location.href = "/"
      }
    })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          height: "100vh",
          display: "flex"
        }}
      >
        <Box
          sx={{
            margin: "auto",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{mt: 1}}>
            {/*<Box sx={{width: "100%", display: "flex", alignItems: "baseline"}}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Site"
              autoFocus
              size="small"
              error={siteError !== ''}
              helperText={siteError}
              onChange={onSiteNameChanged}
              sx={{
                '& fieldset': {
                  borderRadius: '4px 0px 0px 4px'
                }
              }}
            />
            <Box sx={{padding: "8px 20px 8px 0px", backgroundColor: grey[200]}}>.simplehr.com</Box>
          </Box>*/}
            <TextField
              margin="normal"
              required
              fullWidth
              label="Company name"
              size="small"
              onChange={onCompanyNameChanged}
              onKeyDown={onEnter(onSetupCompany)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
              onClick={onSetupCompany}
              disabled={!(companyName !== '')}
              //disabled={!(site !== '' && companyName !== '' && siteError === '')}
            >
              Continue
            </Button>
            <Grid container>
              <Grid item xs>

              </Grid>

              {/*<Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>*/}

            </Grid>
          </Box>
        </Box>
      </Box>
      <Chat/>
    </Container>
  )
}