import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField
} from "@mui/material"
import {Team, useAddTeam, useDeleteTeam, UserRole, useTeams, useUpdateTeam} from "../api";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as React from "react";
import {useState} from "react";
import {ApiError} from "../errors";
import {useAuth} from "../auth/AuthProvider";
import {headerStyle, paperItemStyle} from "../style-util";

export const TeamList = () => {
  const auth = useAuth();
  const teams = useTeams()
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  const [addTeam] = useAddTeam()
  const [updateTeam] = useUpdateTeam()
  const [deleteTeam] = useDeleteTeam()

  const [addDialog, setAddDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)

  const [newName, setNewName] = useState("")
  const [newNameError, setNewNameError] = useState("")

  const onConfirmDelete = () => {
    if (!selectedTeam) {
      return
    }

    deleteTeam({
      variables: {
        id: selectedTeam.id
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }
      } else {
        teams.refetch()
        setDeleteDialog(false)
      }
    })
  }

  const onOpenEditDialog = (team: Team) => {
    setSelectedTeam(team)
    setNewName(team.name)
    setEditDialog(true)
  }

  const onOpenDeleteDialog = (team: Team) => {
    setSelectedTeam(team)
    setDeleteDialog(true)
  }

  const onAdd = () => {
    addTeam({variables: {
        name: newName
      }}).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }
      } else {
        teams.refetch()
        onCancelAddDialog()
      }
    })
  }

  const onUpdate = () => {
    if (!selectedTeam) {
      return
    }

    updateTeam({
      variables: {
        id: selectedTeam.id,
        name: newName
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }
      } else {
        teams.refetch()
        onCancelUpdateDialog()
      }
    })
  }

  const onCancelAddDialog = () => {
    setAddDialog(false)
    setNewName("")
    setNewNameError("")
  }

  const onCancelUpdateDialog = () => {
    setEditDialog(false)
    setNewName("")
  }

  return (
    <Box sx={{marginTop: 2, display: "flex", gap: "10px", flexWrap: "wrap", flexDirection: "column"}}>

      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <Box sx={headerStyle}>Teams</Box>
        {auth.user?.role === UserRole.COMPANY_ADMIN &&
          <Box>
            <Box sx={{display: "flex", width: "100%"}}>
              <Button variant="outlined" onClick={e => setAddDialog(true)}>+ Add</Button>
            </Box>
            <Dialog open={addDialog}>
              <DialogTitle>Add Team</DialogTitle>
              <DialogContent sx={{width: "400px"}}>
                <TextField
                  autoFocus
                  label="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  error={newNameError !== ''}
                  helperText={newNameError}
                  value={newName}
                  onChange={e => setNewName(e.target.value)}
                />
              </DialogContent>
              <DialogActions sx={{padding: "10px 25px"}}>
                <Button onClick={onCancelAddDialog}>Cancel</Button>
                <Button variant="contained" onClick={onAdd}>Add</Button>
              </DialogActions>
            </Dialog>
          </Box>
        }
      </Box>

      <Box sx={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
        {teams.data?.teams.map(x =>
          <Box sx={{...paperItemStyle, minWidth: "311px", maxWidth: "311px"}} key={x.id}>

            <Box sx={{display: "flex", justifyContent: "space-between", padding: "10px"}}>
              <Box sx={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                {x.name}
              </Box>
              <Badge badgeContent={4}>
                <PeopleIcon color="action" />
              </Badge>
            </Box>

            {auth.user?.role === UserRole.COMPANY_ADMIN &&
              <>
                <Divider/>

                <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: "5px"}}>
                  <IconButton onClick={e => onOpenDeleteDialog(x)}>
                    <DeleteIcon fontSize={"small"}/>
                  </IconButton>
                  <IconButton onClick={e => onOpenEditDialog(x)}>
                    <EditIcon fontSize={"small"}/>
                  </IconButton>
                </Box>
              </>
            }

          </Box>
        )}
      </Box>

      <Dialog onClose={onCancelUpdateDialog} open={editDialog}>
        <DialogTitle>Update Team</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          <TextField
            autoFocus
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            margin="normal"
            error={newNameError !== ''}
            helperText={newNameError}
            value={newName}
            onChange={e => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={_ => onCancelUpdateDialog()}>Cancel</Button>
          <Button variant="contained" onClick={() => onUpdate()}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialog}>
        <DialogTitle>Delete Team</DialogTitle>
        <DialogContent sx={{width: "400px"}}>
          Are you sure you want to delete '{selectedTeam?.name ?? ''}'?
        </DialogContent>
        <DialogActions sx={{padding: "10px 25px"}}>
          <Button onClick={e => setDeleteDialog(false)}>No</Button>
          <Button variant="contained" color="error" onClick={onConfirmDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}