import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Paper} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import {TimeOffPolicyList} from "./TimeOffPolicyList";
import {TimeOffPolicyAddUpdate} from "./TimeOffPolicyAddUpdate";
import {TimeOffPolicyEdit} from "./TimeOffPolicyEdit";
import {HolidaysCalendarList} from "./HolidaysCalendarList";
import {TimeOffPolicyUsersList} from "./TimeOffPolicyUsersList";
import {HolidaysCalendar} from "./HolidaysCalendar";
import {General} from "./General";
import {WorkScheduleList} from "./WorkScheduleList";
import {WorkScheduleAddUpdate} from "./WorkScheduleAddUpdate";
import {WorkScheduleEdit} from "./WorkScheduleEdit";
import {WorkSchedule} from "./WorkSchedule";
import {EmploymentTypeList} from "./EmploymentTypeList";
import {Integrations} from "./Integrations";

export const Settings = () => {

  const location = useLocation()
  const [tab, setTab] = useState("/settings/general")

  useEffect(() => {
    const selectTab = (path: string) => {
      if (location.pathname.startsWith(path)) {
        setTab(path)
        return true
      }

      return false
    }

    selectTab("/settings/general") ||
    selectTab("/settings/employment-types") ||
    selectTab("/settings/time-off-policy") ||
    selectTab("/settings/holidays-calendar")
    selectTab("/settings/work-schedule")
    selectTab("/settings/integration")
  }, [location.pathname])

  return (
    <Box>
      <Paper sx={{padding: "0px 20px"}}>
        <Tabs
          value={tab}
          //onChange={handleChange}
          variant="scrollable"
        >
          <Tab
            label="General"
            component={Link}
            value={"/settings/general"}
            to={"/settings/general"}
          />
          <Tab
            label="Employment Type"
            component={Link}
            value={"/settings/employment-types"}
            to={"/settings/employment-types"}
          />
          <Tab
            label="Time Off Policy"
            component={Link}
            value={"/settings/time-off-policy"}
            to={"/settings/time-off-policy"}
          />
          <Tab
            label="Work Schedule"
            component={Link}
            value={"/settings/work-schedule"}
            to={"/settings/work-schedule"}
          />
          <Tab
            label="Holidays Calendar"
            component={Link}
            value={"/settings/holidays-calendar"}
            to={"/settings/holidays-calendar"}
          />
          <Tab
            label="Integrations"
            component={Link}
            value={"/settings/integration"}
            to={"/settings/integration"}
          />
        </Tabs>
      </Paper>
      <Box sx={{marginTop: 2}}>
        <Routes>
          <Route path="/general/*" element={<General/>}/>
          <Route path="/employment-types" element={<EmploymentTypeList/>}/>
          <Route path="/time-off-policy" element={<TimeOffPolicyList/>}/>
          <Route path="/time-off-policy/add" element={<TimeOffPolicyAddUpdate/>}/>
          <Route path="/time-off-policy/:timeOffPolicyId/edit" element={<TimeOffPolicyEdit/>}/>
          <Route path="/time-off-policy/:timeOffPolicyId/users" element={<TimeOffPolicyUsersList/>}/>
          <Route path="/work-schedule" element={<WorkScheduleList/>}/>
          <Route path="/work-schedule/add" element={<WorkScheduleAddUpdate/>}/>
          <Route path="/work-schedule/:workScheduleId/edit" element={<WorkScheduleEdit/>}/>
          <Route path="/work-schedule/:workScheduleId/*" element={<WorkSchedule/>}/>
          <Route path="/holidays-calendar" element={<HolidaysCalendarList/>}/>
          <Route path="/holidays-calendar/:holidaysCalendarId/*" element={<HolidaysCalendar/>}/>
          <Route path="/integration/*" element={<Integrations/>}/>
          <Route path="/*" element={<Navigate to="/settings/general" replace/>}/>
        </Routes>
      </Box>
    </Box>
  )
}

