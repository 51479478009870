import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@mui/material";
import {SupportedImageTypes, uploadCompanyLogo, useCompany, useUpdateCompanyName} from "../api";
import {headerStyle} from "../style-util";
import * as React from "react";
import {useState} from "react";
import {ApiError} from "../errors";
import EditIcon from "@mui/icons-material/Edit";
import {useGlobalScope} from "../auth/GlobalScopeProvider";

export const CompanyGeneral = () => {
  const { refreshCompany } = useGlobalScope()
  const company = useCompany()

  const [editDialog, setEditDialog] = useState(false)
  const [newName, setNewName] = useState("")
  const [newNameError, setNewNameError] = useState("")

  const [updateCompanyName] = useUpdateCompanyName()

  const inputFileRef = React.useRef<HTMLInputElement>(null)

  const onOpenEditDialog = () => {
    setNewName(company.data?.company.name || "")
    setEditDialog(true)
  }

  const onCancelUpdateDialog = () => {
    setEditDialog(false)
    setNewName("")
  }

  const onUpdate = () => {
    if (!newName) {
      return
    }

    updateCompanyName({
      variables: {
        name: newName
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNewNameError(e.message)
        }
      } else {
        company.refetch()
        refreshCompany()
        onCancelUpdateDialog()
      }
    })
  }

  const onClickSelectFile = () => {
    inputFileRef.current?.click()
  }

  const onSelectPhotoFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      uploadCompanyLogo(file).then(() => {
        if (inputFileRef.current) {
          inputFileRef.current.value = ""
        }
        company.refetch()
        refreshCompany()
      }).catch(() => {
        if (inputFileRef.current) {
          inputFileRef.current.value = ""
        }
      })
    }
  }

  return (
    <Box sx={{marginTop: "25px"}}>
      <Box>
        <Box style={headerStyle}>
          Company name
        </Box>
        <Box sx={{display: "flex", alignItems: "center", marginTop: "10px"}}>
          <Box sx={{}}>
            {company.data?.company.name}
          </Box>
          <IconButton onClick={_ => onOpenEditDialog()}>
            <EditIcon fontSize={"small"}/>
          </IconButton>
        </Box>
        <Dialog onClose={onCancelUpdateDialog} open={editDialog}>
          <DialogTitle>Update Company</DialogTitle>
          <DialogContent sx={{width: "400px"}}>
            <TextField
              autoFocus
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              margin="normal"
              error={newNameError !== ''}
              helperText={newNameError}
              value={newName}
              onChange={e => setNewName(e.target.value)}
            />
          </DialogContent>
          <DialogActions sx={{padding: "10px 25px"}}>
            <Button onClick={e => onCancelUpdateDialog()}>Cancel</Button>
            <Button variant="contained" onClick={() => onUpdate()}>Update</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box sx={{marginTop: "45px"}}>
        <Box style={headerStyle}>
          Company logo
        </Box>
        <Box sx={{display: "flex", paddingTop: "10px"}}>
          <Box
            sx={{display: "flex", justifyContent: "center", position: "relative"}}
          >
            <input style={{display: "none"}}
                   type="file"
                   accept={SupportedImageTypes}
                   onChange={onSelectPhotoFile}
                   ref={inputFileRef}
            />
            {company.data?.company?.logoUrl &&
              <img
                style={{marginRight: "10px", height: "40px"}}
                src={company.data?.company?.logoUrl + "?" + Date.now()}/>
            }
          </Box>

          <Button variant="outlined" onClick={onClickSelectFile}>Update logo</Button>

        </Box>
      </Box>
    </Box>
  )
}