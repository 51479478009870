import {useEffect, useState} from "react";
import {useAuth} from "./AuthProvider";
import {Alert, Box, Button, Container, CssBaseline, Divider, Grid, Link, TextField} from "@mui/material";
import {ReactComponent as GoogleIcon} from "../icon/google-icon.svg";
import {useQuery} from "graphql-hooks";
import {useSearchParams} from "react-router-dom";
import {Spinner} from "../common/Spinner";
import {grey} from "@mui/material/colors";
import {onEnter} from "../event-util";

const GOOGLE_SSO_URL_QUERY = `
  query googleSSO($operation: SsoOperation!) {
    googleSSO(operation: $operation)
  }
`;

interface GoogleSsoResponse {
  googleSSO: string
}

export const LoginPage = () => {

  const {loading, error, data} = useQuery<GoogleSsoResponse>(GOOGLE_SSO_URL_QUERY, {
    variables: {
      operation: 'LOGIN'
    }
  })
  const [googleSsoUrl, setGoogleSsoUrl] = useState("")

  useEffect(() => {
    if (data) {
      setGoogleSsoUrl(data.googleSSO)
    } else if (error) {
      console.error(error)
    }
  }, [loading])

  const auth = useAuth()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const [searchParams, _] = useSearchParams();
  const errorCode = searchParams.get("error")

  const errorMessage =
    errorCode === 'internal'
      ? "Internal error. Please try again later."
      : errorCode === 'not_found'
        ? "User not found"
        : "";

  const onLogin = () => {
    auth.login(email, password)
  }

  if (!googleSsoUrl) {
    return <Spinner/>
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          height: "100vh",
          display: "flex"
        }}
      >
        <Box
          sx={{
            margin: "auto",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: "400px"
          }}
        >
          {errorMessage &&
            <Alert severity="error" sx={{margin: 3}}>{errorMessage}</Alert>
          }
          <Button fullWidth sx={{color: "primary.main"}} variant="outlined" href={googleSsoUrl}>
            <GoogleIcon style={{marginRight: "10px"}}/> Log in with Google
          </Button>

          <Divider sx={{width: "100%", marginTop: 3, color: grey[500], fontSize: "12px"}}>OR</Divider>

          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
              value={email}
              onChange={x => setEmail(x.target.value)}
              onKeyDown={onEnter(onLogin)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              size="small"
              value={password}
              onChange={x => setPassword(x.target.value)}
              onKeyDown={onEnter(onLogin)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={onLogin}
            >
              Log in
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/password-reset-request" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/registration" variant="body2">
                  Don't have an account? Register
                </Link>
              </Grid>
            </Grid>
          </Box>

        </Box>
      </Box>
    </Container>
  )
}