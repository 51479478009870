import {Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField} from "@mui/material"
import {useAddWorkSchedule, useUpdateWorkSchedule, WorkSchedule, WorkScheduleWeekDay} from "../api";
import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {headerStyle} from "../style-util";
import {weekDays} from "../data-time-util";
import InfoIcon from '@mui/icons-material/Info';
import {color} from "../color";
import {ApiError} from "../errors";

export const WorkScheduleAddUpdate = (props: {workSchedule?: WorkSchedule}) => {
  const navigate = useNavigate()
  const edit = props.workSchedule !== undefined

  const [addWorkSchedule] = useAddWorkSchedule()
  const [updateWorkSchedule] = useUpdateWorkSchedule()

  const [name, setName] = useState(props.workSchedule?.name || "")
  const [nameError, setNameError] = useState("")
  const [type, setType] = useState(edit ? props.workSchedule?.type : 'FIXED')

  const defaultWeekDays = Array.from(new Array(7), (x, i) => i).map(i => ({
    id: '',
    isoWeekDay: i + 1,
    amountHours: i === 5 || i === 6 ? 0 : 8
  }));

  const [scheduleWeekDays, setScheduleWeekDays] = useState<Array<WorkScheduleWeekDay>>(
    edit
      ? props.workSchedule?.weekDays!
      : defaultWeekDays)

  const addOrUpdate = () => {
    if (edit) {
      onUpdate()
    } else {
      onAdd()
    }
  }

  const onAdd = () => {
    addWorkSchedule({
      variables: {
        name: name,
        type: type,
        weekDays: scheduleWeekDays.map(x => ({
          isoWeekDay: x.isoWeekDay,
          amountHours: x.amountHours
        }))
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNameError(e.message)
        }
      } else {
        navigate("/settings/work-schedule")
      }
    })
  }

  const onUpdate = () => {
    updateWorkSchedule({
      variables: {
        id: props.workSchedule?.id!,
        name: name,
        weekDays: scheduleWeekDays.map(x => ({
          id: x.id,
          amountHours: x.amountHours
        }))
      }
    }).then(response => {
      if (response.error) {
        const e = response?.error?.graphQLErrors?.find(x => x.extensions?.code === ApiError.ValidationError)
        if (e) {
          setNameError(e.message)
        }
      } else {
        navigate("/settings/work-schedule")
      }
    })
  }

  const rowStyle = {height: "55px"}
  const rowLabel = {minWidth: "180px"}

  const updateScheduleWeekDay = (i: number, value: number) => {
    setScheduleWeekDays(scheduleWeekDays.map(x => {
      if (x.isoWeekDay === i + 1) {
        return {...x, amountHours: value}
      } else {
        return x
      }
    }))
  }

  return (
    <Box sx={{width: "400px"}}>
      <Box sx={{marginBottom: 1, marginLeft: 3, ...headerStyle}}>
        {edit ? "Update" : "Add"} Work Schedule
      </Box>

      <Paper>
        <table style={{width: "100%"}}>
          <thead></thead>
          <tbody>
            <tr style={rowStyle}>
              <td style={rowLabel}>Name</td>
              <td>
                <TextField
                  autoFocus
                  type="text"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                  error={nameError !== ''}
                  helperText={nameError}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </td>
            </tr>
            {!edit &&
            <tr style={rowStyle}>
              <td style={rowLabel}>Type</td>
              <td>
                <Select
                  fullWidth
                  size="small"
                  margin="dense"
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  <MenuItem value="FIXED">Fixed</MenuItem>
                  <MenuItem value="FLEXIBLE">Flexible</MenuItem>
                </Select>
              </td>
            </tr>
            }
            {type === 'FIXED' &&
              weekDays.map((x, i) =>
                <tr style={rowStyle}>
                  <td style={rowLabel}>{x}</td>
                  <td>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel>hours</InputLabel>
                      <Select
                        size="small"
                        label="hour"
                        value={edit ? scheduleWeekDays[i].amountHours : (i === 5 || i === 6 ? 0 : 8)}
                        onChange={e => updateScheduleWeekDay(i, e.target.value as number)}
                      >
                        {Array.from(new Array(25), (x, i) => i).map(i =>
                          <MenuItem value={i} key={i}>
                            {i}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>

        {type === 'FLEXIBLE' &&
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px"
            }}>
            <InfoIcon fontSize="small" sx={{color: color.pending}}/>
            Daily working hours assignment will be available in schedule menu for each user
          </Box>
        }

        <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}>
          <Button sx={{marginRight: 2}} onClick={() => navigate("/settings/work-schedule")}>Cancel</Button>
          <Button variant="contained" onClick={addOrUpdate}>{edit ? 'Update' : 'Add'}</Button>
        </Box>

      </Paper>
    </Box>
  )
}