import {Box, Paper} from "@mui/material"
import * as React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {Link, Navigate, Route, Routes, useLocation, useParams} from "react-router-dom";
import {WorkScheduleUsersList} from "./WorkScheduleUsersList";

export const WorkSchedule = () => {
  const {workScheduleId} = useParams()
  const location = useLocation();

  if (!workScheduleId) {
    return <></>
  }

  return (
    <Box sx={{marginTop: 2, display: "flex", gap: "10px", flexWrap: "wrap"}}>
      <Box sx={{width: "100%"}}>
        <Paper>
          <Tabs
            value={location.pathname}
            //onChange={handleChange}
            variant="scrollable"
          >
            <Tab
              label="Users"
              component={Link}
              value={`/settings/work-schedule/${workScheduleId}/users`}
              to={`/settings/work-schedule/${workScheduleId}/users`}
            />
          </Tabs>
          <Box sx={{marginTop: 2}}>
            <Routes>
              <Route path="/users" element={<WorkScheduleUsersList workScheduleId={workScheduleId}/>}/>
              <Route path="/*" element={<Navigate to={`/settings/work-schedule/${workScheduleId}/users`} replace/>}/>
            </Routes>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}