import {Avatar, Box, colors, IconButton, Link, Paper, Skeleton, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {
  AnnouncementType,
  AnnouncementUser,
  useAnnouncements,
  useCurrentTimeOffRequests,
  usePendingOvertimeRequests,
  usePendingTimeOffRequests,
  useRequestedOvertimeRequests,
  useUpcomingTimeOffRequests,
  useUpdateOvertimeRequestStatus,
  useUpdateTimeOffRequestStatus,
  useUserTimeOffBalances
} from "../api";
import {useAuth} from "../auth/AuthProvider";
import {headerStyle, paperItemStyle, StatusColor, statusLabel} from "../style-util";
import {TimeOffAdd} from "../people/TimeOffAdd";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from "react-router-dom";
import moment from "moment/moment";
import {nameAbbreviation, round} from "../string-format-util";
import {color} from "../color";
import {minutesToDays, toTimeDuration} from "../data-time-util";

export const Home = () => {
  const {user} = useAuth()

  const timeOffBalances = useUserTimeOffBalances(user!!.id)
  const pendingOvertimeRequests = usePendingOvertimeRequests()
  const requestedOvertimeRequests = useRequestedOvertimeRequests()
  const pendingTimeOffRequests = usePendingTimeOffRequests()
  const upcomingTimeOffRequests = useUpcomingTimeOffRequests()
  const currentTimeOffRequests = useCurrentTimeOffRequests()
  const announcements = useAnnouncements()

  const [newcomers, setNewcomers] = useState<Array<AnnouncementUser>>([])

  useEffect(() =>
      setNewcomers(
        announcements.data?.announcements?.filter(x => x.type === AnnouncementType.NEW_HIRE)
          .flatMap(x => x.users) || []
      ), [announcements.loading])

  const [birthdays, setBirthdays] = useState<Array<AnnouncementUser>>([])

  useEffect(() =>
    setBirthdays(
      announcements.data?.announcements?.filter(x => x.type === AnnouncementType.BIRTHDAY)
        .flatMap(x => x.users) || []
    ), [announcements.loading])

  const [anniversaries, setAnniversaries] = useState<Array<AnnouncementUser>>([])

  useEffect(() =>
    setAnniversaries(
      announcements.data?.announcements?.filter(x => x.type === AnnouncementType.ANNIVERSARY)
        .flatMap(x => x.users) || []
    ), [announcements.loading])

  const [updateOvertimeRequestStatus] = useUpdateOvertimeRequestStatus()
  const [updateTimeOffRequestStatus] = useUpdateTimeOffRequestStatus()

  const labelStyle = {}

  const balanceStyle =
    /*{
    //border: "1px solid " + colors.grey[300],
    borderRadius: "6px",
    //padding: "15px",
    margin: "10px 20px 10px 0px",
    width: "200px",
    maxWidth: "200px",
    minWidth: "200px",
    //backgroundColor: "white"
  }*/
    {...paperItemStyle, margin: "10px 20px 10px 0px",}

  const sectionStyle = {
    marginBottom: "40px"
  }

  const onChangeTimeOffRequestStatus = (id: string, status: string) => {
    updateTimeOffRequestStatus({
      variables: {
        timeOffRequestId: id,
        status: status
      }
    }).then(response => {
      if (response.error) {
        console.error(response.error)
      } else {
        pendingTimeOffRequests.refetch()
      }
    })
  }

  const onChangeOvertimeRequestStatus = (id: string, status: string) => {
    updateOvertimeRequestStatus({
      variables: {
        overtimeRequestId: id,
        status: status
      }
    }).then(response => {
      if (response.error) {
        console.error(response.error)
      } else {
        pendingOvertimeRequests.refetch()
      }
    })
  }

  const nameTitleStyle = {
    display: "flex",
    flexDirection: "column",
    minWidth: "200px",
    width: "200px",
    justifyContent: "center"
  }

  const fullNameStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }

  return (
    <>
      {timeOffBalances.loading &&
        <Box sx={{marginBottom: "40px"}}>
          <Skeleton animation="wave" sx={{ width: 100 }}/>
          <Box sx={{display: "flex", marginBottom: "15px"}}>
            <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={141} />
            <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={141} />
            <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={141} />
          </Box>
          <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={36} />
        </Box>
      }
      {(timeOffBalances.data?.user?.timeOffBalances?.length ?? 0) > 0 &&
      <Box sx={sectionStyle}>
        <Box sx={headerStyle}>
          Time off balance
        </Box>
        <Box className="hide-scroll" sx={{display: "flex", marginBottom: "10px", overflowX: "auto", maxWidth: "990px"}}>
          {timeOffBalances.data?.user?.timeOffBalances?.map(x =>
            <Paper sx={balanceStyle} key={x.id}>
              <Box sx={labelStyle}>{x.timeOffPolicy.name}</Box>
              {!x.timeOffPolicy.unlimited &&
                <Box sx={{color: "green"}}>
                  <Box sx={{
                    display: "inline-block",
                    color: "green",
                    fontSize: "22px"
                  }}>{minutesToDays(x.availableMinutes)}</Box><Typography variant="caption"> days available</Typography>
                </Box>
              }
              <Box sx={{display: "inline-block", color: colors.grey[500]}}>{round(x.usedHours / 8, 2)}<Typography variant="caption"> days used</Typography></Box>
            </Paper>
          )}
        </Box>
        <TimeOffAdd user={user!!} onAdded={() => {
          timeOffBalances.refetch()
          pendingTimeOffRequests.refetch()
          upcomingTimeOffRequests.refetch()
          currentTimeOffRequests.refetch()
        }}/>
      </Box>
      }

      {pendingOvertimeRequests.loading &&
        <Box sx={{marginBottom: "40px"}}>
          <Skeleton animation="wave" sx={{ width: 100 }}/>
          <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={36} />
        </Box>
      }
      {(pendingOvertimeRequests.data?.pendingOvertimeRequests?.length ?? 0) > 0 &&
        <Box sx={sectionStyle}>
          <Box sx={headerStyle}>
            Overtime requests
          </Box>
          <Box>
            {pendingOvertimeRequests.data?.pendingOvertimeRequests?.map(x =>
              <Paper key={x.id} sx={{display: "flex", marginTop: "10px", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: "20px"}}>
                  <Box sx={{display: "flex"}}>
                    <Link component={RouterLink} to={"/people/person/" + x.user.id + "/profile"} sx={{textDecoration: "none"}}>
                      <Avatar
                        sx={{ width: 56, height: 56, fontSize: "14px" }}
                        src={x.user?.photoUrl}
                      >
                        {nameAbbreviation(x.user?.fullName)}
                      </Avatar>
                    </Link>
                  </Box>
                  <Box sx={nameTitleStyle}>
                    <Box sx={fullNameStyle}>{x.user.fullName}</Box>
                    <Box sx={{color: "gray", fontSize: "14px"}}>{x.user.title}</Box>
                  </Box>

                  <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Box sx={{display: "flex", flexDirection: "row", gap: "10px"}}>
                      <Box>Overtime on {moment(x.date).format("MMM DD")}, {moment(x.fromTime, [moment.ISO_8601, 'HH:mm:ss']).format('HH:mm')} - {moment(x.toTime, [moment.ISO_8601, 'HH:mm:ss']).format('HH:mm')}</Box>
                      <Box sx={{color: colors.grey[500]}}>{toTimeDuration(x.fromTime, x.toTime).text}</Box>
                    </Box>
                    <Box sx={{wordBreak: "break-word"}}>{x.description}</Box>
                  </Box>
                </Box>
                <Box sx={{display: "flex"}}>
                  <IconButton sx={{width: "55px", height: "55px"}} onClick={_ => onChangeOvertimeRequestStatus(x.id, 'APPROVED')}>
                    <DoneIcon sx={{fill: color.success}}/>
                  </IconButton>
                  <IconButton sx={{width: "55px", height: "55px"}} onClick={_ => onChangeOvertimeRequestStatus(x.id, 'DECLINED')}>
                    <CloseIcon sx={{fill: color.error}}/>
                  </IconButton>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      }

      {pendingTimeOffRequests.loading &&
        <Box sx={{marginBottom: "40px"}}>
          <Skeleton animation="wave" sx={{ width: 100 }}/>
          <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={36} />
        </Box>
      }
      {(pendingTimeOffRequests.data?.pendingTimeOffRequests?.length ?? 0) > 0 &&
        <Box sx={sectionStyle}>
          <Box sx={headerStyle}>
            Time off requests
          </Box>
          <Box>
            {pendingTimeOffRequests.data?.pendingTimeOffRequests?.map(x =>
              <Paper key={x.id} sx={{display: "flex", marginTop: "10px", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: "20px"}}>
                  <Box sx={{display: "flex"}}>
                    <Link component={RouterLink} to={"/people/person/" + x.user.id + "/profile"} sx={{textDecoration: "none"}}>
                      <Avatar
                        sx={{ width: 56, height: 56, fontSize: "14px" }}
                        src={x.user?.photoUrl}
                      >
                        {nameAbbreviation(x.user?.fullName)}
                      </Avatar>
                    </Link>
                  </Box>
                  <Box sx={nameTitleStyle}>
                    <Box sx={fullNameStyle}>{x.user.fullName}</Box>
                    <Box sx={{color: "gray", fontSize: "14px"}}>{x.user.title}</Box>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Box sx={{display: "flex", flexDirection: "row"}}>
                      <Box>
                        {x.timeOffPolicy.name} on {moment(x.start).format("MMM DD")} - {moment(x.end).format("MMM DD")}, {moment(x.end).format("YYYY")}
                      </Box>
                      <Box sx={{color: colors.grey[500], marginLeft: "10px"}}>
                        {round(x.amountHours / 8, 2)}d
                      </Box>
                    </Box>
                    <Box sx={{wordBreak: "break-word"}}>{x.description}</Box>
                  </Box>
                </Box>
                <Box sx={{display: "flex"}}>
                  <IconButton sx={{width: "55px", height: "55px"}} onClick={_ => onChangeTimeOffRequestStatus(x.id, 'APPROVED')}>
                    <DoneIcon sx={{fill: color.success}}/>
                  </IconButton>
                  <IconButton sx={{width: "55px", height: "55px"}} onClick={_ => onChangeTimeOffRequestStatus(x.id, 'DECLINED')}>
                    <CloseIcon sx={{fill: color.error}}/>
                  </IconButton>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      }

      {requestedOvertimeRequests.loading &&
        <Box sx={{marginBottom: "40px"}}>
          <Skeleton animation="wave" sx={{ width: 100 }}/>
          <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={36} />
        </Box>
      }
      {(requestedOvertimeRequests.data?.requestedOvertimeRequests?.length ?? 0) > 0 &&
        <Box sx={sectionStyle}>
          <Box sx={headerStyle}>
            My overtime requests
          </Box>
          <Box>
            {requestedOvertimeRequests.data?.requestedOvertimeRequests?.map(x =>
              <Paper key={x.id} sx={{display: "flex", marginTop: "10px", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: "20px"}}>
                  <Box sx={{display: "flex"}}>
                    <Link component={RouterLink} to={"/people/person/" + x.user.id + "/profile"} sx={{textDecoration: "none"}}>
                      <Avatar
                        sx={{ width: 56, height: 56, fontSize: "14px" }}
                        src={x.user?.photoUrl}
                      >
                        {nameAbbreviation(x.user?.fullName)}
                      </Avatar>
                    </Link>
                  </Box>

                  <Box sx={nameTitleStyle}>
                    <Box sx={fullNameStyle}>{x.user.fullName}</Box>
                    <Box sx={{color: "gray", fontSize: "14px"}}>{x.user.title}</Box>
                  </Box>

                  <Box sx={{display: "flex", gap: "20px"}}>
                    <Box sx={{display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center", alignItems: "center"}}>
                      <Box>Overtime on {moment(x.date).format("MMM DD")}, {moment(x.fromTime, [moment.ISO_8601, 'HH:mm:ss']).format('HH:mm')} - {moment(x.toTime, [moment.ISO_8601, 'HH:mm:ss']).format('HH:mm')}</Box>
                      <Box sx={{color: colors.grey[500]}}>{toTimeDuration(x.fromTime, x.toTime).text}</Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{display: "flex", alignItems: "center"}}>
                  {statusLabel(x.status, x.status === 'REQUESTED' ? StatusColor.BLUE : StatusColor.GREEN)}
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      }

      {upcomingTimeOffRequests.loading &&
        <Box sx={{marginBottom: "40px"}}>
          <Skeleton animation="wave" sx={{ width: 100 }}/>
          <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={36} />
        </Box>
      }
      {(upcomingTimeOffRequests.data?.upcomingTimeOffRequests?.length ?? 0) > 0 &&
        <Box sx={sectionStyle}>
          <Box sx={headerStyle}>
            My time off requests
          </Box>
          <Box>
            {upcomingTimeOffRequests.data?.upcomingTimeOffRequests?.map(x =>
              <Paper key={x.id} sx={{display: "flex", marginTop: "10px", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: "20px"}}>
                  <Box sx={{display: "flex"}}>
                    <Link component={RouterLink} to={"/people/person/" + x.user.id + "/profile"} sx={{textDecoration: "none"}}>
                      <Avatar
                        sx={{ width: 56, height: 56, fontSize: "14px" }}
                        src={x.user?.photoUrl}
                      >
                        {nameAbbreviation(x.user?.fullName)}
                      </Avatar>
                    </Link>
                  </Box>

                  <Box sx={nameTitleStyle}>
                    <Box sx={fullNameStyle}>{x.user.fullName}</Box>
                    <Box sx={{color: "gray", fontSize: "14px"}}>{x.user.title}</Box>
                  </Box>

                  <Box sx={{display: "flex", gap: "20px"}}>
                    <Box sx={{display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center", alignItems: "center"}}>
                      <Box>{x.timeOffPolicy.name} on {moment(x.start).format("MMM DD")} - {moment(x.end).format("MMM DD")}, {moment(x.end).format("YYYY")}</Box>
                      <Box sx={{color: colors.grey[500]}}>{round(x.amountHours / 8, 2)}d</Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{display: "flex", alignItems: "center"}}>
                  {statusLabel(x.status, x.status === 'REQUESTED' ? StatusColor.BLUE : StatusColor.GREEN)}
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      }

      {announcements.loading &&
        <Box sx={{marginBottom: "40px"}}>
          <Skeleton animation="wave" sx={{ width: 100 }}/>
          <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={36} />
        </Box>
      }
      {newcomers.length > 0 &&
        <Box sx={sectionStyle}>
          <Box sx={headerStyle}>
            Newcomers
          </Box>
          <Box>
            {newcomers.map(user =>
              <Paper key={user.id} sx={{display: "flex", marginTop: "10px", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: "20px"}}>
                  <Box sx={{display: "flex"}}>
                    <Link component={RouterLink} to={"/people/person/" + user.id + "/profile"} sx={{textDecoration: "none"}}>
                      <Avatar
                        sx={{ width: 56, height: 56, fontSize: "14px" }}
                        src={user.photoUrl}>
                        {nameAbbreviation(user.fullName)}
                      </Avatar>
                    </Link>
                  </Box>
                  <Box sx={nameTitleStyle}>
                    <Box sx={fullNameStyle}>{user.fullName}</Box>
                    <Box sx={{color: "gray", fontSize: "14px"}}>{user.title}</Box>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                      Joined on {moment(user.date).format("MMM DD")}
                  </Box>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      }

      {currentTimeOffRequests.loading &&
        <Box sx={{marginBottom: "40px"}}>
          <Skeleton animation="wave" sx={{ width: 100 }}/>
          <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={36} />
        </Box>
      }
      {(currentTimeOffRequests.data?.currentTimeOffRequests?.length ?? 0) > 0 &&
        <Box sx={sectionStyle}>
          <Box sx={headerStyle}>
            Out today
          </Box>
          <Box>
            {currentTimeOffRequests.data?.currentTimeOffRequests?.map(x =>
              <Paper key={x.id} sx={{display: "flex", marginTop: "10px", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: "20px"}}>
                  <Box sx={{display: "flex"}}>
                    <Link component={RouterLink} to={"/people/person/" + x.user.id + "/profile"} sx={{textDecoration: "none"}}>
                      <Avatar
                        sx={{ width: 56, height: 56, fontSize: "14px" }}
                        src={x.user?.photoUrl}>
                        {nameAbbreviation(x.user?.fullName)}
                      </Avatar>
                    </Link>
                  </Box>
                  <Box sx={nameTitleStyle}>
                    <Box sx={fullNameStyle}>{x.user.fullName}</Box>
                    <Box sx={{color: "gray", fontSize: "14px"}}>{x.user.title}</Box>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                      <Box>
                        {x.timeOffPolicy.name} on {moment(x.start).format("MMM DD")} - {moment(x.end).format("MMM DD")}, {moment(x.end).format("YYYY")}
                      </Box>
                      <Box sx={{color: colors.grey[500], marginLeft: "10px"}}>
                        {round(x.amountHours / 8, 2)}d
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      }

      {announcements.loading &&
        <Box sx={{marginBottom: "40px"}}>
          <Skeleton animation="wave" sx={{ width: 100 }}/>
          <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={36} />
        </Box>
      }
      {birthdays.length > 0 &&
        <Box sx={sectionStyle}>
          <Box sx={headerStyle}>
            Birthdays
          </Box>
          <Box>
            {birthdays.map(user =>
              <Paper key={user.id} sx={{display: "flex", marginTop: "10px", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: "20px"}}>
                  <Box sx={{display: "flex"}}>
                    <Link component={RouterLink} to={"/people/person/" + user.id + "/profile"} sx={{textDecoration: "none"}}>
                      <Avatar
                        sx={{ width: 56, height: 56, fontSize: "14px" }}
                        src={user.photoUrl}>
                        {nameAbbreviation(user.fullName)}
                      </Avatar>
                    </Link>
                  </Box>
                  <Box sx={nameTitleStyle}>
                    <Box sx={fullNameStyle}>{user.fullName}</Box>
                    <Box sx={{color: "gray", fontSize: "14px"}}>{user.title}</Box>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                      <Box>
                        Birthday on {moment(user.date).format("MMM DD")}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      }

      {announcements.loading &&
        <Box sx={{marginBottom: "40px"}}>
          <Skeleton animation="wave" sx={{ width: 100 }}/>
          <Skeleton variant="rectangular" sx={balanceStyle} width={200} height={36} />
        </Box>
      }
      {anniversaries.length > 0 &&
        <Box sx={sectionStyle}>
          <Box sx={headerStyle}>
            Anniversaries
          </Box>
          <Box>
            {anniversaries.map(user =>
              <Paper key={user.id} sx={{display: "flex", marginTop: "10px", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: "20px"}}>
                  <Box sx={{display: "flex"}}>
                    <Link component={RouterLink} to={"/people/person/" + user.id + "/profile"} sx={{textDecoration: "none"}}>
                      <Avatar
                        sx={{ width: 56, height: 56, fontSize: "14px" }}
                        src={user.photoUrl}>
                        {nameAbbreviation(user.fullName)}
                      </Avatar>
                    </Link>
                  </Box>
                  <Box sx={nameTitleStyle}>
                    <Box sx={fullNameStyle}>{user.fullName}</Box>
                    <Box sx={{color: "gray", fontSize: "14px"}}>{user.title}</Box>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                      <Box>
                        {moment().year() - moment(user.date).year()}y anniversary on {moment(user.date).format("MMM DD")}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      }

    </>
  )
}