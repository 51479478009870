import {useState} from "react";
import {Box, Button, Container, CssBaseline, Grid, Link, TextField} from "@mui/material";
import {useResetPassword} from "../api";
import {useSearchParams} from "react-router-dom";
import {onEnter} from "../event-util";

export const PasswordResetPage = () => {

  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")
  const [resetPassword] = useResetPassword()

  const [searchParams, _] = useSearchParams();
  const token = searchParams.get("token")

  const onReset = () => {
    if (!token) {
      setMessage("Unable to find related password reset request")
      return
    }

    resetPassword({
      variables: {
        token: token,
        password: password
      }
    }).then(response => {
      if (response.data?.resetPassword) {
        window.location.href = "/"
      } else {
        setMessage("Unable to find related password reset request")
      }
    })
    //TODO: error message
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          height: "100vh",
          display: "flex"
        }}
      >
        <Box
          sx={{
            margin: "auto",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: "400px"
          }}
        >
          {message &&
            <>
              <Box>
                {message}
              </Box>
              <Link href="/login" variant="body2">
                Back to login page
              </Link>
            </>
          }
          {!message &&
            <Box sx={{mt: 1}}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="New password"
                type="password"
                id="password"
                autoComplete="current-password"
                size="small"
                value={password}
                onChange={x => setPassword(x.target.value)}
                onKeyDown={onEnter(onReset)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                onClick={onReset}
              >
                Reset
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/login" variant="body2">
                    Back to login page
                  </Link>
                </Grid>
              </Grid>
            </Box>
          }
        </Box>
      </Box>
    </Container>
  )
}