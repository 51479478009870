import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {colors, createTheme, ThemeProvider} from "@mui/material";
import {ClientContext, GraphQLClient} from 'graphql-hooks';
import {AuthProvider} from "./auth/AuthProvider";
import {blueGrey} from "@mui/material/colors";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from "@mui/x-date-pickers";

const client = new GraphQLClient({
  url: '/api/graphql',
  onError: ({result, operation}) => {
    const unauthorized = result?.error?.graphQLErrors?.some(x =>
      x?.extensions?.classification === 'UNAUTHORIZED')

    if (unauthorized) {
      window.location.href = "/"
    }
  }
});

const theme = createTheme({
  typography: {
    fontFamily: [
      //'Inter',
      "Public Sans",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(","),
    //fontWeightRegular: "500",
    //fontSize: 14
  },
  palette: {
    primary: {
      main: colors.teal[900],// '#00ab55'
      light: '#35514e',
      //dark: '#47736b'
    }
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "30px"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          //padding: 0,
          margin: 0,
          minHeight: "30px"
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: "0 !important"
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          //padding: 0,
          "& .MuiListItem-root": {

          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          //boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
          borderRadius: '10px',
          padding: '20px',
          border: "1px solid #e4e4e4",
          //boxShadow: "rgb(145 158 171 / 20%) 0px 0px 5px 0px"
          boxShadow: "none"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: blueGrey[50],
          borderRadius: '16px'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          borderRadius: '16px'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none"
        },
        head: {
          color: '#637381'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          //fontFamily: "DM Sans"
        },
        contained: {
          "&:hover": {
            backgroundColor: "#47736b"
          }
        },
        outlined: {
          borderColor: "#cadaca",
          "&:hover": {
            borderColor: "#cadaca"
          }
        },
        outlinedError: {
          borderColor: "rgba(211, 47, 47, 0.5)",
          "&:hover": {
            borderColor: "rgba(211, 47, 47, 0.5)"
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#a0a9a0"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#d8e4d87a"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          "&:hover": {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: "#909a90"
            }
          },
          "&.Mui-focused": {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: "#909a90"
            }
          }
        },
        notchedOutline: {
          borderColor: "#a7b4a7"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "#5b5b5b"
        }
      }
    }
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <ClientContext.Provider value={client}>
      <AuthProvider>
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <App/>
            </LocalizationProvider>
          </ThemeProvider>
        </React.StrictMode>
      </AuthProvider>
    </ClientContext.Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
